<!doctype html>
<html lang="en">
  <head>
    <meta charset="UTF-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
    <title>FSNXT | Login</title>
  </head>
  <body>
    <div id="maincontainer" class="flex flex-row w-full h-full">
      <div id="leftpanel" class="hidden md:block w-1/2">
        <div class="flex flex-col h-full items-center align-middle bg-gray-100">
          <img
            class="scale-75"
            src="assets/images/graphics/signup.png"
            alt="" />
        </div>
      </div>
      <div id="rightpanel" class="w-full md:w-1/2 flex items-center">
        <div #loginContainer class="flex flex-col items-start w-full px-5">
          <div class="flex flex-row items-end">
            <span class="font-lato text-4xl mb-5">Login</span>
          </div>
          <span class="font-lato mb-1">Email address</span>
          <input
            name="email"
            placeholder="Email address"
            [(ngModel)]="email"
            type="email"
            id="UserEmail"
            class="mb-3 w-full h-12 rounded-md border-gray-200 shadow-sm sm:text-sm px-3 !font-lato" />
          <span class="font-lato mb-1">Password</span>
          <div
            #passwordContainer
            class="mb-3 w-full h-12 rounded-md border-gray-200 shadow-sm sm:text-sm !font-lato flex flex-row items-center">
            <input
              [type]="showPassword ? 'text' : 'password'"
              placeholder="Password"
              name="password"
              [(ngModel)]="password"
              class="h-12 px-3 rounded-md w-full mr-[-15%] md:mr-[-10%]" />
            <button *ngIf="!showPassword" (click)="togglePasswordVisibility()">
              <img
                src="assets/icons/view.png"
                class="h-5 pl-5"
                alt="show-pass" />
            </button>
            <button *ngIf="showPassword" (click)="togglePasswordVisibility()">
              <img
                src="assets/icons/hide.png"
                class="h-5 pl-5"
                alt="hide-pass" />
            </button>
          </div>
          <div #checkBox class="flex flex-row items-center w-full">
            <input type="checkbox" class="accent-blue-500" />
            <span class="font-lato m-2">Remember me</span>
          </div>

          <button
            class="w-full h-14 rounded-lg bg-blue-600 hover:bg-blue-800 text-white mt-3 font-lato"
            (click)="login()">
            Login
          </button>
          <div class="flex flex-col w-full items-center">
            <div
              id="orContainer"
              class="mt-4 flex flex-row w-full items-center">
              <hr
                class="h-px my-8 bg-gray-200 border-0 dark:bg-gray-700 w-full" />
              <span class="font-lato px-4">or</span>
              <hr
                class="h-px my-8 bg-gray-200 border-0 dark:bg-gray-700 w-full" />
            </div>
          </div>
          <button
            class="border w-full h-14 mt-4 rounded-lg hover:bg-gray-200"
            (click)="loginWithGoogle()">
            <div class="flex flex-row items-center justify-center">
              <img
                src="assets/icons/google.png"
                class="h-5 px-3"
                alt="google-logo" />
              <span class="font-lato">Authorize with Google</span>
            </div>
          </button>
        </div>
      </div>
    </div>
  </body>
</html>
