import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DbService } from 'src/app/core/services/db.service';

@Component({
  selector: 'app-manage-user-dialog',
  templateUrl: './manage-user-dialog.component.html',
  styleUrls: ['./manage-user-dialog.component.scss'],
})
export class ManageUserDialogComponent implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { userCode: string },
    private dbService: DbService
  ) {}
  menulist: any;
  userAccessString: any = {};
  status = '';

  ngOnInit(): void {
    //Get currrent menus from database
    this.getMenus();
    this.getUAC();
  }

  getMenus() {
    this.dbService.admin([], 'getMenus').subscribe((data: any) => {
      this.menulist = data;
      this.menulist.forEach((element: any) => {
        this.userAccessString[element.MENU_CODE] = 'show';
      });
    });
  }
  getUAC() {
    this.dbService
      .admin([this.data.userCode], 'getUAC')
      .subscribe((data: any) => {
        if (data != null) {
          this.userAccessString = data;
        }
      });
  }
  updateAccess() {
    this.status = '';
    this.dbService
      .admin(
        [JSON.stringify(this.userAccessString), this.data.userCode],
        'updateUAC'
      )
      .subscribe((response: any) => {
        if (response['status'] == 'success') {
          this.status = response['msg'];
        }
      });
  }
}
