<div #mainContainer class="h-full w-full flex flex-col">
  <div id="header" class="mt-10 flex flex-col">
    <span tabindex="0" class="font-lato text-2xl" (click)="fillDummy()"
      >FRA Dashboard</span
    >
    <span class="font-lato text-md py-2 text-gray-500"
      >Calculate MTM Values and generate linear regression charts.</span
    >
  </div>

  <div class="flex flex-col w-full !mt-3 h-[22%]" #inputContainer>
    <div #Row1 class="flex flex-row items-center">
      <div class="flex-1 px-2">
        <label for="day-no" class="font-lato">Day No.</label>
        <br />
        <select
          id="day-no"
          [(ngModel)]="dayOption"
          name="---"
          class="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg block p-2.5 w-full">
          <option value="0">Day 0</option>
          <option value="1">Day 1</option>
        </select>
      </div>
      <div appearance="fill" class="flex-1 px-2">
        <label for="trade-date" class="label-title">Trade Date</label>
        <br />
        <input
          id="trade-date"
          type="date"
          [(ngModel)]="tradeDate"
          required
          #udate
          class="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg w-full p-2.5" />
      </div>
      <div appearance="fill" class="flex-1 px-2">
        <label for="settlementDate" class="label-title">Settlement Date</label>
        <br />
        <input
          id="settlementDate"
          type="date"
          [(ngModel)]="settlementDate"
          required
          class="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg w-full p-2.5" />
      </div>
      <div appearance="fill" class="flex-1 px-2">
        <label for="valuationDate" class="label-title">Valuation Date</label>
        <br />
        <input
          id="valuationDate"
          type="date"
          [(ngModel)]="valuationDate"
          required
          class="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg w-full p-2.5" />
      </div>
    </div>
    <div #Row2 class="flex flex-row items-end mt-3">
      <div class="flex-1 px-2">
        <label for="notionalValue" class="font-lato">Notional Value</label>
        <br />
        <input
          id="notionalValue"
          class="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg block p-2.5 w-full"
          [(ngModel)]="notionalValue"
          required />
      </div>
      <div class="flex-1 px-2">
        <label for="dealId" class="font-lato">Deal ID</label>
        <br />
        <input
          id="dealId"
          class="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg block p-2.5 w-full"
          [(ngModel)]="dealId"
          required />
      </div>
      <div class="flex-1 px-2">
        <label for="forwardRate" class="font-lato">Forward Rate</label>
        <br />
        <input
          id="forwardRate"
          class="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg block p-2.5 w-full"
          [(ngModel)]="forwardRate"
          required />
      </div>
      <div class="flex-1 px-2">
        <label for="fundingSpread" class="font-lato">Funding Spread</label>
        <br />
        <input
          id="fundingSpread"
          class="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg block p-2.5 w-full"
          [(ngModel)]="fundingSpread"
          required />
      </div>
      <button
        (click)="calculateFRA()"
        type="button"
        class="text-white bg-blue-700 hover:bg-blue-800 font-medium rounded-lg text-sm !px-28 py-2.5 mb-0.5 mr-2">
        Calculate
      </button>
    </div>
  </div>

  <div
    #outputContainer
    class="{{
      isMtmLoaded ? 'block' : 'hidden'
    }} mt-5 flex flex-row justify-between flex-1 pb-1 ">
    <div
      id="regressionContainer"
      class="bg-white rounded-lg mat-elevation-z2 w-[70%] h-full mr-2">
      <div #header class="flex flex-row justify-between px-3 py-3 items-center">
        <div class="">
          <span id="name" class="font-lato">Linear Regression</span>
        </div>
      </div>
      <hr />
      <div id="valueContainer" class="flex flex-row justify-between w-full p-2">
        <div class="mr-4 flex-1 w-full">
          <label for="slope" class="font-thin text-lg">Slope</label>
          <br />
          <input
            id="slope"
            class="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg block p-2.5 w-full"
            [(ngModel)]="hiCoef"
            disabled />
        </div>
        <div class="mr-4 flex-1 w-full">
          <label for="pvalue" class="font-thin text-lg">P-value</label>
          <br />
          <input
            id="pvalue"
            class="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg block p-2.5 w-full"
            [(ngModel)]="hiPValue"
            disabled />
        </div>
        <div class="flex-1 w-full">
          <label for="R-squared" class="font-thin text-lg">R-squared</label>
          <br />
          <input
            id="R-squared"
            class="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg block p-2.5 w-full"
            [(ngModel)]="hiRSquared"
            disabled />
        </div>
      </div>
      <div
        class="graphContainer px-2 py-4 rounded-lg border-2 border-gray-500 m-2"
        style="position: relative; height: 65%">
        <canvas #RegressionChartCanvas>{{ regressionChart }}</canvas>
      </div>
    </div>
    <div
      #mtmContainer
      class="bg-white rounded-lg mat-elevation-z2 flex-1 h-full flex flex-col">
      <div #header class="flex flex-row justify-between px-3 py-3 items-center">
        <span id="name" class="font-lato">MTM Values</span>
      </div>
      <hr />

      <div class="flex-1 flex flex-col justify-between p-2">
        <div id="valueContainer" class="flex flex-col items-start">
          <div class="w-full">
            <label for="Hedging" class="font-thin text-lg"
              >Hedging Instrument
            </label>
            <br />
            <input
              id="Hedging"
              class="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg block p-2.5 w-full"
              ngModel="{{ mtm1 }}"
              disabled />
          </div>
          <div class="w-full mt-4">
            <label for="Hedged" class="font-thin text-lg">Hedged Item </label>
            <br />
            <input
              id="Hedged"
              class="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg block p-2.5 w-full"
              ngModel="{{ mtm2 }}"
              disabled />
          </div>
        </div>
        <button
          (click)="downloadDump()"
          type="button"
          class="text-white bg-blue-700 hover:bg-blue-800 font-medium rounded-lg text-sm px-3 py-2.5 w-full">
          Download Dumps
        </button>
      </div>
    </div>
  </div>
</div>
