import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DbService } from 'src/app/core/services/db.service';
import { ViewUserDialogComponent } from './view-user-dialog/view-user-dialog.component';
import { UpdateUserDialogComponent } from './update-user-dialog/update-user-dialog.component';
import { ManageUserDialogComponent } from './manage-user-dialog/manage-user-dialog.component';
import { MatTableDataSource } from '@angular/material/table';
import { AddUserDialogComponent } from './add-user-dialog/add-user-dialog.component';

@Component({
  selector: 'app-user-management',
  templateUrl: './user-management.component.html',
  styleUrls: ['./user-management.component.scss'],
})
export class UserManagementComponent implements OnInit {
  constructor(
    private dbService: DbService,
    private dialog: MatDialog
  ) {}
  displayedColumns: string[] = [
    'No',
    'User Code',
    'User Name',
    'User Email',
    'Company Group ID',
    'Actions',
  ];
  userlist!: MatTableDataSource<any>;

  ngOnInit(): void {
    this.userlist = new MatTableDataSource();
    //Get currrent menus from database
    this.getUsers();
  }

  openAddUserDialog() {
    const userCode = '';
    this.dialog.open(AddUserDialogComponent, {
      data: { userCode: userCode },
    });
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;

    this.userlist.filter = filterValue.trim().toLowerCase();
  }

  getUsers() {
    this.dbService.admin([], 'getUsers').subscribe((data: any) => {
      this.userlist.data = data;
    });
  }
  viewUser(userCode: string) {
    console.log('viewing user :' + userCode);
    this.dialog.open(ViewUserDialogComponent, {
      data: { userCode: userCode },
    });
  }
  updateUser(userCode: string) {
    console.log('updating user :' + userCode);
    this.dialog.open(UpdateUserDialogComponent, {
      data: { userCode: userCode },
    });
  }
  manageUser(userCode: string) {
    console.log('managing user :' + userCode);
    this.dialog.open(ManageUserDialogComponent, {
      data: { userCode: userCode },
    });
  }
}
