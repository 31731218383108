<div id="header" class="mt-10 flex flex-col">
  <span class="font-lato text-2xl">FRA Upload</span>
  <span class="font-lato text-md py-2 text-gray-500"
    >Upload your FRA related files for processing!</span
  >
</div>

<div id="uploadContainer" class="my-4 ml-1">
  <!-- INPUT FOR BOND DETAILS -->
  <div #bondDetails class="mb-3">
    <span class="font-lato text-xl">Bond Details</span>
    <div class="bg-transparent h-4"></div>
    <label for="file-input" class="sr-only">Choose file</label>
    <input
      type="file"
      (change)="onChange($event, 'bondD')"
      class="block w-full border bg-white border-gray-200 shadow-sm rounded-md text-sm focus:z-10 focus:border-blue-500 focus:ring-blue-500 file:bg-transparent file:border-0 file:bg-gray-50 file:border-r file:mr-4 file:py-3 file:px-4" />
  </div>
  <!-- INPUT FOR COUPON PAYMENTS -->
  <div #couponPayments class="mb-3">
    <span class="font-lato text-xl">Coupon Payments</span>
    <div class="bg-transparent h-4"></div>
    <label for="file-input" class="sr-only">Choose file</label>
    <input
      type="file"
      (change)="onChange($event, 'couponP')"
      class="block w-full border bg-white border-gray-200 shadow-sm rounded-md text-sm focus:z-10 focus:border-blue-500 focus:ring-blue-500 file:bg-transparent file:border-0 file:bg-gray-50 file:border-r file:mr-4 file:py-3 file:px-4" />
  </div>
  <!-- INPUT FOR OIS DUMP -->
  <div #oisDump class="mb-3">
    <span class="font-lato text-xl">OIS Dump</span>
    <div class="bg-transparent h-4"></div>
    <label for="file-input" class="sr-only">Choose file</label>
    <input
      type="file"
      (change)="onChange($event, 'oisD')"
      class="block w-full border bg-white border-gray-200 shadow-sm rounded-md text-sm focus:z-10 focus:border-blue-500 focus:ring-blue-500 file:bg-transparent file:border-0 file:bg-gray-50 file:border-r file:mr-4 file:py-3 file:px-4" />
  </div>
  <!-- INPUT FOR SPOT YEILDS -->
  <div #spotYeilds class="mb-3">
    <span class="font-lato text-xl">Spot Yeilds</span>
    <div class="bg-transparent h-4"></div>
    <label for="file-input" class="sr-only">Choose file</label>
    <input
      type="file"
      (change)="onChange($event, 'spotY')"
      class="block w-full border bg-white border-gray-200 shadow-sm rounded-md text-sm focus:z-10 focus:border-blue-500 focus:ring-blue-500 file:bg-transparent file:border-0 file:bg-gray-50 file:border-r file:mr-4 file:py-3 file:px-4" />
  </div>
</div>
<button
  (click)="onUpload()"
  type="button"
  class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2">
  Upload Files
</button>
