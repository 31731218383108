import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { first } from 'rxjs';
import { DbService } from 'src/app/core/services/db.service';

@Component({
  selector: 'app-txndata',
  templateUrl: './txndata.component.html',
  styleUrls: ['./txndata.component.scss'],
})
export class TxndataComponent implements OnInit {
  @ViewChild('fileInput') fileInput!: ElementRef<HTMLInputElement>;

  allcompList: any[] = [];
  statementList: any[] = [];
  migrationLog: any[] = [];

  selectedFile: File | null = null;

  constructor(
    private dbservice: DbService,
    private http: HttpClient
  ) {}

  ngOnInit(): void {
    this.allCompList();
    this.statementList = [
      { id: 'cptxn', value: 'CP Transaction Data' },
      { id: 'cpinv', value: 'CP Investor Data' },
      { id: 'annx', value: 'Annexure Data' },
    ];

    this.cpuploadlog();
  }
  //txndatadisplay: any[] =[];
  txndataupload = new FormGroup({
    statement: new FormControl(''),
    company: new FormControl(''),
    udate: new FormControl(''),
    uploadfile: new FormControl(''),
  });

  txndatadisplay = new FormGroup({
    statement: new FormControl(''),
    company: new FormControl(''),
    frmdate: new FormControl(''),
    todate: new FormControl(''),
  });

  txndatadelete = new FormGroup({
    statement: new FormControl(''),
    company: new FormControl(''),
    fromdate: new FormControl(''),
    enddate: new FormControl(''),
  });

  allCompList() {
    this.dbservice
      .cpdoc([], 'allCompList')
      .pipe(first())
      .subscribe({
        next: (data: any) => {
          this.allcompList = data['res'];
          // console.log(this.mclrBankList);
        },
        error: (err: any) => {
          console.log(err);
        },
      });
  }

  handleFileInput(files: FileList) {
    const fileToUpload = files.item(0);
    if (fileToUpload) {
      const formData = new FormData();
      formData.append('file', fileToUpload);

      // Send the file to the PHP server
      this.http.post('http://your-php-server/upload.php', formData).subscribe(
        () => {
          console.log('File uploaded successfully');
        },
        error => {
          console.error('Error uploading file:', error);
        }
      );
    }
  }

  formData: any = [];
  cpdataList: any[] = [];
  frmdata: any = [];

  displayCpData() {
    this.formData = this.txndatadisplay.value;
    this.dbservice
      .cpdoc(this.formData, 'displayCpData')
      .pipe(first())
      .subscribe({
        next: (data: any) => {
          this.cpdataList = data['res'];
          // console.log(this.mclrBankList);
        },
        error: (err: any) => {
          console.log(err);
        },
      });
  }

  msg = '';
  deleteCpData() {
    this.frmdata = this.txndatadelete.value;
    this.dbservice
      .cpdoc(this.frmdata, 'deleteCpData')
      .pipe(first())
      .subscribe({
        next: (data: any) => {
          if (data['res']['status'] === 'true') {
            this.msg = 'Data Deleted successfully';
          } else {
            this.msg = 'Data Not Found';
          }
          //this.cpdataList = data['res'];
          // console.log(this.mclrBankList);
        },
        error: (err: any) => {
          console.log(err);
        },
      });
  }

  cpuploadlog() {
    this.dbservice
      .cpdoc([], 'cpuploadlog')
      .pipe(first())
      .subscribe({
        next: (data: any) => {
          this.migrationLog = data['res'];
          // console.log(this.mclrBankList);
        },
        error: (err: any) => {
          console.log(err);
        },
      });
  }

  onFileSelected(files: FileList | null) {
    if (files && files.length > 0) {
      this.selectedFile = files.item(0);
    } else {
      this.selectedFile = null;
    }
  }

  dataupload: any = [];

  upload() {
    this.dataupload = this.txndataupload.value;
    console.log(this.txndataupload.value);
    const compcode: any = this.txndataupload.controls['company'].value;
    const stmt: any = this.txndataupload.controls['statement'].value;
    this.dataupload.company = compcode.comp_code;
    this.dataupload.stmt = stmt.id;
    /* this.toDate1 = new Date(this.dataupload.udate); */
    this.dataupload.udate = JSON.stringify(
      new Date(this.dataupload.udate).toLocaleString('en-IN', {
        timeZone: 'Asia/Kolkata',
      })
    ).slice(1, 10);
    if (this.selectedFile) {
      this.dbservice
        .uploadFile(this.selectedFile, 'uploadCPFile', this.dataupload)
        .subscribe({
          next: () => {
            console.log('File uploaded successfully.');
            // Handle the response from the backend if needed
          },
          error: error => {
            console.error('Error uploading file:', error);
          },
        });
    }
  }

  displayedColumns: string[] = [
    'srno',
    'date',
    'company',
    'statement',
    'user',
    'filename',
    'errorlog',
  ];

  displaytxndata: string[] = [
    'srno',
    'date',
    'classid',
    'txnno',
    'isin',
    'units',
    'issueamt',
    'cid',
    'cdt',
    'action',
  ];
}
