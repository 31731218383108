<div id="dialogContainer" class="px-5 py-10 w-[1000px]">
  <div class="flex flex-row justify-between mb-10">
    <div class="flex flex-row items-center">
      <span class="font-lato text-xl"
        >Viewing User : {{ data['userCode'] }}</span
      >
    </div>
    <div class="flex flex-row items-center">
      <img
        class="w-[25px] h-[25px] hover:opacity-25 duration-300"
        src="assets/icons/close.png"
        alt=""
        mat-dialog-close="" />
    </div>
  </div>
  <div id="inputContainer" class="flex flex-row w-full">
    <div id="leftPanel" class="flex-1">
      <span>Required Fields</span>
      <div class="flex flex-row w-full justify-between">
        <div #UserCode class="mr-2 flex-1">
          <label for="userCode" class="font-lato">User Code</label>
          <br />
          <input
            id="userCode"
            class="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg block p-2.5 w-full"
            [(ngModel)]="UserArray['USER_CODE']"
            readonly />
        </div>
        <div class="mr-2 flex-1">
          <label for="userType" class="font-lato">User Type</label>
          <br />
          <input
            id="userType"
            class="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg block p-2.5 w-full"
            [(ngModel)]="UserArray['USER_TYPE']"
            readonly />
        </div>
        <div class="mr-2 flex-1">
          <label for="userStatus" class="font-lato">User Status</label>
          <br />
          <input
            id="userStatus"
            class="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg block p-2.5 w-full"
            [(ngModel)]="UserArray['USER_R_STATUS']"
            readonly />
        </div>
      </div>
      <div id="nameContainer" class="flex flex-row">
        <div class="mr-2 w-[5rem]">
          <label for="prefix" class="font-lato">Prefix</label>
          <br />
          <input
            id="prefix"
            class="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg block p-2.5 w-full"
            [(ngModel)]="UserArray['USER_TITLE']"
            readonly />
        </div>
        <div class="mr-2 flex-1">
          <label for="firstName" class="font-lato">First Name</label>
          <br />
          <input
            id="firstName"
            class="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg block p-2.5 w-full"
            [(ngModel)]="UserArray['USER_NAME']"
            readonly />
        </div>

        <div class="mr-2 flex-1">
          <label for="lastName" class="font-lato">Last Name</label>
          <br />
          <input
            id="lastName"
            class="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg block p-2.5 w-full"
            [(ngModel)]="UserArray['USER_LAST_NAME']"
            readonly />
        </div>
      </div>
      <div #UserEmail class="mr-2 flex-1">
        <label for="Email" class="font-lato">Email</label>
        <br />
        <input
          id="Email"
          class="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg block p-2.5 w-full"
          [(ngModel)]="UserArray['USER_EMAIL']"
          readonly />
      </div>

      <div #UserCompanyGrpID class="mr-2 flex-1">
        <label for="cgrp_id" class="font-lato">Company Group ID</label>
        <br />
        <input
          id="cgrp_id"
          class="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg block p-2.5 w-full"
          [(ngModel)]="UserArray['USER_COMPANY_GROUP_ID']"
          readonly />
      </div>
      <div id="validityContainer" class="flex flex-row w-full">
        <div appearance="fill" class="flex-1 mr-2">
          <label for="validFrom" class="label-title">Valid From</label>
          <br />
          <input
            id="validFrom"
            type="date"
            [(ngModel)]="UserArray['USER_FROM_DATE']"
            class="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg w-full p-2.5" />
        </div>
        <div appearance="fill" class="flex-1 mr-2">
          <label for="validTo" class="label-title">Valid To</label>
          <br />
          <input
            id="validTo"
            type="date"
            [(ngModel)]="UserArray['USER_TO_DATE']"
            class="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg w-full p-2.5" />
        </div>
      </div>
    </div>
    <div id="right" class="flex-1">
      <span>Additional Fields</span>
      <div #UserManager class="mr-2 flex-1">
        <label for="userManager" class="font-lato">User Manager</label>
        <br />
        <input
          id="userManager"
          class="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg block p-2.5 w-full"
          [(ngModel)]="UserArray['USER_MANAGER']"
          readonly />
      </div>
      <div #UserUrl class="mr-2 flex-1">
        <label for="userUrl" class="font-lato">User URL</label>
        <br />
        <input
          id="userUrl"
          class="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg block p-2.5 w-full"
          [(ngModel)]="UserArray['USER_URL']"
          readonly />
      </div>
      <div #UserLanding class="mr-2 flex-1">
        <label for="userLandingMenu" class="font-lato"
          >User Landing Menu Code</label
        >
        <br />
        <input
          id="userLandingMenu"
          class="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg block p-2.5 w-full"
          [(ngModel)]="UserArray['USER_LANDING_MENU_CODE']"
          readonly />
      </div>
      <div #UserPhoneNo class="mr-2 flex-1">
        <label for="phoneNo" class="font-lato">Phone Number</label>
        <br />
        <input
          id="phoneNo"
          class="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg block p-2.5 w-full"
          [(ngModel)]="UserArray['USER_MOBILE']"
          readonly />
      </div>
    </div>
  </div>
</div>
