<div id="header" class="mt-10 flex flex-col">
  <span class="font-lato text-2xl">Company Bank List Management</span>
  <span class="font-lato text-md py-2 text-gray-500">Add and Edit Banks</span>
</div>

<div
  id="CompanyList"
  class="bg-white mt-4 pb-1 rounded-lg mat-elevation-z2"
  *ngIf="showListing">
  <div #tableHeader class="flex flex-row justify-between px-3 items-center">
    <div class="py-3">
      <span id="name" class="font-lato">Full Company List</span>
    </div>

    <button
      (click)="addCompBank()"
      type="button"
      class="text-white font-lato bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2">
      Add Bank
    </button>
  </div>
  <hr />
  <table mat-table [dataSource]="compbankList">
    <!-- Position Column -->
    <ng-container matColumnDef="srno">
      <th mat-header-cell *matHeaderCellDef>#</th>
      <td mat-cell *matCellDef="let element; let i = index">
        {{ i + 1 }}
      </td>
    </ng-container>

    <!-- Name Column -->
    <ng-container matColumnDef="compcode">
      <th mat-header-cell *matHeaderCellDef>Company Code</th>
      <td mat-cell *matCellDef="let element">{{ element.code }}</td>
    </ng-container>

    <!-- Weight Column -->
    <ng-container matColumnDef="txnid">
      <th mat-header-cell *matHeaderCellDef>Transaction ID</th>
      <td mat-cell *matCellDef="let element">
        <a href="javascript:;" (click)="editrec(element)">{{
          element.refid
        }}</a>
      </td>
    </ng-container>

    <!-- Name Column -->
    <ng-container matColumnDef="accno">
      <th mat-header-cell *matHeaderCellDef>Account No.</th>
      <td mat-cell *matCellDef="let element">{{ element.accno }}</td>
    </ng-container>

    <!-- Weight Column -->
    <ng-container matColumnDef="bankname">
      <th mat-header-cell *matHeaderCellDef>Bank Name</th>
      <td mat-cell *matCellDef="let element">{{ element.name }}</td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
</div>

<div class="example-container" *ngIf="showForm">
  <form [formGroup]="compbankform" class="reg" (ngSubmit)="saveCompBank()">
    <div class="container">
      <div class="form-header">
        <h1 style="font-weight: 700; color: rgb(107 114 128)">
          Company Bank List
        </h1>
      </div>

      <div class="form-body">
        <div class="horizontal-group">
          <div class="form-group left">
            <label for="comp_code" class="label-title">Company Code</label>
            <select id="comp_code" formControlName="compcode" required>
              <option *ngFor="let comp of allcompList" [value]="comp">
                {{ comp.comp_code }} - {{ comp.comp_name }}
              </option>
            </select>
          </div>
          <div>
            <label for="bankname" class="label-title">Bank Name</label>
            <select
              id="bankname"
              formControlName="bank"
              class="form-group left"
              required>
              <option *ngFor="let bank of allbankList" [value]="bank">
                {{ bank.name }}
              </option>
            </select>
          </div>
          <!-- Remaining form fields -->

          <div class="form-group left">
            <label for="transactionid" class="label-title"
              >Transaction ID</label
            >
            <br />
            <input
              id="transactionid"
              matInput
              formControlName="txnid"
              class="form-group left"
              required />
          </div>
          <div class="form-group left">
            <label for="accno" class="label-title">Account No.</label>
            <br />
            <input
              id="accno"
              matInput
              formControlName="accno"
              class="form-group left"
              required />
          </div>
          <div class="form-group left">
            <label for="ifsc" class="label-title">IFSC Code</label>
            <br />
            <input
              id="ifsc"
              matInput
              formControlName="ifsc"
              class="form-group left" />
          </div>
          <div class="form-group left">
            <label for="branch" class="label-title">Branch Name</label>
            <br />
            <input
              id="branch"
              matInput
              formControlName="branch"
              class="form-group left" />
          </div>
          <div class="form-group left">
            <label for="email" class="label-title">Bank Email</label>
            <br />
            <input
              id="email"
              matInput
              formControlName="email"
              class="form-group left" />
          </div>
          <div class="form-group left">
            <label for="addr1" class="label-title">Address</label>
            <br />
            <input
              id="addr1"
              matInput
              formControlName="addr1"
              class="form-group left" />
          </div>
          <div class="form-group left">
            <label for="addr2" class="label-title">Address Line</label>
            <br />
            <input
              id="addr2"
              matInput
              formControlName="addr2"
              class="form-group left" />
          </div>
          <div class="form-group left">
            <label for="city" class="label-title">City</label>
            <br />
            <input
              id="city"
              matInput
              formControlName="city"
              class="form-group left" />
          </div>
          <div class="form-group left">
            <label for="pincode" class="label-title">Postal Code</label>
            <br />
            <input
              id="pincode"
              matInput
              formControlName="pincode"
              class="form-group left" />
          </div>
          <div class="form-group left">
            <label for="country" class="label-title">Country</label>
            <br />
            <input
              id="country"
              matInput
              formControlName="country"
              class="form-group left" />
          </div>
          <div class="form-group left">
            <label for="state" class="label-title">State</label>
            <br />
            <input
              id="state"
              matInput
              formControlName="state"
              class="form-group left" />
          </div>
        </div>
      </div>
      <div class="form-footer">
        <button mat-raised-button class="cta-button">Submit</button>
        &nbsp;
        <button mat-raised-button class="cta-button" (click)="HideForm()">
          Back
        </button>
      </div>
    </div>
    <div></div>
    <!-- <div class="form-footer">
            <div class="form-footer">
                 <span>* required</span> -->

    <!-- </div>
        </div> -->
    <!-- <section>
          <div class="cta-button-row">
            <button mat-raised-button class="cta-button">
              Submit
            </button>
            <button mat-raised-button class="cta-button" (click)="HideForm()">
              Back
            </button>
          </div>
        </section> -->
  </form>
</div>
