import { Component } from '@angular/core';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-locked',
  templateUrl: './locked.component.html',
  styleUrls: ['./locked.component.scss'],
})
export class LockedComponent {
  constructor(private auth: AuthService) {}
  logout() {
    //Logs out the session from auth service
    this.auth.logout();
  }
}
