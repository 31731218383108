import { FileService } from './../../../core/services/file.service';
import { Component } from '@angular/core';

@Component({
  selector: 'app-fra-upload',
  templateUrl: './fra-upload.component.html',
  styleUrls: ['./fra-upload.component.scss'],
})
export class FraUploadComponent {
  bondDetailsFile: any;
  couponPaymentsFile: any;
  oisDumpFile: any;
  spotYeildsFile: any;
  shortLink = '';
  loading = false;
  constructor(private fileService: FileService) {}

  onChange(event: any, type: string) {
    console.log(event);
    switch (type) {
      case 'bondD':
        this.bondDetailsFile = event.target.files[0];
        break;
      case 'couponP':
        this.couponPaymentsFile = event.target.files[0];
        break;
      case 'oisD':
        this.oisDumpFile = event.target.files[0];
        break;
      case 'spotY':
        this.spotYeildsFile = event.target.files[0];
        break;
    }
  }
  onUpload() {
    this.loading = !this.loading;
    console.log(this.bondDetailsFile);
    this.fileService
      .upload(
        this.bondDetailsFile,
        this.couponPaymentsFile
        // this.oisDumpFile,
        // this.spotYeildsFile
      )
      .subscribe((event: any) => {
        if (typeof event === 'object') {
          this.shortLink = event.link;
          this.loading = false;
        }
      });
  }
}
