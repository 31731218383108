import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { DbService } from 'src/app/core/services/db.service';

@Component({
  selector: 'app-add-menu-dialog',
  templateUrl: './add-menu-dialog.component.html',
  styleUrls: ['./add-menu-dialog.component.scss'],
})
export class AddMenuDialogComponent {
  constructor(public dbService: DbService) {}
  menuName = '';
  menuCode = '';

  addMenu() {
    this.dbService.admin([this.menuName, this.menuCode], 'addMenu').subscribe({
      next: (data: any) => {
        console.log(data);
      },
    });
  }
}
