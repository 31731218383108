import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
//For Auth
import { environment } from 'src/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './features/login/login.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatListModule } from '@angular/material/list';
import { MatSelectModule } from '@angular/material/select';
import { MatTreeModule } from '@angular/material/tree';
import { MatDialogModule } from '@angular/material/dialog';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatButtonToggleModule } from '@angular/material/button-toggle';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatIconModule } from '@angular/material/icon';
import { MatTableModule } from '@angular/material/table';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

import { CompanyComponent } from './features/admin/company/company.component';
import { DashboardComponent } from './features/dashboard/dashboard.component';
import { DocumenttypeComponent } from './features/admin/documenttype/documenttype.component';
import { CompanygroupComponent } from './features/admin/companygroup/companygroup.component';
import { UsersComponent } from './features/admin/users/users.component';
import { FormgenerationComponent } from './features/CP/Txn/formgeneration/formgeneration.component';
import { TxndataComponent } from './features/CP/Txn/txndata/txndata.component';
import { FindataComponent } from './features/CP/Txn/findata/findata.component';
import { PdfdialogComponent } from './features/CP/Txn/pdfdialog/pdfdialog.component';
import { MasterdataComponent } from './features/CP/master/masterdata/masterdata.component';
import { GenerateallformsComponent } from './features/CP/Txn/generateallforms/generateallforms.component';
import { BanklistComponent } from './features/CC/banklist/banklist.component';
import { CompanybankComponent } from './features/CC/companybank/companybank.component';
import { StmtconverterComponent } from './features/CC/stmtconverter/stmtconverter.component';
import { CcstatementComponent } from './features/CC/ccstatement/ccstatement.component';

//firebase
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { LandingpageComponent } from './features/landingpage/landingpage.component';
import { MfExposureComponent } from './features/mutualfund/mf-exposure/mf-exposure.component';
import { LockedComponent } from './core/components/locked/locked.component';
import { FraUploadComponent } from './features/fra/fra-upload/fra-upload.component';
import { FraDashboardComponent } from './features/fra/fra-dashboard/fra-dashboard.component';
import { UserManagementComponent } from './features/adminpanel/user-management/user-management.component';
import { MenuManagementComponent } from './features/adminpanel/menu-management/menu-management.component';
import { AddMenuDialogComponent } from './features/adminpanel/menu-management/add-menu-dialog/add-menu-dialog.component';
import { UpdateUserDialogComponent } from './features/adminpanel/user-management/update-user-dialog/update-user-dialog.component';
import { ManageUserDialogComponent } from './features/adminpanel/user-management/manage-user-dialog/manage-user-dialog.component';
import { ViewUserDialogComponent } from './features/adminpanel/user-management/view-user-dialog/view-user-dialog.component';
import { AddUserDialogComponent } from './features/adminpanel/user-management/add-user-dialog/add-user-dialog.component';
import { MainSpinnerComponent } from './core/components/spinners/main-spinner/main-spinner.component';
import { LoadingInterceptor } from './core/interceptors/loading.interceptor';
import { UploadFileModalComponent } from './features/payments/components/upload-file-modal/upload-file-modal.component';

//google
/* import { SocialLoginModule, SocialAuthServiceConfig } from 'angularx-social-login';
import {
  GoogleLoginProvider,
  FacebookLoginProvider
} from 'angularx-social-login';
 */

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    CompanyComponent,
    DashboardComponent,
    DocumenttypeComponent,
    CompanygroupComponent,
    UsersComponent,
    FormgenerationComponent,
    TxndataComponent,
    FindataComponent,
    PdfdialogComponent,
    MasterdataComponent,
    GenerateallformsComponent,
    BanklistComponent,
    CompanybankComponent,
    StmtconverterComponent,
    CcstatementComponent,
    LandingpageComponent,
    MfExposureComponent,
    LockedComponent,
    FraUploadComponent,
    FraDashboardComponent,
    UserManagementComponent,
    MenuManagementComponent,
    AddMenuDialogComponent,
    UpdateUserDialogComponent,
    ManageUserDialogComponent,
    ViewUserDialogComponent,
    AddUserDialogComponent,
    MainSpinnerComponent,
    UploadFileModalComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    MatFormFieldModule,
    MatInputModule,
    MatTableModule,
    MatToolbarModule,
    MatSidenavModule,
    MatDividerModule,
    MatExpansionModule,
    MatListModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    MatIconModule,
    MatCardModule,
    MatButtonModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatSelectModule,
    MatTabsModule,
    MatTreeModule,
    MatDialogModule,
    MatTooltipModule,
    MatGridListModule,
    MatProgressSpinnerModule,
    MatButtonToggleModule,
    AngularFireAuthModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoadingInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}
