import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/core/services/auth.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  //Form control vars
  email = '';
  password = '';
  rememberMe = false;

  //CSS control vars
  emailError = false;
  emailBox = '';

  passwordError = false;
  passwordBox = '';

  constructor(
    private auth: AuthService,
    private router: Router
  ) {}

  ngOnInit(): void {
    if (localStorage.getItem('token') != null) {
      this.router.navigate(['dashboard']);
    }
    this.emailBox = 'gray';
    this.passwordBox = 'gray';
    if (localStorage.getItem('saved-email') != '') {
      this.email = localStorage.getItem('saved-email')!;
      this.rememberMe = true;
    }
  }
  //Functionality for showing/hiding passkey in login form
  public showPassword = false;
  public togglePasswordVisibility(): void {
    this.showPassword = !this.showPassword;
  }

  //Function for listening to Remember me value
  public rememberMeChanged(value: boolean) {
    this.rememberMe = value;
    console.log(this.rememberMe);
  }

  public errorCheck(): boolean {
    if (this.email == '') {
      this.emailBox = 'red';
      alert('Please enter your email-id');
      return true;
    } else {
      this.emailBox = 'gray';
    }
    if (this.password == '') {
      this.passwordBox = 'red';
      alert('Please enter your password');
      return true;
    } else {
      this.passwordBox = 'gray';
    }
    return false;
  }

  login() {
    //Validation and login
    if (!this.errorCheck()) {
      //Remember me function
      if (this.rememberMe) {
        localStorage.setItem('saved-email', this.email);
      } else {
        localStorage.setItem('saved-email', '');
      }
      //START LOGIN
      this.auth.login(this.email, this.password);
    }
  }

  loginWithGoogle() {
    //login with google Oauth
    this.auth.loginWithGoogle();
    //this.auth.loginWithFirebase(this.email, this.password);
  }
}
