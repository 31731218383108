<div id="header" class="flex flex-row justify-between items-center">
  <div class="mt-10 flex flex-col">
    <span class="font-lato text-2xl">Menu Management Panel</span>
    <span class="font-lato text-md py-2 text-gray-500"
      >Add or Manage your menus here!</span
    >
  </div>
  <button
    (click)="openDialog()"
    type="button"
    class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm h-1/2 px-5 py-2.5 mr-2">
    Add Menu
  </button>
</div>

<div id="menulist" class="bg-white mt-4 pb-1 rounded-lg mat-elevation-z2">
  <div class="py-3 px-3">
    <span id="name" class="font-lato">Menu List</span>
  </div>
  <hr />
  <table mat-table [dataSource]="menulist" class="w-full">
    ]
    <ng-container matColumnDef="Menu Name">
      <th mat-header-cell *matHeaderCellDef>Menu Name</th>
      <td mat-cell *matCellDef="let element">{{ element.MENU_NAME }}</td>
    </ng-container>

    <ng-container matColumnDef="Menu Code">
      <th mat-header-cell *matHeaderCellDef>Menu Code</th>
      <td mat-cell *matCellDef="let element">{{ element.MENU_CODE }}</td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
</div>
