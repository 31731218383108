<div id="header" class="mt-10 flex flex-col">
  <span class="font-lato text-2xl">Bank List</span>
  <span class="font-lato text-md py-2 text-gray-500"
    >A list of banks in the records</span
  >
</div>

<div id="bankList" class="bg-white mt-4 py-1 rounded-lg mat-elevation-z2">
  <table mat-table [dataSource]="bankList">
    <!-- Position Column -->
    <ng-container matColumnDef="srno">
      <th mat-header-cell *matHeaderCellDef class="th-srno">#</th>
      <td mat-cell *matCellDef="let element; let i = index">
        {{ i + 1 }}
      </td>
    </ng-container>

    <!-- Name Column -->
    <ng-container matColumnDef="bankcode">
      <th mat-header-cell *matHeaderCellDef class="th-bankcode">Bank Code</th>
      <td mat-cell *matCellDef="let element">{{ element.code }}</td>
    </ng-container>

    <!-- Weight Column -->
    <ng-container matColumnDef="bankname">
      <th mat-header-cell *matHeaderCellDef class="th-bankname">Bank Name</th>
      <td mat-cell *matCellDef="let element">{{ element.name }}</td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
</div>
