//Service for anything related to authentication for firebase
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { DbService } from './db.service';
import { first } from 'rxjs/operators';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { GoogleAuthProvider } from '@angular/fire/auth';
import { ApiUrls } from 'src/apis';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(
    private fireauth: AngularFireAuth,
    private router: Router,
    private dbService: DbService
  ) {}

  getUser() {
    //Function to get user details from the backend session
    this.dbService
      .postRequest(ApiUrls.dashboard, [], 'getUser')
      .pipe(first())
      .subscribe({
        next: (data: any) => {
          return data;
        },
        error: err => {
          return err;
        },
      });
  }

  login(email: string, password: string) {
    this.dbService
      .postRequest(ApiUrls.auth, [email, password], 'loginUser')
      .pipe(first())
      .subscribe({
        next: (result: any) => {
          if (result.status == 'successful') {
            this.setToken(result);
            this.router.navigate(['/dashboard']);
          } else {
            alert('error logging in please try again');
          }
        },
        error: error => {
          alert('User name or password is incorrect');
          console.log(error);
        },
      });
  }

  loginWithFirebase(email: string, password: string): void {
    //Login with firebase method
    this.fireauth.signInWithEmailAndPassword(email, password).then(
      () => {
        this.setToken('true');
        // this.router.navigate(['/dashboard']);
      },
      error => {
        alert(error['code']);
        this.router.navigate(['']);
      }
    );
  }

  loginWithGoogle() {
    return this.AuthLogin(new GoogleAuthProvider());
  }

  loginwithgmail(email: string, password: string) {
    this.dbService
      .postRequest(ApiUrls.auth, [email, password], 'loginUserGmail')
      .pipe(first())
      .subscribe({
        next: (result: any) => {
          if (result.status == 'successful') {
            this.setToken(result);
            this.router.navigate(['/dashboard']);
          } else {
            alert('error logging in please try again');
          }
        },
        error: error => {
          alert('User name or password is incorrect');
          console.log(error);
        },
      });
  }

  AuthLogin(provider: any) {
    return this.fireauth
      .signInWithPopup(provider)
      .then((result: any) => {
        this.loginwithgmail(result.user?.email, '');
        /* this.setToken(JSON.stringify(result.user?.email));
        this.router.navigate(['/dashboard']); */
        console.log('You have been successfully logged in!');
      })
      .catch(error => {
        console.log(error);
      });
  }

  loginWithGoogle1() {
    return this.fireauth.signInWithPopup(new GoogleAuthProvider()).then(
      res => {
        this.setToken(JSON.stringify(res.user?.uid));
        this.router.navigate(['/dashboard']);
      },
      error => {
        alert(error['code']);
      }
    );
  }

  logout() {
    //Logout method----------------------
    this.dbService.postRequest(ApiUrls.auth, [], 'logoutUser').subscribe({
      next: () => {
        this.removeToken();
        localStorage.removeItem('isset');
        this.router.navigate(['']);
      },
      error: error => {
        alert('User name or password is incorrect');
        console.log(error);
      },
    });
    // this.fireauth.signOut().then(
    //   () => {
    //     this.removeToken();
    //     this.router.navigate(['']);
    //   },
    //   (error) => {
    //     alert(error['code']);
    //   }
    // );
  }

  //Validates local token and session token
  validateSession() {
    let sessionId = '';
    let isSessionSet = true;
    this.dbService
      .postRequest(ApiUrls.auth, [], 'sessionExists')
      .subscribe((data: any) => {
        sessionId = data['sessionId'];
        isSessionSet = data['res'];
        if (isSessionSet) {
          if (this.getToken() == null) {
            this.setToken(sessionId);
          }
        }
        if (!isSessionSet) {
          if (this.getToken() != null) {
            this.removeToken();
            this.router.navigate(['']);
          }
        }
      });
  }
  //Checks if user is logged in
  isUserLoggedIn(): boolean {
    const usertoken = this.getToken();
    if (usertoken != null) {
      return true;
    } else {
      return false;
    }
  }

  //Token functions----------------------------
  setToken(token: string) {
    localStorage.setItem('token', token);
  }

  getToken(): string {
    return localStorage.getItem('token') ?? '';
  }

  removeToken(): void {
    if (localStorage.getItem('token') != null) {
      localStorage.removeItem('token');
    }
  }
}
