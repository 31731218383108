//Service for communicating between php and Angular
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DbService {
  baseUrl = 'http://localhost/fsnxtbackend';

  constructor(private http: HttpClient) {
    this.baseUrl = this.setBaseURL();
  }
  public setBaseURL() {
    if (window.location.hostname == 'localhost') {
      return 'http://localhost/fsnxtbackend';
    } else if (window.location.hostname == 'development.fsnxt.com') {
      return 'http://development.fsnxt.com/fsnxtbackend';
    } else {
      return 'http://159.89.175.114/fsnxtbackend';
    }
  }

  public postRequest(
    apiUrl: string,
    params: Array<string | Object>,
    method: string
  ) {
    return this.http
      .post(this.baseUrl + apiUrl, {
        params: params,
        method: method,
      })
      .pipe(
        map(data => {
          return data;
        })
      );
  }

  public admin(params: string[], method: string) {
    //MCLR API
    return this.http
      .post(`${this.baseUrl}/api/admin.api.php`, {
        params: params,
        method: method,
      })
      .pipe(
        map(data => {
          return data;
        })
      );
  }

  public cpdoc(params: string[], method: string) {
    //MCLR API
    return this.http
      .post(`${this.baseUrl}/api/cpdoc.api.php`, {
        params: params,
        method: method,
      })
      .pipe(
        map(data => {
          return data;
        })
      );
  }

  public ccstmt(params: string[], method: string) {
    //MCLR API
    return this.http
      .post(`${this.baseUrl}/api/ccstmt.api.php`, {
        params: params,
        method: method,
      })
      .pipe(
        map(data => {
          return data;
        })
      );
  }

  public fra(params: any[], method: string) {
    //Auth API
    return this.http
      .post<any>(`${this.baseUrl}/features/fra/fra.api.php`, {
        params: params,
        method: method,
      })
      .pipe(
        map(Data => {
          return Data;
        })
      );
  }

  uploadFile(file: File, method: string, param: any) {
    const formData: FormData = new FormData();
    formData.append('file', file, file.name);
    formData.append('company', param.company);
    formData.append('stmt', param.stmt);
    formData.append('udate', param.udate);
    formData.append('bank', param.bank);
    formData.append('accno', param.accno);
    formData.append('txnid', param.txnid);
    //formData.append('date', param.date);
    return this.http
      .post<any>(`${this.baseUrl}/api/cpupload.api.php`, formData, {
        params: { method: method },
      })
      .pipe(
        map((data: any) => {
          return data;
        })
      );
  }

  public forms(params: string[], doctype: string, method: string) {
    //MCLR API
    return this.http
      .post<any>(`${this.baseUrl}/api/cpdoc.api.php`, {
        params: params,
        doctype: doctype,
        method: method,
      })
      .pipe(
        map((Data: any) => {
          return Data;
        })
      );
  }

  public mf(params: any, method: string) {
    //Dashboard API
    return (
      this.http
        .post(`${this.baseUrl}/api/mf.api.php`, {
          params: params,
          method: method,
        })
        // .post<any>(`${this.baseUrl}/api/get-menus.php`, { userCode })
        .pipe(
          map(data => {
            return data;
          })
        )
    );
  }
}
