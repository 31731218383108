import Chart from 'chart.js/auto';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from '@angular/core';
import { first } from 'rxjs';
import { DbService } from 'src/app/core/services/db.service';

@Component({
  selector: 'app-mf-exposure',
  templateUrl: './mf-exposure.component.html',
  styleUrls: ['./mf-exposure.component.scss'],
})
export class MfExposureComponent implements OnInit, AfterViewInit {
  //MF EXP Table Vars
  //----MF EXPOSURE TABLE
  dataSource: any = [];
  displayedColumns: string[] = ['Rating', 'Grand Total'];
  displayedRows: string[] = ['UTCL Investments(Cr)'];

  //----RATING EXPOSURE TABLE
  ratingDataSource: any = [];
  displayedRatingColumns: string[] = ['Issuer', 'Rating', 'Grand Total'];
  displayedRatingRows: string[] = [];
  selectedRating = '';

  //----OS COMPARISON TABLE
  comparisonDataSource: any = [];
  displayedComparisonColumns: string[] = ['Category'];
  displayedComparisonRows: string[] = [];

  //Input Vars
  selectedExposureMonth = '';
  selectedPortfolioMonth = '';
  selectedMfCategory = '';

  portfolioMonths: any = [];
  exposureMonths: any = [];
  mfCategories: any = [];

  //Graph Vars
  public ratingExposureChart: any;
  public schemeExposureChart: any;
  public categoryWiseChart: any;

  @ViewChild('SchemeExposureChartCanvas') schemeChartRef!: ElementRef;
  @ViewChild('RatingExposureChartCanvas') ratingChartRef!: ElementRef;
  @ViewChild('CategoryWiseChartCanvas') categoryWiseChartRef!: ElementRef;

  //Flags for Responsive Loading
  isGraphsLoaded = false;
  isRatingTableLoaded = false;
  isMftableLoaded = false;
  isComparisonTableLoaded = false;
  isSummaryGraphLoaded = false;
  noDataFound = false;

  constructor(
    private dbService: DbService,
    private cdr: ChangeDetectorRef
  ) {}

  //Functions for Input Fields
  getExposureMonths() {
    this.dbService
      .mf([], 'getExposureMonths')
      .pipe(first())
      .subscribe({
        next: (data: any) => {
          this.selectedExposureMonth = data[0].id;
          this.selectedPortfolioMonth = data[0].id;
          this.portfolioMonths = data;
          this.exposureMonths = data;
        },
        error: (err: any) => {
          console.log(err);
        },
      });
  }

  getPortfolioMonths() {
    this.dbService
      .mf([{ m1: this.selectedExposureMonth }], 'getPortfolioMonths')
      .pipe(first())
      .subscribe({
        next: (data: any) => {
          this.selectedPortfolioMonth = data[0].id;
          this.selectedPortfolioMonth = this.selectedExposureMonth;
        },
        error: (err: any) => {
          console.log(err);
        },
      });
  }

  getMfCategories() {
    this.dbService
      .mf([], 'getMfExposureCategories')
      .pipe(first())
      .subscribe({
        next: (data: any) => {
          this.selectedMfCategory = data[0].code;
          this.mfCategories = data;
        },
        error: (err: any) => {
          console.log(err);
        },
      });
  }

  //Functions to update tables and graphs with data
  updateMfExposureTable(data: any) {
    //get columns from data
    this.displayedColumns = ['Rating', 'Grand Total'];
    data['col'].forEach((element: any) => {
      this.displayedColumns.push(element);
    });

    //Getting the Rows
    this.displayedRows = ['UTCL Investments(Cr)'];
    data['rating_row'].forEach((element: any) => {
      this.displayedRows.push(element);
    });
    this.displayedRows.push('Total');

    //Creating the datasources
    this.dataSource = [];
    let rowData: any = {};

    this.displayedRows.forEach((rowCategory: any, index: number) => {
      rowData = {};
      //Creating a special case for the first row
      if (index == 0) {
        rowData = { 0: rowCategory, 1: data['invmts_total'].toFixed(2) };
        data['col'].forEach((colValue: any, index: number) => {
          rowData[index + 2] = data['invmts'][index]['amount'].toFixed(2);
        });
        //Special case for totals row
      } else if (index == this.displayedRows.length - 1) {
        rowData = {
          0: rowCategory,
          1: data['total_mvaluep'].toFixed(2) + ' %',
        };
        data['col'].forEach((colValue: any, index: number) => {
          rowData[index + 2] =
            data['total'][colValue]['total'].toFixed(2) + ' %';
        });
      } else {
        //populating rows for individual ratings
        rowData = {
          0: rowCategory,
          1:
            data['result'][rowCategory]['mvaluep'].toFixed(2).toString() + ' %',
        };
        data['col'].forEach((colValue: any, index: number) => {
          let temp;
          if (data['result'][rowCategory][colValue] != null) {
            temp =
              data['result'][rowCategory][colValue].toFixed(2).toString() +
              ' %';
          } else {
            temp = '';
          }

          rowData[index + 2] = temp;
        });
      }

      this.dataSource.push(rowData);
    });
  }

  updateSchemeRatingTable(data: any) {
    //get columns from data
    this.displayedRatingColumns = ['Issuer', 'Rating', 'Grand Total'];
    data['col'].forEach((element: any, index: number) => {
      this.displayedRatingColumns.push(element);
    });

    //Getting the Rows
    this.displayedRatingRows = [];
    Object.keys(data['result']).forEach((element: any, index: number) => {
      this.displayedRatingRows.push(element);
    });
    this.displayedRatingRows.push('Total');

    //Creating the datasources
    this.ratingDataSource = [];
    let rowData: any = {};
    this.displayedRatingRows.forEach((element: any, index: number) => {
      rowData = {};
      //Special case for totals row
      if (index == this.displayedRatingRows.length - 1) {
        rowData[0] = element;
        rowData[1] = '';
        rowData[2] = (data['total_aum'] / 100).toFixed(2);
        data['col'].forEach((colValue: any, index: number) => {
          rowData[index + 3] = (data['total'][colValue]['total'] / 100).toFixed(
            2
          );
        });
      } else {
        //For normal rows
        rowData[0] = element;
        rowData[1] = this.selectedRating;
        rowData[2] = (data['result'][element]['mvalue'] / 100).toFixed(2);

        data['col'].forEach((colValue: any, index: number) => {
          let temp = '';
          if (data['result'][element][colValue] != null) {
            temp = (data['result'][element][colValue] / 100).toFixed(2);
          }
          rowData[index + 3] = temp;
        });
      }

      this.ratingDataSource.push(rowData);
    });
  }

  updateMfExposureSummaryTable(data: any) {
    //Get Columns from data
    this.displayedColumns = ['Category'];
    data['mfctype'].forEach((element: any) => {
      this.displayedColumns.push(element['name']);
    });
    this.displayedColumns.push('Total');
    //Getting the Rows
    this.displayedRows = ['UTCL Investments(Cr)'];
    data['rating_row'].forEach((element: any) => {
      this.displayedRows.push(element);
    });
    this.displayedRows.push('Total');

    //Creating the data sources
    this.dataSource = [];
    let rowData: any = {};

    this.displayedRows.forEach((rowCategory: any, index: number) => {
      rowData = {};

      //Creating a special case for the first row
      let temp = '';
      if (index == 0) {
        rowData[0] = rowCategory;
        data['mfctype'].forEach((colValue: any, index: number) => {
          if (data['invmts'][index]['amount'] == null) {
            temp = '-';
          } else {
            temp = data['invmts'][index]['amount'].toFixed(2).toString();
          }

          rowData[index + 1] = temp;
        });
        rowData[this.displayedColumns.length - 1] = data['invmts_total']
          .toFixed(2)
          .toString();
      } else if (index == this.displayedRows.length - 1) {
        rowData[0] = rowCategory;
        data['mfctype'].forEach((element: any, index: number) => {
          if (data['total'][element['code']]['total'] != null) {
            temp = data['total'][element['code']]['total'].toFixed(2) + ' %';
          } else {
            temp = '-';
          }
          rowData[index + 1] = temp;
        });
        rowData[this.displayedColumns.length - 1] = '100 %';
      } else {
        //Populating rows for individual Ratings
        this.displayedColumns.forEach((colValue: any, index: number) => {
          rowData[0] = rowCategory;
          data['mfctype'].forEach((element: any, index: number) => {
            if (data['result'][rowCategory][element['code']] != null) {
              temp =
                data['result'][rowCategory][element['code']].toFixed(2) + ' %';
            } else {
              temp = '-';
            }
            rowData[index + 1] = temp;
          });
        });
        rowData[this.displayedColumns.length - 1] =
          data['result'][rowCategory]['mvaluep'].toFixed(2) + ' %';
      }

      this.dataSource.push(rowData);
    });
    this.updateComparisonTable(data);
  }

  updateComparisonTable(data: any) {
    //Getting the columns from data
    this.displayedComparisonColumns = ['Category'];
    data['mfctype'].forEach((element: any) => {
      this.displayedComparisonColumns.push(element['name']);
    });

    //Getting the rows from the data
    this.displayedComparisonRows = [
      `O/s as on ${data['record_date']}`,
      `O/s as on ${data['prev_date']}`,
    ];

    //Populating the datasource
    this.comparisonDataSource = [];
    let rowData: any = {};
    this.displayedComparisonRows.forEach(
      (rowCategory: any, rowIndex: number) => {
        rowData = {};
        rowData[0] = rowCategory;
        //for first row
        if (rowIndex == 0) {
          data['mfctype'].forEach((mfType: any, mfTypeIndex: number) => {
            let temp: any = '';
            if (data['catg_wise2'][mfTypeIndex] != 0) {
              temp = data['catg_wise2'][mfTypeIndex]['y'].toFixed(2);
            } else {
              temp = '-';
            }

            rowData[mfTypeIndex + 1] = temp;
          });
          this.comparisonDataSource.push(rowData);
        } else {
          //for second row
          data['mfctype'].forEach((mfType: any, mfTypeIndex: number) => {
            let temp: any = '';
            if (data['catg_wise1'][mfTypeIndex] != 0) {
              temp = data['catg_wise1'][mfTypeIndex]['y'].toFixed(2);
            } else {
              temp = '-';
            }
            rowData[mfTypeIndex + 1] = temp;
          });
          this.comparisonDataSource.push(rowData);
        }
      }
    );
  }

  //Initializing Graphs and updating them
  createGraphs() {
    this.schemeExposureChart = new Chart(this.schemeChartRef.nativeElement, {
      type: 'doughnut', //this denotes tha type of chart
      data: {
        // values on X-Axis
        labels: ['Red', 'Pink', 'Green', 'Yellow', 'Orange', 'Blue'],
        datasets: [
          {
            data: [300, 240, 100, 432, 253, 34],
            hoverOffset: 4,
          },
        ],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            position: 'bottom',
          },
        },
      },
    });

    this.ratingExposureChart = new Chart(this.ratingChartRef.nativeElement, {
      type: 'doughnut', //this denotes tha type of chart
      data: {
        // values on X-Axis
        labels: ['Red', 'Pink', 'Green', 'Yellow', 'Orange', 'Blue'],
        datasets: [
          {
            data: [300, 240, 100, 432, 253, 34],
            hoverOffset: 4,
          },
        ],
      },

      options: {
        responsive: true,
        maintainAspectRatio: false,

        plugins: {
          legend: {
            position: 'bottom',
          },
        },
      },
    });

    this.categoryWiseChart = new Chart(
      this.categoryWiseChartRef.nativeElement,
      {
        type: 'bar',
        data: {
          labels: ['Arbitrage', 'Vanilla', 'Strawberry'],
          datasets: [
            {
              label: 'category 1',
              data: [433, 300, 50],
            },
            {
              label: 'category 2',
              data: [435, 320, 100],
            },
          ],
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            x: {
              grid: {
                display: false,
              },
            },
            y: {
              grid: {
                display: false,
              },
            },
          },
          plugins: {
            legend: {
              position: 'bottom',
            },
          },
        },
      }
    );
    this.cdr.detectChanges();
  }

  updateSchemeGraph(dataset: any) {
    const data: any = [];
    const labels: any = [];
    dataset.forEach((element: any) => {
      data.push(element['y']);
      labels.push(element['name']);
    });

    this.schemeExposureChart.data.datasets = [
      {
        data: data,
        hoverOffset: 4,
        backgroundColor: ['#a6cee3', '#1f78b4', '#b2df8a'],
      },
    ];
    this.schemeExposureChart.data.labels = labels;
    this.schemeExposureChart.update();
  }

  updateRatingGraph(dataset: any) {
    const data: any = [];
    const labels: any = [];
    dataset.forEach((element: any) => {
      data.push(element['y']);
      labels.push(element['name']);
    });

    this.ratingExposureChart.data.datasets = [
      {
        data: data,
        hoverOffset: 4,
        backgroundColor: [
          '#eff3ff',
          '#c6dbef',
          '#9ecae1',
          '#6baed6',
          '#4292c6',
          '#2171b5',
          '#084594',
        ],
      },
    ];
    this.ratingExposureChart.data.labels = labels;
    this.ratingExposureChart.update();
  }

  updateComparisonGraph(datasets: any) {
    const labels: string[] = [];
    const previousData: number[] = [];
    const currentData: number[] = [];
    //Populating the datasets
    datasets['dataset1'].forEach((element: any, index: number) => {
      if (datasets['dataset1'][index]['y'] != 0) {
        labels.push(element['name']);
        previousData.push(datasets['dataset1'][index]['y']);
        currentData.push(datasets['dataset2'][index]['y']);
      }
    });

    this.categoryWiseChart.data.datasets = [
      {
        label: datasets['previousDate'],
        data: previousData,
        backgroundColor: '#1AA7EC',
      },
      {
        label: datasets['currentDate'],
        data: currentData,
        backgroundColor: '#1E2F97',
      },
    ];
    this.categoryWiseChart.data.labels = labels;
    this.categoryWiseChart.update();
  }

  //Main GET Functions
  getMfExposure() {
    this.schemeExposureChart.destroy();
    this.ratingExposureChart.destroy();
    this.categoryWiseChart.destroy();
    this.createGraphs();
    this.isComparisonTableLoaded = false;
    this.isGraphsLoaded = false;
    this.isMftableLoaded = false;
    this.isRatingTableLoaded = false;
    this.isSummaryGraphLoaded = false;
    //adding summary condition
    if (this.selectedMfCategory == 'summary') {
      this.getMfExposureSummary();
      return;
    }
    this.dbService
      .mf(
        {
          month: this.selectedExposureMonth,
          month1: this.selectedPortfolioMonth,
          type: this.selectedMfCategory,
        },

        'getMfExposure'
      )
      .pipe(first())
      .subscribe({
        next: (data: any) => {
          this.noDataFound = false;
          this.updateMfExposureTable(data);
          this.updateRatingGraph(data['schemerat_wise']);
          this.updateSchemeGraph(data['scheme_wise']);
          this.isMftableLoaded = true;
          this.isGraphsLoaded = true;
          this.isComparisonTableLoaded = false;
          this.isSummaryGraphLoaded = false;
        },
        error: (err: any) => {
          this.noDataFound = true;
          console.log(err);
        },
      });
  }

  getMfExposureSummary() {
    this.dbService
      .mf(
        {
          month: this.selectedExposureMonth,
          month1: this.selectedPortfolioMonth,
          type: this.selectedMfCategory,
        },

        'getMfExposureSummary'
      )
      .pipe(first())
      .subscribe({
        next: (data: any) => {
          this.noDataFound = false;
          this.updateMfExposureSummaryTable(data);
          this.updateRatingGraph(data['rating_wise']);
          this.updateSchemeGraph(data['catg_wise2']);
          this.updateComparisonGraph({
            dataset1: data['catg_wise1'],
            dataset2: data['catg_wise2'],
            currentDate: data['record_date'],
            previousDate: data['prev_date'],
          });
          this.isMftableLoaded = true;
          this.isGraphsLoaded = true;
          this.isSummaryGraphLoaded = true;
          this.isComparisonTableLoaded = true;
        },
        error: (err: any) => {
          this.noDataFound = true;
          console.log(err);
        },
      });
  }

  getSchemeRating(ratingCategory: any) {
    this.selectedRating = ratingCategory;
    this.dbService
      .mf(
        {
          month: this.selectedExposureMonth,
          month1: this.selectedPortfolioMonth,
          type: this.selectedMfCategory,
          rating: ratingCategory,
        },

        'getRatingExposure'
      )
      .pipe(first())
      .subscribe({
        next: (data: any) => {
          this.updateSchemeRatingTable(data);
        },
        error: (err: any) => {
          console.log(err);
        },
      });
  }

  ngOnInit(): void {
    this.getExposureMonths();
    this.getMfCategories();
  }
  ngAfterViewInit(): void {
    this.createGraphs();
  }
}
