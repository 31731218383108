import { Router } from '@angular/router';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class RedirectUnauthorizedToLogin {
  constructor(private router: Router) {}
  canActivate(): boolean {
    if (localStorage.getItem('token') != null) {
      return true;
    }

    // navigate to login page as user is not authenticated
    localStorage.setItem('lastnavigated', this.router.url);
    this.router.navigate(['/login']);
    return false;
  }
}
