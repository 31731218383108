import { Component, OnInit } from '@angular/core';
import { first } from 'rxjs';
import { DbService } from 'src/app/core/services/db.service';

@Component({
  selector: 'app-banklist',
  templateUrl: './banklist.component.html',
  styleUrls: ['./banklist.component.scss'],
})
export class BanklistComponent implements OnInit {
  constructor(private dbservice: DbService) {}

  ngOnInit(): void {
    this.getBankList();
  }
  bankList: any[] = [];
  displayedColumns = ['srno', 'bankcode', 'bankname'];

  getBankList() {
    this.dbservice
      .ccstmt([], 'getBankList')
      .pipe(first())
      .subscribe({
        next: (data: any) => {
          this.bankList = data['res'];
          // console.log(this.mclrBankList);
        },
        error: (err: any) => {
          console.log(err);
        },
      });
  }
}
