<!doctype html>
<html lang="en">
  <head>
    <meta charset="UTF-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
    <link rel="preconnect" href="https://fonts.googleapis.com" />
    <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
    <link
      href="https://fonts.googleapis.com/css2?family=Poppins&display=swap"
      rel="stylesheet" />
  </head>
  <body class="">
    <div class="absolute w-full" #Header>
      <div
        class="flex flex-row justify-between items-center w-full h-12 bg-transparent py-8 px-5">
        <div class="flex flex-row items-center">
          <img class="h-12" src="assets/images/logos/FSlogo.png" alt="" />
          <span class="font-lato font-bold text-lg ml-4">FS NxT Prod</span>
        </div>
        <button
          routerLink="/login"
          type="button"
          class="text-white bg-black hover:bg-gray-400 focus:ring-4 focus:ring-blue-300 font-medium rounded-md px-10 py-2.5 mr-2 mat-elevation-z3">
          Login
        </button>
      </div>
    </div>

    <div #heroBG class="h-full flex flex-row items-center">
      <div #left class="w-1/2 px-24">
        <div
          id="tagline"
          class="text-5xl wow animate__animated animate__fadeInDown font-semibold mb-4 font-lato leading-normal">
          Leverage your Enterprise Data to
          <span class="text-red-700">Analyze</span> Information
        </div>
        <span
          class="text-xl text-gray-600 mt-4 wow animate__animated animate__fadeInDown"
          >Bring Data from ERP's and Generate Analytics</span
        >
        <div class="flex flex-col">
          <a
            tabindex="0"
            (click)="scrollToBorrowings()"
            class="text-green-700 hover:text-green-900 inline-flex items-center mt-4 cursor-pointer font-semibold"
            >Borrowing Analytics
            <svg
              class="w-4 h-4 ml-2"
              viewBox="0 0 24 24"
              stroke="currentColor"
              stroke-width="2"
              fill="none"
              stroke-linecap="round"
              stroke-linejoin="round">
              <path d="M5 12h14"></path>
              <path d="M12 5l7 7-7 7"></path>
            </svg>
          </a>
          <a
            tabindex="0"
            (click)="scrollToMutualFunds()"
            class="text-yellow-700 hover:text-yellow-900 inline-flex items-center mt-4 cursor-pointer font-semibold"
            >Mutual Fund Analytics
            <svg
              class="w-4 h-4 ml-2"
              viewBox="0 0 24 24"
              stroke="currentColor"
              stroke-width="2"
              fill="none"
              stroke-linecap="round"
              stroke-linejoin="round">
              <path d="M5 12h14"></path>
              <path d="M12 5l7 7-7 7"></path>
            </svg>
          </a>
        </div>
        <br />
      </div>
      <div #right class="w-[40%] h-full flex items-center">
        <img class="" src="assets/images/graphics/v1.png" alt="" />
      </div>
    </div>

    <div #Statistics>
      <div
        class="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-2xl md:px-24 lg:px-8 lg:py-20 bg-gray-100">
        <div class="grid grid-cols-2 row-gap-8 md:grid-cols-4">
          <div class="text-center md:border-r">
            <h6
              class="text-4xl font-bold lg:text-5xl xl:text-6xl text-red-600 animate__animated animate__fadeInUp">
              39
            </h6>
            <p
              class="text-sm font-medium tracking-widest text-gray-800 uppercase lg:text-base wow animate__animated animate__fadeInUp">
              Fund Houses
            </p>
          </div>
          <div class="text-center md:border-r">
            <h6
              class="text-4xl font-bold lg:text-5xl xl:text-6xl text-yellow-500 wow animate__animated animate__fadeInUp">
              12
            </h6>
            <p
              class="text-sm font-medium tracking-widest text-gray-800 uppercase lg:text-base wow animate__animated animate__fadeInUp">
              Clients
            </p>
          </div>
          <div class="text-center md:border-r">
            <h6
              class="text-4xl font-bold lg:text-5xl xl:text-6xl text-green-500 wow animate__animated animate__fadeInUp">
              2,242
            </h6>
            <p
              class="text-sm font-medium tracking-widest text-gray-800 uppercase lg:text-base wow animate__animated animate__fadeInUp">
              Schemes
            </p>
          </div>
          <div class="text-center">
            <h6
              class="text-4xl font-bold lg:text-5xl xl:text-6xl text-gray-500 wow animate__animated animate__fadeInUp">
              1000+
            </h6>
            <p
              class="text-sm font-medium tracking-widest text-gray-800 uppercase lg:text-base wow animate__animated animate__fadeInUp">
              Documents Generated
            </p>
          </div>
        </div>
      </div>
    </div>

    <div #Features id="borrowingsTag">
      <!-- CP Documentation -->
      <div
        class="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
        <div class="flex flex-row items-center justify-between w-full">
          <div class="">
            <img
              class="rounded-t-[0.625rem] rounded-b-[0.625rem] w-[80rem] h-2/3 mat-elevation-z5 wow animate__animated animate__slideInLeft"
              src="assets/images/website-previews/5.png"
              alt="" />
          </div>
          <div class="ml-16">
            <a
              href="/"
              aria-label="Go Home"
              title="Logo"
              class="inline-block mb-5">
            </a>
            <h5
              style="font-family: 'Poppins', sans-serif"
              class="mb-4 text-4xl font-extrabold leading-none text-[#800000] wow animate__animated animate__slideInRight">
              CP Documentation<br class="hidden md:block" />
            </h5>
            <p
              style="font-family: 'Poppins', sans-serif"
              class="mb-6 text-gray-900 wow animate__animated animate__slideInRight">
              Revolutionize your business with our seamless integration.
              Generate Commercial Paper Documentation effortlessly, leveraging
              real-time ERP data. Streamline for swift, precise, and
              regulatory-compliant documentation. Elevate efficiency today!
              <br />
            </p>
          </div>
        </div>
      </div>
      <hr />

      <!-- Statement Converter -->
      <div
        class="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
        <div class="grid gap-10 lg:grid-cols-2">
          <div class="lg:pr-10">
            <a
              href="/"
              aria-label="Go Home"
              title="Logo"
              class="inline-block mb-5">
            </a>
            <h5
              style="font-family: 'Poppins', sans-serif"
              class="mb-4 text-4xl font-extrabold leading-none text-yellow-400 wow animate__animated animate__slideInLeft">
              Statement Converter<br class="hidden md:block" />
            </h5>
            <p
              style="font-family: 'Poppins', sans-serif"
              class="mb-7 text-gray-900 wow animate__animated animate__slideInLeft">
              Effortlessly transform bank statements into ERP-compatible
              formats. Automate transaction management updates, eliminating
              manual intervention. Boost efficiency with seamless integration
              for streamlined financial processes.
              <br />
            </p>
          </div>
          <div class="w-full h-full">
            <img
              class="rounded-t-[0.625rem] rounded-b-[0.625rem] mat-elevation-z5 inline-block wow animate__animated animate__slideInRight"
              src="assets/images/website-previews/2.png"
              alt="" />
          </div>
        </div>
      </div>
      <hr />
      <!-- MF Exposure -->
      <div
        class="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20"
        id="mfTag">
        <div class="flex flex-row items-center justify-between w-full">
          <div class="mr-16">
            <img
              class="rounded-t-[0.625rem] rounded-b-[0.625rem] mat-elevation-z5 wow animate__animated animate__slideInLeft"
              src="assets/images/website-previews/mfe.png"
              alt="" />
          </div>
          <div class="lg:pr-10">
            <a
              href="/"
              aria-label="Go Home"
              title="Logo"
              class="inline-block mb-5">
            </a>
            <h5
              style="font-family: 'Poppins', sans-serif"
              class="mb-4 text-4xl font-extrabold leading-none text-green-400 wow animate__animated animate__slideInRight">
              MF Exposure<br class="hidden md:block" />
            </h5>
            <p
              style="font-family: 'Poppins', sans-serif"
              class="mb-6 text-gray-900 wow animate__animated animate__slideInRight">
              Streamline Mutual Fund tracking. Capture fund details, categorize
              by asset class, calculate allocations, and assess risk exposure.
              Seamlessly integrate with SAP via tailored IT solutions for
              synchronized financial management.

              <br />
            </p>
          </div>
        </div>
      </div>
    </div>

    <hr />
    <!-- Clients -->
    <section class="bg-white mt-10 mb-20">
      <div class="container mx-auto">
        <div class="-mx-4 flex flex-wrap">
          <div class="w-full px-4">
            <h1
              class="sm:text-3xl text-2xl font-medium title-font mb-2 text-gray-900 text-center">
              Trusted By
            </h1>
            <div class="flex flex-wrap items-center justify-center">
              <a
                href="javascript:void(0)"
                class="mx-4 flex w-[150px] items-center justify-center py-5 2xl:w-[180px]">
                <img
                  src="assets/images/tatacapi.png"
                  alt="image"
                  class="h-13 w-full" />
              </a>
              <a
                href="javascript:void(0)"
                class="mx-4 flex w-[150px] items-center justify-center py-5 2xl:w-[180px]">
                <img
                  src="assets/images/piramal.png"
                  alt="image"
                  class="h-13 w-full" />
              </a>
              <a
                href="javascript:void(0)"
                class="mx-4 flex w-[150px] items-center justify-center py-5 2xl:w-[180px]">
                <img
                  src="assets/images/tata1.jpg"
                  alt="image"
                  class="h-23 w-full" />
              </a>
              <a
                href="javascript:void(0)"
                class="mx-4 flex w-[150px] items-center justify-center py-5 2xl:w-[180px]">
                <img
                  src="assets/images/toyo.png"
                  alt="image"
                  class="h-30 w-full" />
              </a>
              <a
                href="javascript:void(0)"
                class="mx-4 flex w-[150px] items-center justify-center py-5 2xl:w-[180px]">
                <img
                  src="assets/images/lntf.jpg"
                  alt="image"
                  class="h-30 w-full" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>

    <div>
      <div
        class="col-span-1: grid-column: span 1 / span 3; bg-gray-100 shadow-2xl">
        <div
          class="px-4 pt-8 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8">
          <div class="grid gap-10 row-gap-6 mb-8 sm:grid-cols-2 lg:grid-cols-4">
            <div class="sm:col-span-2">
              <!-- contact us here -->
              <div
                id="contactus"
                class="flex flex-col items-start h-[15rem w-[25rem]">
                <span
                  class="font-semibold mb-4"
                  style="font-family: 'Poppins', sans-serif"
                  >Get in touch with us by filling a simple form :</span
                >
                <div id="nameContainers" class="flex flex-row w-full mb-2">
                  <div #Fname class="flex-1 px-1">
                    <label
                      for="fname"
                      class="block text-sm"
                      style="font-family: 'Poppins', sans-serif"
                      >First Name</label
                    >
                    <input
                      type="text"
                      id="fname"
                      class="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg block p-2.5 w-full h-[2.5rem]" />
                  </div>
                  <div #Lname class="flex-1 px-1">
                    <label
                      for="fname"
                      class="block text-sm"
                      style="font-family: 'Poppins', sans-serif"
                      >Last Name</label
                    >
                    <input
                      type="text"
                      id="lname"
                      class="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg block p-2.5 w-full h-[2.5rem]" />
                  </div>
                </div>

                <div class="flex-1 w-full">
                  <label
                    for="compname"
                    class="block text-sm"
                    style="font-family: 'Poppins', sans-serif"
                    >Company Name</label
                  >
                  <input
                    type="text"
                    id="compname"
                    class="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg block p-2.5 w-full h-[2.5rem]" />
                </div>
                <div class="flex-1 w-full mt-2">
                  <label
                    for="emailadd"
                    class="block text-sm"
                    style="font-family: 'Poppins', sans-serif"
                    >Email Address</label
                  >
                  <input
                    type="text"
                    id="emailadd"
                    class="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg block p-2.5 w-full h-[2.5rem]" />
                </div>
                <button
                  class="px-10 py-2 bg-blue-700 text-white rounded-md mt-3 font-lato">
                  Submit
                </button>
              </div>
            </div>

            <div class="space-y-2 text-sm">
              <p class="text-base font-bold tracking-wide text-gray-900">
                India
              </p>
              <div class="flex">
                <a
                  style="font-family: 'Poppins', sans-serif"
                  href="tel:+91-22-4005 0723"
                  aria-label="Our phone"
                  title="Our phone"
                  class="transition-colors duration-300 text-deep-purple-accent-400 hover:text-deep-purple-800"
                  >+91-22-4005 0723</a
                >
              </div>
              <div class="flex">
                <a
                  style="font-family: 'Poppins', sans-serif"
                  href="mailto:contact@fourthsignal.com"
                  aria-label="Our email"
                  title="Our email"
                  class="transition-colors duration-300 text-deep-purple-accent-400 hover:text-deep-purple-800"
                  >contact@fourthsignal.com</a
                >
              </div>
              <div class="flex">
                <a
                  style="font-family: 'Poppins', sans-serif"
                  href="https://www.google.com/maps/place/Fourth+Signal/@19.1137359,72.8586194,17z/data=!3m2!4b1!5s0x3be7b7c0b81724a1:0x5b901b769395ecf5!4m6!3m5!1s0x3be7c913de7090fd:0x3947594d1b91c328!8m2!3d19.1137308!4d72.8611943!16s%2Fg%2F11h198t1t?entry=ttu"
                  target="_blank"
                  rel="noopener noreferrer"
                  aria-label="Our address"
                  title="Our address"
                  class="transition-colors duration-300 text-deep-purple-accent-400 hover:text-deep-purple-800">
                  Fourth Signal (India) Pvt Ltd, 502,C Wing, Business
                  Square,Solitaire Corporate Park, Andheri Kurla Road,
                  Andheri(E) ,Mumbai 400093, India
                </a>
              </div>
            </div>

            <div class="space-y-2 text-sm">
              <p
                style="font-family: 'Poppins', sans-serif"
                class="text-base font-bold tracking-wide text-gray-900">
                APAC
              </p>
              <div class="flex">
                <a
                  style="font-family: 'Poppins', sans-serif"
                  href="tel:+65 6818 6372"
                  aria-label="Our phone"
                  title="Our phone"
                  class="transition-colors duration-300 text-deep-purple-accent-400 hover:text-deep-purple-800"
                  >+65 6818 6372</a
                >
              </div>
              <div class="flex">
                <a
                  style="font-family: 'Poppins', sans-serif"
                  href="mailto:mani@fourthsignal.com"
                  aria-label="Our email"
                  title="Our email"
                  class="transition-colors duration-300 text-deep-purple-accent-400 hover:text-deep-purple-800"
                  >mani@fourthsignal.com</a
                >
              </div>
              <div class="flex">
                <a
                  style="font-family: 'Poppins', sans-serif"
                  href="https://www.google.com/maps/place/Fourth+Signal/@19.1137359,72.8586194,17z/data=!3m2!4b1!5s0x3be7b7c0b81724a1:0x5b901b769395ecf5!4m6!3m5!1s0x3be7c913de7090fd:0x3947594d1b91c328!8m2!3d19.1137308!4d72.8611943!16s%2Fg%2F11h198t1t?entry=ttu"
                  target="_blank"
                  rel="noopener noreferrer"
                  aria-label="Our address"
                  title="Our address"
                  class="transition-colors duration-300 text-deep-purple-accent-400 hover:text-deep-purple-800">
                  Fourth Signal Pte Ltd Level 39, Marina Bay Financial Centre
                  Tower 2 10 Marina Boulevard, Singapore 018 983
                </a>
              </div>
            </div>
          </div>
          <div
            class="flex flex-col-reverse justify-between pt-5 pb-4 lg:flex-row">
            <p
              style="font-family: 'Poppins', sans-serif"
              class="text-sm text-gray-600">
              © Copyright 2023 by Fourth Signal. All rights reserved.
            </p>
            <ul
              class="flex flex-col mb-3 space-y-2 lg:mb-0 sm:space-y-0 sm:space-x-5 sm:flex-row">
              <li>
                <a
                  href="/"
                  class="text-sm text-gray-600 transition-colors duration-300 hover:text-deep-purple-accent-400"
                  >Privacy Policy</a
                >
              </li>
              <li>
                <a
                  href="/"
                  class="text-sm text-gray-600 transition-colors duration-300 hover:text-deep-purple-accent-400"
                  >Terms &amp; Conditions</a
                >
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </body>
</html>
