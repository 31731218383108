import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { first } from 'rxjs';
import { ApiUrls } from 'src/apis';
import { AuthService } from 'src/app/core/services/auth.service';
import { DbService } from 'src/app/core/services/db.service';

@Component({
  selector: 'app-companygroup',
  templateUrl: './companygroup.component.html',
  styleUrls: ['./companygroup.component.scss'],
})
export class CompanygroupComponent implements OnInit {
  showForm = false;
  formAction: any;
  constructor(
    private dbservice: DbService,
    private auth: AuthService
  ) {}

  cgrpList: any[] = [];
  cgrpCode: string = '';
  showListing = true;

  ngOnInit(): void {
    this.getCgrpList();
  }

  compcgrp = new FormGroup({
    cgrpid: new FormControl(''),
    cgrpname: new FormControl(''),
    cgrplogo: new FormControl(''),
    cgrpurl: new FormControl(''),
  });

  getCgrpList() {
    var cgrp: string = localStorage.getItem('CGRP')!;
    this.dbservice
      .postRequest(ApiUrls.admin, [{ cgrpCode: cgrp }], 'getCgrpList')
      .pipe(first())
      .subscribe({
        next: (data: any) => {
          this.cgrpList = data['res'];
          this.showListing = true;
        },
        error: (err: any) => {
          console.log(err);
        },
      });
  }

  viewRec(action: string, params: any) {
    this.showForm = true;
    this.showListing = false;
    this.formAction = action;
    this.compcgrp.patchValue({
      cgrpid: params.cgrp_id,
      cgrpname: params.cgrp_name,
      cgrplogo: params.cgrp_logo,
      cgrpurl: params.cgrp_domain,
    });
  }

  HideForm() {
    this.showForm = false;
    this.showListing = true;
    this.getCgrpList();
  }

  displayedColumns: string[] = [
    'srno',
    'cgrpid',
    'cgrpname',
    'status',
    'cid',
    'cdt',
  ];
}
