import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { first } from 'rxjs';
import { ApiUrls } from 'src/apis';
import { DbService } from 'src/app/core/services/db.service';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss'],
})
export class UsersComponent implements OnInit {
  showForm = false;
  formAction: any;
  constructor(
    private dbservice: DbService,
    private http: HttpClient
  ) {}

  usersList: any[] = [];
  showListing = true;

  ngOnInit(): void {
    this.getUsersList();
  }

  usersform = new FormGroup({
    usercode: new FormControl(''),
    username: new FormControl(''),
    useremail: new FormControl(''),
    usermobile: new FormControl(''),
    lmenucode: new FormControl(''),
  });

  getUsersList() {
    this.dbservice
      .postRequest(ApiUrls.admin, [], 'getUsersList')
      .pipe(first())
      .subscribe({
        next: (data: any) => {
          this.usersList = data['res'];
          this.showListing = true;
          // console.log(this.mclrBankList);
        },
        error: (err: any) => {
          console.log(err);
        },
      });
  }

  viewRec(action: string, params: any) {
    this.showForm = true;
    this.showListing = false;
    this.formAction = action;
    this.usersform.patchValue({
      usercode: params.user_code,
      username: params.user_name,
      useremail: params.user_email,
      usermobile: params.user_mobile,
      lmenucode: params.landing_menucode,
    });
  }

  HideForm() {
    this.showForm = false;
    this.showListing = true;
    this.getUsersList();
  }

  displayedColumns: string[] = [
    'srno',
    'usercode',
    'username',
    'useremail',
    'usermobile',
    'status',
    'cid',
    'cdt',
  ];
}
