import { AfterViewInit, Component, ElementRef, ViewChild } from '@angular/core';
import { Chart } from 'chart.js';
import { first } from 'rxjs';
import { DbService } from 'src/app/core/services/db.service';

@Component({
  selector: 'app-fra-dashboard',
  templateUrl: './fra-dashboard.component.html',
  styleUrls: ['./fra-dashboard.component.scss'],
})
export class FraDashboardComponent implements AfterViewInit {
  constructor(private dbservice: DbService) {}
  //Input Vars
  dayOption = '';
  tradeDate = '';
  settlementDate = '';
  valuationDate = '';
  notionalValue = '';
  dealId = '';
  forwardRate = '';
  fundingSpread = '';

  //Output Vars
  mtm1: any = '';
  mtm2: any = '';
  linePlots: any = {};
  isMtmLoaded = false;

  //Chart Vars
  public regressionChart: any;
  regressionChartData: any = [];
  @ViewChild('RegressionChartCanvas') regressionChartRef!: ElementRef;

  //Regression Vars
  hiCoef: any = '';
  hiPValue: any = '';
  hiRSquared: any = '';

  ngAfterViewInit(): void {
    this.createCharts();
  }

  fillDummy() {
    this.dayOption = '1';
    this.tradeDate = '2023-01-10';
    this.settlementDate = '2029-11-27';
    this.valuationDate = '2023-01-10';
    this.notionalValue = '81361506';
    this.dealId = '1';
    this.forwardRate = '7.374';
    this.fundingSpread = '75';
  }

  calculateFRA() {
    this.isMtmLoaded = false;
    this.dbservice
      .fra(
        [
          {
            dayOption: this.dayOption,
            tradeDate: this.tradeDate,
            settlementDate: this.settlementDate,
            valuationDate: this.valuationDate,
            notionalValue: this.notionalValue,
            dealId: this.dealId,
            forwardRate: this.forwardRate,
            fundingSpread: this.fundingSpread,
          },
        ],
        'calculateFRA'
      )
      .pipe(first())
      .subscribe({
        next: (data: any) => {
          this.mtm1 = data['mtm1'];
          this.mtm2 = data['mtm2'];
          this.linePlots = data['linePlot'];
          this.hiCoef = data['regression']['Slope'];
          this.hiPValue = data['regression']['P-value'];
          this.hiRSquared = data['regression']['R-squared'];
          //generating graph datasource
          const temp: any = [];
          data['linePlot']['x'].forEach((element: any, index: number) => {
            temp.push({
              x: data['linePlot']['x'][index],
              y: data['linePlot']['y'][index],
            });
          });

          this.regressionChart.data.datasets = [
            {
              label: 'Data Point',
              data: temp,
              backgroundColor: [
                '#eff3ff',
                '#c6dbef',
                '#9ecae1',
                '#6baed6',
                '#4292c6',
                '#2171b5',
                '#084594',
              ],
            },
          ];
          this.regressionChart.update();

          if (data[0] != 'N') {
            this.isMtmLoaded = true;
          }
        },
        error: (err: any) => {
          console.log(err);
        },
      });
  }

  downloadDump() {
    const url = 'http://159.89.175.114/fsnxtbackend/features/fra/Dumps/';
    //"FRA_{deal_id}_{valuation_date}.xlsx"
    const fileName = `FRA_${this.dealId}_${this.valuationDate}.xlsx`;
    window.open(url + fileName, '_blank');
  }

  createCharts() {
    this.regressionChart = new Chart(this.regressionChartRef.nativeElement, {
      type: 'scatter',
      data: {
        datasets: [
          {
            label: 'Scatter Dataset',
            data: [
              {
                x: -10,
                y: 0,
              },
              {
                x: 0,
                y: 10,
              },
              {
                x: 10,
                y: 5,
              },
              {
                x: 0.5,
                y: 5.5,
              },
            ],
            backgroundColor: 'rgb(255, 99, 132)',
          },
        ],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            display: false,
            position: 'bottom',
          },
        },
        scales: {
          x: {
            type: 'linear',
            position: 'center',
            grid: {
              display: false,
            },
            ticks: {
              autoSkip: true,
              maxTicksLimit: 6,
            },
          },
          y: {
            type: 'linear',
            position: 'center',
            grid: {
              display: false,
            },
            ticks: {
              autoSkip: true,
              maxTicksLimit: 6,
            },
          },
        },
      },
    });
  }
}
