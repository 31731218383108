import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class FileService {
  baseUrl = 'http://localhost/fsnxtbackend';

  constructor(private http: HttpClient) {
    this.baseUrl = this.setBaseURL();
  }
  public setBaseURL() {
    if (window.location.hostname == 'localhost') {
      return 'http://localhost/fsnxtbackend';
    } else if (window.location.hostname == 'development.fsnxt.com') {
      return 'http://development.fsnxt.com/fsnxtbackend';
    } else {
      return 'http://159.89.175.114/fsnxtbackend';
    }
  }

  public upload(file: File, params: any) {
    const formData = new FormData();
    formData.append('file', file, file.name);
    return this.http
      .post<any>(`${this.baseUrl}/api/file.api.php`, {
        params: params,
      })
      .pipe(
        map(Data => {
          return Data;
        })
      );
  }

  public uploadFRA(bondD: File, couponP: File, oisD: File, spotY: File) {
    console.log(bondD);

    const fraData = new FormData();
    fraData.append('bondDetails', bondD, bondD.name);
    fraData.append('couponPayments', couponP, couponP.name);
    fraData.append('oisDump', oisD, oisD.name);
    fraData.append('spotYield', spotY, spotY.name);
    return this.http
      .post<any>(
        `${this.baseUrl}/api/file.api.php`,
        {
          params: fraData,
          method: 'fraUpload',
        },
        { reportProgress: true, observe: 'events' }
      )
      .pipe(
        map(Data => {
          return Data;
        })
      );
  }
}
