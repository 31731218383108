<div id="header" class="mt-10 flex flex-col">
  <span class="font-lato text-2xl">Master Data</span>
  <span class="font-lato text-md py-2 text-gray-500"
    >Your company master data. Click on the Company Name for more
    information</span
  >
</div>

<div
  id="masterData"
  class="bg-white mt-4 pb-1 rounded-lg mat-elevation-z2"
  *ngIf="showListing">
  <div class="py-3 px-3">
    <span id="name" class="font-lato">Company List</span>
  </div>
  <hr />
  <table mat-table [dataSource]="compList" class="w-full">
    <ng-container matColumnDef="srno">
      <th mat-header-cell *matHeaderCellDef>#</th>
      <td mat-cell *matCellDef="let element; let i = index">
        {{ i + 1 }}
      </td>
    </ng-container>

    <ng-container matColumnDef="compname">
      <th mat-header-cell *matHeaderCellDef>Company Name</th>
      <td
        mat-cell
        *matCellDef="let element"
        (click)="showCompanyDetails(element)"
        class="text-blue-500 cursor-pointer">
        {{ element.comp_name }}
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
</div>

<div *ngIf="showlist" class="pb-4">
  <div
    id="companyDetails"
    class="bg-white mt-4 pb-1 rounded-lg mat-elevation-z2 py-1"
    *ngIf="selectedCompany">
    <table mat-table [dataSource]="compMasterList" class="w-full py-2">
      <ng-container matColumnDef="srno">
        <th mat-header-cell *matHeaderCellDef>#</th>
        <td mat-cell *matCellDef="let element; let i = index">
          {{ i + 1 }}
        </td>
      </ng-container>

      <ng-container matColumnDef="key">
        <th mat-header-cell *matHeaderCellDef>Key</th>
        <td mat-cell *matCellDef="let element">{{ element.key }}</td>
      </ng-container>

      <ng-container matColumnDef="desc">
        <th mat-header-cell *matHeaderCellDef>Description</th>
        <td mat-cell *matCellDef="let element">{{ element.description }}</td>
      </ng-container>

      <ng-container matColumnDef="input">
        <th mat-header-cell *matHeaderCellDef>Input</th>
        <td mat-cell *matCellDef="let element">{{ element.valtext }}</td>
      </ng-container>

      <ng-container matColumnDef="action">
        <th mat-header-cell *matHeaderCellDef>Action</th>
        <td mat-cell *matCellDef="let element">
          <a href="javascript:;" (click)="viewRec('view', element)"
            ><i class="fa fa-eye" aria-hidden="true"></i></a
          >&nbsp;
          <a href="javascript:;" (click)="editRec('edit', element)"
            ><i class="fa fa-pencil" aria-hidden="true"></i></a
          >&nbsp;
          <a href="javascript:;" (click)="deleteCompBank('delete', element)"
            ><i class="fa fa-trash" aria-hidden="true"></i
          ></a>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumnslist"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumnslist"></tr>
    </table>
  </div>
</div>

<div class="example-container" *ngIf="showForm">
  <form [formGroup]="masterDataList" (ngSubmit)="updateMasterData()">
    <div>
      <mat-form-field appearance="fill">
        <mat-label>Key</mat-label>
        <input matInput formControlName="key" required />
      </mat-form-field>
    </div>
    <div>
      <mat-form-field appearance="fill">
        <mat-label>Key Label</mat-label>
        <input matInput formControlName="keylabel" required />
      </mat-form-field>
    </div>
    <div>
      <mat-form-field appearance="fill">
        <mat-label>Value</mat-label>
        <input matInput formControlName="keyvalue" required />
      </mat-form-field>
    </div>

    <section>
      <div class="cta-button-row">
        <button
          mat-raised-button
          class="cta-button"
          *ngIf="formAction !== 'view'">
          Submit
        </button>
        <button mat-raised-button class="cta-button" (click)="HideForm()">
          Back
        </button>
      </div>
    </section>
  </form>
</div>
