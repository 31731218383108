<div id="header" class="mt-10 flex flex-col">
  <span class="font-lato text-2xl">Company Group List</span>
  <span class="font-lato text-md py-2 text-gray-500"
    >Here you can find the list of all the companies that you have added! You
    can click on the Group ID for more information.</span
  >
</div>
<div
  id="cgrpList"
  class="bg-white mt-4 py-1 rounded-lg mat-elevation-z2"
  *ngIf="showListing">
  <table mat-table [dataSource]="cgrpList" class="w-full">
    <!-- Position Column -->
    <ng-container matColumnDef="srno">
      <th mat-header-cell *matHeaderCellDef>#</th>
      <td mat-cell *matCellDef="let element; let i = index">
        {{ i + 1 }}
      </td>
    </ng-container>

    <!-- Name Column -->
    <ng-container matColumnDef="cgrpid">
      <th mat-header-cell *matHeaderCellDef>Company Group ID</th>
      <td mat-cell *matCellDef="let element" class="cursor-pointer">
        <a (click)="viewRec('view', element)" tabindex="0">
          <span class="text-blue-400"> {{ element.cgrp_id }}</span></a
        >
      </td>
    </ng-container>

    <!-- Weight Column -->
    <ng-container matColumnDef="cgrpname">
      <th mat-header-cell *matHeaderCellDef>Company Group Name</th>
      <td mat-cell *matCellDef="let element">{{ element.cgrp_name }}</td>
    </ng-container>

    <!-- Symbol Column -->
    <ng-container matColumnDef="status">
      <th mat-header-cell *matHeaderCellDef>Status</th>
      <td mat-cell *matCellDef="let element">{{ element.rstatus }}</td>
    </ng-container>

    <!-- Symbol Column -->
    <ng-container matColumnDef="cid">
      <th mat-header-cell *matHeaderCellDef>Created ID</th>
      <td mat-cell *matCellDef="let element">{{ element.created_id }}</td>
    </ng-container>
    <!-- Symbol Column -->

    <ng-container matColumnDef="cdt">
      <th mat-header-cell *matHeaderCellDef>Created Date</th>
      <td mat-cell *matCellDef="let element">{{ element.created_dt }}</td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
</div>

<div *ngIf="showForm" class="mt-4">
  <form class="example-form" [formGroup]="compcgrp">
    <span class="font-lato mb-2">Company Group ID</span>
    <input
      #cgrpidForm
      readonly
      formControlName="cgrpid"
      placeholder="Company Group ID"
      class="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg block w-1/2 p-2.5" />
    <div class="bg-transparent py-2"></div>

    <span class="font-lato">Company Group Name</span>
    <input
      readonly
      formControlName="cgrpname"
      placeholder="Company Group Name"
      class="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg block w-1/2 p-2.5" />
    <div class="bg-transparent py-2"></div>
    <span class="font-lato">Company Group Logo</span>
    <input
      readonly
      formControlName="cgrplogo"
      placeholder="Company Group Logo"
      class="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg block w-1/2 p-2.5" />
    <div class="bg-transparent py-2"></div>
    <span class="font-lato">Company Group URL</span>
    <input
      readonly
      formControlName="cgrpurl"
      placeholder="Company Group URL"
      class="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg block w-1/2 p-2.5" />

    <div class="bg-transparent py-2"></div>
    <button
      (click)="HideForm()"
      type="button"
      class="text-white bg-blue-600 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2">
      Go back
    </button>
  </form>
</div>
