<div class="container p-4">
  <!-- Drag and Drop File Upload -->
  <div class="flex flex-col">
    <div
      class="drop-zone"
      (drop)="onFileDropped($event)"
      (dragover)="onDragOver($event)">
      <input type="file" #fileDropRef (change)="onFileSelected($event)" />
      <div *ngIf="!isFileSelected">
        <img
          src="assets/images/spreadsheet/ic-upload-file.svg"
          alt="ic-upload-file.svg"
          height="64px"
          width="64px" />
        <div class="m-2">
          <h3>Drag and drop your file here</h3>
          <h3>or</h3>
        </div>
        <label
          class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mt-2"
          for="fileDropRef"
          >Browse for file</label
        >
      </div>
      <div *ngIf="isFileSelected">
        <i class="fa-solid fa-file-csv fa-3x"></i>
        <pre></pre>
        <h3>{{ selectedFileName }}</h3>
      </div>
    </div>

    <div class="mt-2">
      <button
        class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5"
        (click)="uploadData()">
        Upload
      </button>
    </div>
  </div>
</div>
