<div class="flex flex-row justify-between items-center">
  <div id="header" class="mt-10 flex flex-col">
    <span class="font-lato text-2xl">User Admin Panel</span>
    <span class="font-lato text-md py-2 text-gray-500"
      >Add or Manage your users here!</span
    >
  </div>
  <button
    (click)="openAddUserDialog()"
    type="button"
    class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm h-1/2 px-5 py-2.5 mr-2">
    Add User
  </button>
</div>

<div id="menulist" class="bg-white mt-4 pb-1 rounded-lg mat-elevation-z2">
  <div class="py-3 px-3 flex flex-row justify-between items-center">
    <span id="name" class="font-lato">Menu List</span>
    <div class="mr-2">
      <input
        class="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg block p-2.5 w-full"
        formControlName="txnno"
        (keyup)="applyFilter($event)"
        placeholder="Search user..." />
    </div>
  </div>
  <hr />
  <table mat-table [dataSource]="userlist" class="w-full">
    <ng-container matColumnDef="No">
      <th mat-header-cell *matHeaderCellDef>#</th>
      <td mat-cell *matCellDef="let element; let i = index">{{ i + 1 }}</td>
    </ng-container>
    <ng-container matColumnDef="User Name">
      <th mat-header-cell *matHeaderCellDef>User Name</th>
      <td mat-cell *matCellDef="let element">{{ element.user_name }}</td>
    </ng-container>

    <ng-container matColumnDef="User Code">
      <th mat-header-cell *matHeaderCellDef>User Code</th>
      <td mat-cell *matCellDef="let element">{{ element.user_code }}</td>
    </ng-container>

    <ng-container matColumnDef="User Email">
      <th mat-header-cell *matHeaderCellDef>User Email</th>
      <td mat-cell *matCellDef="let element">{{ element.user_email }}</td>
    </ng-container>

    <ng-container matColumnDef="Company Group ID">
      <th mat-header-cell *matHeaderCellDef>Company Group ID</th>
      <td mat-cell *matCellDef="let element">{{ element.cgrp_id }}</td>
    </ng-container>
    <ng-container matColumnDef="Actions">
      <th mat-header-cell *matHeaderCellDef>Actions</th>
      <td mat-cell *matCellDef="let element">
        <div #actionHandlerContainer class="flex flex-row">
          <button
            (click)="updateUser(element.user_code)"
            #iconContainer
            class="w-1/2 px-1">
            <img
              class="w-[20px] hover:opacity-50 duration-300"
              src="assets/icons/pencil.png"
              alt="update" />
          </button>
          <button
            (click)="viewUser(element.user_code)"
            #iconContainer
            class="w-1/2 px-1">
            <img
              class="w-[25px] hover:opacity-50 duration-300"
              src="assets/icons/view.png"
              alt="view" />
          </button>

          <button
            (click)="manageUser(element.user_code)"
            #iconContainer
            class="w-1/2 px-1">
            <img
              class="w-[25px] hover:opacity-50 duration-300"
              src="assets/icons/setting-alt.png"
              alt="" />
          </button>
        </div>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
</div>
