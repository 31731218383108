<div id="header" class="mt-10 flex flex-col">
  <span class="font-lato text-2xl">Statement Convertor</span>
  <span class="font-lato text-md py-2 text-gray-500"></span>
</div>

<div
  id="MfExposure"
  class="bg-white mt-4 pb-1 rounded-lg mat-elevation-z2"
  *ngIf="showList">
  <div class="py-3 px-3">
    <span id="name" class="font-lato">Statement List</span>
  </div>
  <hr />
  <table mat-table [dataSource]="stmtList">
    <!-- Position Column -->
    <ng-container matColumnDef="srno">
      <th mat-header-cell *matHeaderCellDef>#</th>
      <td mat-cell *matCellDef="let element; let i = index">
        {{ i + 1 }}
      </td>
    </ng-container>

    <!-- Name Column -->
    <ng-container matColumnDef="company">
      <th mat-header-cell *matHeaderCellDef>Company</th>
      <td mat-cell *matCellDef="let element">{{ element.comp }}</td>
    </ng-container>

    <!-- Weight Column -->
    <ng-container matColumnDef="txnid">
      <th mat-header-cell *matHeaderCellDef>Transaction ID</th>
      <td mat-cell *matCellDef="let element">
        <a href="javascript:;" (click)="edit(element)"> {{ element.ref }} </a>
      </td>
    </ng-container>

    <ng-container matColumnDef="accno">
      <th mat-header-cell *matHeaderCellDef>Account No.</th>
      <td mat-cell *matCellDef="let element">{{ element.ac_no }}</td>
    </ng-container>

    <ng-container matColumnDef="bankcode">
      <th mat-header-cell *matHeaderCellDef>Bank Code</th>
      <td mat-cell *matCellDef="let element">{{ element.bank_code }}</td>
    </ng-container>

    <ng-container matColumnDef="bankname">
      <th mat-header-cell *matHeaderCellDef>Bank Name</th>
      <td mat-cell *matCellDef="let element">{{ element.bank }}</td>
    </ng-container>

    <ng-container matColumnDef="lastupload">
      <th mat-header-cell *matHeaderCellDef>Last Upload</th>
      <td mat-cell *matCellDef="let element">{{ element.update_dt }}</td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
</div>

<div class="example-container" *ngIf="showForm">
  <form [formGroup]="stmtForm" (ngSubmit)="processCCFile()">
    <div class="container">
      <div id="header" class="mt-10 flex flex-col">
        <span class="font-lato text-2xl">Statement Convertor</span>
        <span class="font-lato text-md py-2 text-gray-500"></span>
      </div>

      <div class="form-body">
        <div class="horizontal-group">
          <div class="form-group left">
            <form-field appearance="fill">
              <label for="company" class="label-title">company Code</label>
              <input id="company" matInput formControlName="company" required />
            </form-field>
          </div>

          <div class="form-group left">
            <form-field appearance="fill">
              <label for="bank" class="label-title">Bank Name</label>
              <input id="bank" matInput formControlName="bank" required />
            </form-field>
          </div>

          <div class="form-group left">
            <form-field appearance="fill">
              <label for="accno" class="label-title">Account No.</label>
              <input id="accno" matInput formControlName="accno" required />
            </form-field>
          </div>

          <div class="form-group left">
            <form-field appearance="fill">
              <label for="txnid" class="label-title">Transaction ID</label>
              <input id="txnid" matInput formControlName="txnid" required />
            </form-field>
          </div>

          <div class="form-group left">
            <form-field appearance="fill" style="display: none">
              <input
                matInput
                placeholder="Choose file"
                readonly
                [value]="selectedFile?.name"
                class="visually-hidden" />
            </form-field>
            <div class="file-input-container">
              <label for="file" class="label-title">Choose File</label>
              <input
                id="file"
                type="file"
                #fileInput
                (change)="onFileSelected(fileInput.files)"
                class="file-input" />
            </div>
          </div>
        </div>
      </div>
      <div class="form-footer">
        <div class="cta-button-row">
          <button mat-raised-button class="cta-button">Submit</button>
          <button mat-raised-button class="cta-button" (click)="HideForm()">
            Back</button
          >&nbsp;
          <span>{{ uploadmsg }}</span>
        </div>
      </div>
    </div>
  </form>
</div>
