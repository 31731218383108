import { NgIf } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, FormsModule } from '@angular/forms';
import { first } from 'rxjs';
import { DbService } from 'src/app/core/services/db.service';
import {
  MatDialog,
  MAT_DIALOG_DATA,
  MatDialogRef,
  MatDialogModule,
} from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';

export interface DialogData {
  pathname: any;
  filename: any;
}

@Component({
  selector: 'app-formgeneration',
  templateUrl: './formgeneration.component.html',
  styleUrls: ['./formgeneration.component.scss'],
})
export class FormgenerationComponent implements OnInit {
  TxnInfoList: any;
  name: any;

  constructor(
    private dbservice: DbService,
    private http: HttpClient,
    public dialog: MatDialog
  ) {}

  allcompList: any[] = [];
  formtypeList: any[] = [];
  InvInfoList: any[] = [];

  //Flags
  isFormGenerated = false;

  ngOnInit(): void {
    this.allCompList();
    this.formtypeList = [{ frmname: 'CP Listed' }, { frmname: 'CP Unlisted' }];
    this.getFormsTypeList();
  }

  getfrmData = new FormGroup({
    company: new FormControl(''),
    cpfrmtype: new FormControl(''),
    txnno: new FormControl(''),
  });

  allCompList() {
    this.dbservice
      .cpdoc([], 'allCompList')
      .pipe(first())
      .subscribe({
        next: (data: any) => {
          this.allcompList = data['res'];
          // console.log(this.mclrBankList);
        },
        error: (err: any) => {
          console.log(err);
        },
      });
  }
  fromData: any = [];
  txnno: any;
  comp_code: any;
  getTxnInfo() {
    this.fromData = this.getfrmData.value;
    this.dbservice
      .cpdoc(this.fromData, 'getTxnInfo')
      .pipe(first())
      .subscribe({
        next: (data: any) => {
          this.TxnInfoList = data['res'];
          this.txnno = data['res'][0]['txn_no'];
          this.comp_code = data['res'][0]['comp_code'];
          // this.InvInfoList = data['res']['invdata']
        },
      });
  }
  FormsTypeList: any[] = [];
  getFormsTypeList() {
    this.dbservice
      .cpdoc([], 'getFormsTypeList')
      .pipe(first())
      .subscribe({
        next: (data: any) => {
          this.FormsTypeList = data['res'];
        },
      });
  }

  filepathname: any;
  filepath: any;

  preGeneratePDF(data: any, doctype: string) {
    data.txn_no = this.txnno;
    data.comp_code = this.comp_code;

    this.dbservice
      .forms(data, doctype, 'GenerateFormsByType')
      .pipe(first())
      .subscribe({
        next: (data: any) => {
          ///this.FormsTypeList = data['res'];
          this.filepathname = '/pdfs/' + data['res'].filename;
          const pathTillHtdocs =
            window.location.protocol + '//' + window.location.host;
          const pathtofile =
            pathTillHtdocs +
            '/fsnxtbackend/uploads/formdocumentation/' +
            data['res'].filename;
          //console.log(pathtofile);
          //this.filepathname = data['res'].filepath;
          this.dialog.open(PdfdialogComponent, {
            data: { pathname: pathtofile, filename: data['res'].filename },
            /* data: {name: this.name, animal: this.animal}, */
          });
        },
      });
  }

  displayedColumns: string[] = [
    'txnno',
    'classid',
    'isin',
    'issueamt',
    'issuedate',
    'facevalue',
    'matdate',
    'ipa',
  ];

  displayedColumnsf: string[] = ['srno', 'type', 'document', 'pdf', 'doc'];
}

@Component({
  selector: 'app-generatepdf-dialog',
  templateUrl: 'generatepdf-dialog.html',
  standalone: true,
  imports: [
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    MatButtonModule,
  ],
})
export class PdfdialogComponent {
  constructor(
    public dialogRef: MatDialogRef<PdfdialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {}

  onNoClick(): void {
    this.dialogRef.close();
  }
}
