<div id="mainContainer" class="w-fit h-fit p-10">
  <div #menuNameContainers class="mr-2 flex-1">
    <label for="menuname" class="font-lato">Menu Name</label>
    <br />
    <input
      id="menuname"
      type="text"
      [(ngModel)]="menuName"
      class="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg block p-2.5 w-full"
      required />
  </div>
  <div #menuCodeContainers class="mr-2 flex-1">
    <label for="menucode" class="font-lato">Menu Code</label>
    <br />
    <input
      id="menucode"
      [(ngModel)]="menuCode"
      type="text"
      class="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg block p-2.5 w-full"
      required />
  </div>
  <button
    (click)="addMenu()"
    type="button"
    class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2">
    Add Menu
  </button>
  <button
    mat-dialog-close
    class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2">
    Close Dialog
  </button>
</div>
