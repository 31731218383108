<div
  id="name"
  class="flex flex-row items-center font-extrabold text-gray-500 content-heading">
  <i class="fa-solid fa-list"></i>&nbsp; Financial Data
</div>
<mat-card tabindex="0" class="card-style">
  <div
    id="header"
    class="flex flex-row justify-between items-center mx-3 mb-2 md:mb-0"></div>
  <div id="mclr-download" class="">
    <div class="row d-flex">
      <form class="example-form">
        <div>
          <div
            id="table"
            class="max-w-full max-h-full pb-3 overflow-scroll lg:overflow-hidden mclrpading">
            <form [formGroup]="findatagroup">
              <div>
                <form-field appearance="fill">
                  <label for="fyyear" class="label-title">FYYear</label>
                  <br />
                  <select id="fyyear" formControlName="fyyear">
                    <option
                      *ngFor="let year of getPastFinancialYears()"
                      [value]="year">
                      {{ year }}
                    </option>
                  </select>
                </form-field>
              </div>
              <br />
              <div>
                <form-field appearance="fill">
                  <label for="period" class="label-title">Period</label>
                  <br />
                  <select id="fyyear" formControlName="period">
                    <option
                      *ngFor="let frequency of periodList"
                      [value]="frequency.key">
                      {{ frequency.val }}
                    </option>
                  </select>
                </form-field>
              </div>
              <br />
              <div>
                <form-field appearance="fill">
                  <label for="company" class="label-title">Company</label>
                  <br />
                  <select id="company" formControlName="company">
                    <option
                      *ngFor="let comp of allcompList"
                      [value]="comp.comp_code">
                      {{ comp.comp_code }} - {{ comp.comp_name }}
                    </option>
                  </select>
                </form-field>
              </div>
              <section>
                <div class="cta-button-row">
                  <button
                    mat-raised-button
                    class="cta-button"
                    (click)="DisplayFinancialFrm()">
                    Display
                  </button>
                </div>
              </section>
              <div class="table-container" *ngIf="finsmttbl">
                <button
                  mat-raised-button
                  class="cta-button"
                  *ngIf="cbtn"
                  (ngSubmit)="finstmtCRUD(formAction)">
                  {{ formAction }}
                </button>
                <button
                  mat-raised-button
                  class="cta-button"
                  *ngIf="ubtn"
                  (ngSubmit)="finstmtCRUD(formAction)">
                  {{ formAction }}
                </button>
                <!-- <button mat-raised-button class="cta-button" *ngIf="rbtn"> {{formAction}} </button> -->
                <button
                  mat-raised-button
                  class="cta-button"
                  *ngIf="dbtn"
                  (ngSubmit)="finstmtCRUD(formAction)">
                  Delete
                </button>

                <table
                  class="table table-condensed table-hover"
                  *ngIf="showgrid">
                  <thead>
                    <tr>
                      <th>Attribute:</th>
                      <th *ngIf="col1">30-June</th>
                      <th *ngIf="col2">30-September</th>
                      <th *ngIf="col3">31-December</th>
                      <th *ngIf="col4">31-March</th>
                      <th *ngIf="col5">31-March</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <div>Equity:</div>
                      <td *ngIf="col1">
                        <mat-form-field>
                          <mat-label>Equity</mat-label>
                          <input matInput type="text" formControlName="eq_q1" />
                        </mat-form-field>
                      </td>
                      <td *ngIf="col2">
                        <mat-form-field>
                          <mat-label>Equity</mat-label>
                          <input matInput type="text" formControlName="eq_q2" />
                        </mat-form-field>
                      </td>
                      <td *ngIf="col3">
                        <mat-form-field>
                          <mat-label>Equity</mat-label>
                          <input matInput type="text" formControlName="eq_q3" />
                        </mat-form-field>
                      </td>
                      <td *ngIf="col4">
                        <mat-form-field>
                          <mat-label>Equity</mat-label>
                          <input matInput type="text" formControlName="eq_q4" />
                        </mat-form-field>
                      </td>
                      <td *ngIf="col5">
                        <mat-form-field>
                          <mat-label>Equity</mat-label>
                          <input matInput type="text" formControlName="eq_a1" />
                        </mat-form-field>
                      </td>
                    </tr>
                    <tr>
                      <div>Prefrence Shares:</div>
                      <td *ngIf="col1">
                        <mat-form-field>
                          <mat-label>Prefrence Shares</mat-label>
                          <input
                            matInput
                            type="text"
                            formControlName="pref_q1" />
                        </mat-form-field>
                      </td>
                      <td *ngIf="col2">
                        <mat-form-field>
                          <mat-label>Prefrence Shares</mat-label>
                          <input
                            matInput
                            type="text"
                            formControlName="pref_q2" />
                        </mat-form-field>
                      </td>
                      <td *ngIf="col3">
                        <mat-form-field>
                          <mat-label>Prefrence Shares</mat-label>
                          <input
                            matInput
                            type="text"
                            formControlName="pref_q3" />
                        </mat-form-field>
                      </td>
                      <td *ngIf="col4">
                        <mat-form-field>
                          <mat-label>Prefrence Shares</mat-label>
                          <input
                            matInput
                            type="text"
                            formControlName="pref_q4" />
                        </mat-form-field>
                      </td>
                      <td *ngIf="col5">
                        <mat-form-field>
                          <mat-label>Prefrence Shares</mat-label>
                          <input
                            matInput
                            type="text"
                            formControlName="pref_a1" />
                        </mat-form-field>
                      </td>
                    </tr>
                    <tr>
                      <div>Net worth :</div>
                      <td *ngIf="col1">
                        <mat-form-field>
                          <mat-label>Net worth</mat-label>
                          <input
                            matInput
                            type="text"
                            formControlName="networth_q1" />
                        </mat-form-field>
                      </td>
                      <td *ngIf="col2">
                        <mat-form-field>
                          <mat-label>Net worth</mat-label>
                          <input
                            matInput
                            type="text"
                            formControlName="networth_q2" />
                        </mat-form-field>
                      </td>
                      <td *ngIf="col3">
                        <mat-form-field>
                          <mat-label>Net worth</mat-label>
                          <input
                            matInput
                            type="text"
                            formControlName="networth_q3" />
                        </mat-form-field>
                      </td>
                      <td *ngIf="col4">
                        <mat-form-field>
                          <mat-label>Net worth</mat-label>
                          <input
                            matInput
                            type="text"
                            formControlName="networth_q4" />
                        </mat-form-field>
                      </td>
                      <td *ngIf="col5">
                        <mat-form-field>
                          <mat-label>Net worth</mat-label>
                          <input
                            matInput
                            type="text"
                            formControlName="networth_a1" />
                        </mat-form-field>
                      </td>
                    </tr>
                    <tr>
                      <div>Investment in Subsidiaries :</div>
                      <td *ngIf="col1">
                        <mat-form-field>
                          <mat-label>Investment in Subsidiaries</mat-label>
                          <input
                            matInput
                            type="text"
                            formControlName="inv_q1" />
                        </mat-form-field>
                      </td>
                      <td *ngIf="col2">
                        <mat-form-field>
                          <mat-label>Investment in Subsidiaries</mat-label>
                          <input
                            matInput
                            type="text"
                            formControlName="inv_q2" />
                        </mat-form-field>
                      </td>
                      <td *ngIf="col3">
                        <mat-form-field>
                          <mat-label>Investment in Subsidiaries</mat-label>
                          <input
                            matInput
                            type="text"
                            formControlName="inv_q3" />
                        </mat-form-field>
                      </td>
                      <td *ngIf="col4">
                        <mat-form-field>
                          <mat-label>Investment in Subsidiaries</mat-label>
                          <input
                            matInput
                            type="text"
                            formControlName="inv_q4" />
                        </mat-form-field>
                      </td>
                      <td *ngIf="col5">
                        <mat-form-field>
                          <mat-label>Investment in Subsidiaries</mat-label>
                          <input
                            matInput
                            type="text"
                            formControlName="inv_a1" />
                        </mat-form-field>
                      </td>
                    </tr>
                    <tr>
                      <div>Total Debt Outstanding :</div>
                      <td *ngIf="col1">
                        <mat-form-field>
                          <mat-label>Total Debt Outstanding</mat-label>
                          <input
                            matInput
                            type="text"
                            formControlName="dbout_q1" />
                        </mat-form-field>
                      </td>
                      <td *ngIf="col2">
                        <mat-form-field>
                          <mat-label>Total Debt Outstanding</mat-label>
                          <input
                            matInput
                            type="text"
                            formControlName="dbout_q2" />
                        </mat-form-field>
                      </td>
                      <td *ngIf="col3">
                        <mat-form-field>
                          <mat-label>Total Debt Outstanding</mat-label>
                          <input
                            matInput
                            type="text"
                            formControlName="dbout_q3" />
                        </mat-form-field>
                      </td>
                      <td *ngIf="col4">
                        <mat-form-field>
                          <mat-label>Total Debt Outstanding</mat-label>
                          <input
                            matInput
                            type="text"
                            formControlName="dbout_q4" />
                        </mat-form-field>
                      </td>
                      <td *ngIf="col5">
                        <mat-form-field>
                          <mat-label>Total Debt Outstanding</mat-label>
                          <input
                            matInput
                            type="text"
                            formControlName="dbout_a1" />
                        </mat-form-field>
                      </td>
                    </tr>
                    <tr>
                      <div>Short Term :</div>
                      <td *ngIf="col1">
                        <mat-form-field>
                          <mat-label>Short Term </mat-label>
                          <input
                            matInput
                            type="text"
                            formControlName="shterm_q1" />
                        </mat-form-field>
                      </td>
                      <td *ngIf="col2">
                        <mat-form-field>
                          <mat-label>Short Term </mat-label>
                          <input
                            matInput
                            type="text"
                            formControlName="shterm_q2" />
                        </mat-form-field>
                      </td>
                      <td *ngIf="col3">
                        <mat-form-field>
                          <mat-label>Short Term </mat-label>
                          <input
                            matInput
                            type="text"
                            formControlName="shterm_q3" />
                        </mat-form-field>
                      </td>
                      <td *ngIf="col4">
                        <mat-form-field>
                          <mat-label>Short Term </mat-label>
                          <input
                            matInput
                            type="text"
                            formControlName="shterm_q4" />
                        </mat-form-field>
                      </td>
                      <td *ngIf="col5">
                        <mat-form-field>
                          <mat-label>Short Term </mat-label>
                          <input
                            matInput
                            type="text"
                            formControlName="shterm_a1" />
                        </mat-form-field>
                      </td>
                    </tr>
                    <tr>
                      <div>Other Debt :</div>
                      <td *ngIf="col1">
                        <mat-form-field>
                          <mat-label>Other Debt </mat-label>
                          <input
                            matInput
                            type="text"
                            formControlName="debt_q1" />
                        </mat-form-field>
                      </td>
                      <td *ngIf="col2">
                        <mat-form-field>
                          <mat-label>Other Debt</mat-label>
                          <input
                            matInput
                            type="text"
                            formControlName="debt_q2" />
                        </mat-form-field>
                      </td>
                      <td *ngIf="col3">
                        <mat-form-field>
                          <mat-label>Other Debt</mat-label>
                          <input
                            matInput
                            type="text"
                            formControlName="debt_q3" />
                        </mat-form-field>
                      </td>
                      <td *ngIf="col4">
                        <mat-form-field>
                          <mat-label>Other Debt</mat-label>
                          <input
                            matInput
                            type="text"
                            formControlName="debt_q4" />
                        </mat-form-field>
                      </td>
                      <td *ngIf="col5">
                        <mat-form-field>
                          <mat-label>Other Debt</mat-label>
                          <input
                            matInput
                            type="text"
                            formControlName="debt_a1" />
                        </mat-form-field>
                      </td>
                    </tr>
                    <tr>
                      <div>Gross Income :</div>
                      <td *ngIf="col1">
                        <mat-form-field>
                          <mat-label>Gross Income</mat-label>
                          <input
                            matInput
                            type="text"
                            formControlName="income_q1" />
                        </mat-form-field>
                      </td>
                      <td *ngIf="col2">
                        <mat-form-field>
                          <mat-label>Gross Income</mat-label>
                          <input
                            matInput
                            type="text"
                            formControlName="income_q2" />
                        </mat-form-field>
                      </td>
                      <td *ngIf="col3">
                        <mat-form-field>
                          <mat-label>Gross Income</mat-label>
                          <input
                            matInput
                            type="text"
                            formControlName="income_q3" />
                        </mat-form-field>
                      </td>
                      <td *ngIf="col4">
                        <mat-form-field>
                          <mat-label>Gross Income</mat-label>
                          <input
                            matInput
                            type="text"
                            formControlName="income_q4" />
                        </mat-form-field>
                      </td>
                      <td *ngIf="col5">
                        <mat-form-field>
                          <mat-label>Gross Income</mat-label>
                          <input
                            matInput
                            type="text"
                            formControlName="income_a1" />
                        </mat-form-field>
                      </td>
                    </tr>
                    <tr>
                      <div>Operating Profit(PBITD) :</div>
                      <td *ngIf="col1">
                        <mat-form-field>
                          <mat-label>Operating Profit</mat-label>
                          <input
                            matInput
                            type="text"
                            formControlName="opprofit_q1" />
                        </mat-form-field>
                      </td>
                      <td *ngIf="col2">
                        <mat-form-field>
                          <mat-label>Operating Profit</mat-label>
                          <input
                            matInput
                            type="text"
                            formControlName="opprofit_q2" />
                        </mat-form-field>
                      </td>
                      <td *ngIf="col3">
                        <mat-form-field>
                          <mat-label>Operating Profit</mat-label>
                          <input
                            matInput
                            type="text"
                            formControlName="opprofit_q3" />
                        </mat-form-field>
                      </td>
                      <td *ngIf="col4">
                        <mat-form-field>
                          <mat-label>Operating Profit</mat-label>
                          <input
                            matInput
                            type="text"
                            formControlName="opprofit_q4" />
                        </mat-form-field>
                      </td>
                      <td *ngIf="col5">
                        <mat-form-field>
                          <mat-label>Operating Profit</mat-label>
                          <input
                            matInput
                            type="text"
                            formControlName="opprofit_a1" />
                        </mat-form-field>
                      </td>
                    </tr>
                    <tr>
                      <div>Gross Profit(PBTD) :</div>
                      <td *ngIf="col1">
                        <mat-form-field>
                          <mat-label>Gross Profit</mat-label>
                          <input
                            matInput
                            type="text"
                            formControlName="grprofit_q1" />
                        </mat-form-field>
                      </td>
                      <td *ngIf="col2">
                        <mat-form-field>
                          <mat-label>Gross Profit</mat-label>
                          <input
                            matInput
                            type="text"
                            formControlName="grprofit_q2" />
                        </mat-form-field>
                      </td>
                      <td *ngIf="col3">
                        <mat-form-field>
                          <mat-label>Gross Profit</mat-label>
                          <input
                            matInput
                            type="text"
                            formControlName="grprofit_q3" />
                        </mat-form-field>
                      </td>
                      <td *ngIf="col4">
                        <mat-form-field>
                          <mat-label>Gross Profit</mat-label>
                          <input
                            matInput
                            type="text"
                            formControlName="grprofit_q4" />
                        </mat-form-field>
                      </td>
                      <td *ngIf="col5">
                        <mat-form-field>
                          <mat-label>Gross Profit</mat-label>
                          <input
                            matInput
                            type="text"
                            formControlName="grprofit_a1" />
                        </mat-form-field>
                      </td>
                    </tr>
                    <tr>
                      <div>Net Profit(PAT) :</div>
                      <td *ngIf="col1">
                        <mat-form-field>
                          <mat-label>Net Profit</mat-label>
                          <input
                            matInput
                            type="text"
                            formControlName="netprofit_q1" />
                        </mat-form-field>
                      </td>
                      <td *ngIf="col2">
                        <mat-form-field>
                          <mat-label>Net Profit</mat-label>
                          <input
                            matInput
                            type="text"
                            formControlName="netprofit_q2" />
                        </mat-form-field>
                      </td>
                      <td *ngIf="col3">
                        <mat-form-field>
                          <mat-label>Net Profit</mat-label>
                          <input
                            matInput
                            type="text"
                            formControlName="netprofit_q3" />
                        </mat-form-field>
                      </td>
                      <td *ngIf="col4">
                        <mat-form-field>
                          <mat-label>Net Profit</mat-label>
                          <input
                            matInput
                            type="text"
                            formControlName="netprofit_q4" />
                        </mat-form-field>
                      </td>
                      <td *ngIf="col5">
                        <mat-form-field>
                          <mat-label>Net Profit</mat-label>
                          <input
                            matInput
                            type="text"
                            formControlName="netprofit_a1" />
                        </mat-form-field>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </form>
          </div>
        </div>
      </form>
    </div>
  </div>
</mat-card>
