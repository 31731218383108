<div id="dialogContainer" class="px-5 py-10">
  <div class="flex flex-row justify-between mb-10">
    <span class="font-lato text-xl"
      >Managing user access : {{ data.userCode }}</span
    >
    <img
      class="w-[25px] h-[25px] hover:opacity-25 duration-300"
      src="assets/icons/close.png"
      alt=""
      mat-dialog-close="" />
  </div>

  <ng-container *ngFor="let menu of menulist">
    <div id="menuItem" class="flex flex-row">
      <span class="font-lato text-blue-950 font-bold w-1/2"
        >{{ menu.MENU_NAME }} :</span
      >
      <mat-button-toggle-group
        [(ngModel)]="userAccessString[menu.MENU_CODE]"
        class="w-1/2">
        <mat-button-toggle value="show" class="w-40 !bg-white"
          >Show</mat-button-toggle
        >
        <mat-button-toggle value="hide" class="w-40 !bg-white"
          >Hide</mat-button-toggle
        >
        <mat-button-toggle value="locked" class="w-40 !bg-white"
          >Locked</mat-button-toggle
        >
      </mat-button-toggle-group>
    </div>
  </ng-container>

  <button
    (click)="updateAccess()"
    type="button"
    class="w-full mb-4 mt-10 text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2">
    Submit
  </button>

  <div class="flex flex-col w-full items-center">
    <span *ngIf="status !== ''" class="font-lato font-thin">{{ status }}</span>
  </div>
</div>
