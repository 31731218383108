import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { first } from 'rxjs';
import { DbService } from 'src/app/core/services/db.service';
import { ExportService } from 'src/app/core/services/export.service';

@Component({
  selector: 'app-ccstatement',
  templateUrl: './ccstatement.component.html',
  styleUrls: ['./ccstatement.component.scss'],
})
export class CcstatementComponent implements OnInit {
  constructor(
    private dbservice: DbService,
    private exportService: ExportService
  ) {}

  allcompList: any[] = [];
  compbankList: any[] = [];

  ngOnInit(): void {
    this.allCompList();
    this.getcompbank();
  }

  ccstmtdownload = new FormGroup({
    company: new FormControl(''),
    bank: new FormControl(''),
    mstartdate: new FormControl(''),
    menddate: new FormControl(''),
  });

  ccviewstmt = new FormGroup({
    company: new FormControl(''),
    bank: new FormControl(''),
    fromdate: new FormControl(''),
    todate: new FormControl(''),
  });

  deletebankstmt = new FormGroup({
    company: new FormControl(''),
    bank: new FormControl(''),
    sdate: new FormControl(''),
    edate: new FormControl(''),
  });

  dwnccdata: any = [];
  ccstmtList: any[] = [];
  exportmclr = false;

  downloadCCstmt() {
    this.dwnccdata = this.ccstmtdownload.value;
    this.dbservice
      .ccstmt(this.dwnccdata, 'downloadCCstmt')
      .pipe(first())
      .subscribe({
        next: (data: any) => {
          this.ccstmtList = data['result'];
          this.exportmclr = true;
          // console.log(this.mclrBankList);
        },
        error: (err: any) => {
          console.log(err);
        },
      });
  }

  bankstmtList: any[] = [];
  ccdataview: any = [];
  getbnkstatement() {
    this.ccdataview = this.ccviewstmt.value;
    this.dbservice
      .ccstmt(this.ccdataview, 'getbnkstatement')
      .pipe(first())
      .subscribe({
        next: (data: any) => {
          this.bankstmtList = data['result'];
          // console.log(this.mclrBankList);
        },
        error: (err: any) => {
          console.log(err);
        },
      });
  }

  delbankdata: any = [];
  msg: any = '';
  deleteBankStmt() {
    this.delbankdata = this.deletebankstmt.value;
    this.dbservice
      .ccstmt(this.ccdataview, 'deleteBankStmt')
      .pipe(first())
      .subscribe({
        next: (data: any) => {
          this.msg = data['msg'];
          console.log(data);
          // console.log(this.mclrBankList);
        },
        error: (err: any) => {
          console.log(err);
        },
      });
  }

  allCompList() {
    this.dbservice
      .cpdoc([], 'allCompList')
      .pipe(first())
      .subscribe({
        next: (data: any) => {
          this.allcompList = data['res'];
          // console.log(this.mclrBankList);
        },
        error: (err: any) => {
          console.log(err);
        },
      });
  }

  getcompbank() {
    this.dbservice
      .ccstmt([], 'getcompbank')
      .pipe(first())
      .subscribe({
        next: (data: any) => {
          this.compbankList = data['res'];
          // console.log(this.mclrBankList);
        },
        error: (err: any) => {
          console.log(err);
        },
      });
  }

  exportToCsv(ratelist: any, filename: any) {
    this.exportService.downloadFile(ratelist, filename);
  }

  displaytxndata = [
    'txnid',
    'date',
    'withdrawls',
    'deposit',
    'balance',
    'otherflows',
  ];
  viewccdata = [
    'txnid',
    'bank',
    'accno',
    'valdate',
    'check',
    'particular',
    'withdrawls',
    'deposit',
    'balance',
  ];
}
