<div id="header" class="mt-10 flex flex-col">
  <span class="font-lato text-2xl">Company List Management</span>
  <span class="font-lato text-md py-2 text-gray-500"
    >Add or Modify Company Lists Here. Click on any company code to view
    more.</span
  >
</div>

<div
  id="CompanyList"
  class="bg-white mt-4 pb-1 rounded-lg mat-elevation-z2"
  *ngIf="showListing">
  <div #tableHeader class="flex flex-row justify-between px-3 items-center">
    <div class="py-3">
      <span id="name" class="font-lato">Full Company List</span>
    </div>

    <button
      (click)="({})"
      type="button"
      class="text-white font-lato bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2">
      Add Company
    </button>
  </div>
  <hr />
  <table mat-table [dataSource]="compList" class="w-full">
    <!-- Position Column -->
    <ng-container matColumnDef="srno">
      <th mat-header-cell *matHeaderCellDef>#</th>
      <td mat-cell *matCellDef="let element; let i = index">
        {{ i + 1 }}
      </td>
    </ng-container>

    <!-- Name Column -->
    <ng-container matColumnDef="cgrpid">
      <th mat-header-cell *matHeaderCellDef>Company Group ID</th>
      <td mat-cell *matCellDef="let element">
        {{ element.cgrp_id }}
      </td>
    </ng-container>

    <!-- Weight Column -->
    <ng-container matColumnDef="compcode">
      <th mat-header-cell *matHeaderCellDef>Company Code</th>
      <td mat-cell *matCellDef="let element" class="cursor-pointer">
        <a tabindex="0" (click)="editRec(element)" class="text-blue-500">
          {{ element.comp_code }}</a
        >
      </td>
    </ng-container>

    <!-- Symbol Column -->
    <ng-container matColumnDef="compname">
      <th mat-header-cell *matHeaderCellDef>Company Name</th>
      <td mat-cell *matCellDef="let element">{{ element.comp_name }}</td>
    </ng-container>

    <ng-container matColumnDef="cdt">
      <th mat-header-cell *matHeaderCellDef>Created Date</th>
      <td mat-cell *matCellDef="let element">{{ element.created_dt }}</td>
    </ng-container>

    <ng-container matColumnDef="cid">
      <th mat-header-cell *matHeaderCellDef>Created ID</th>
      <td mat-cell *matCellDef="let element">{{ element.created_id }}</td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
</div>

<div *ngIf="showForm">
  <form [formGroup]="companylist" #CompanyDataDisplay>
    <div class="bg-transparent py-2"></div>

    <span class="font-lato">Company Code</span>
    <input
      readonly
      formControlName="compCode"
      placeholder="Company Code"
      class="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg block w-1/2 p-2.5" />
    <div class="bg-transparent py-2"></div>

    <span class="font-lato">Company Name</span>
    <input
      readonly
      formControlName="compName"
      placeholder="Company Name"
      class="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg block w-1/2 p-2.5" />
    <div class="bg-transparent py-2"></div>

    <span class="font-lato">Company Email</span>
    <input
      readonly
      formControlName="compEmail"
      placeholder="Company Email"
      class="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg block w-1/2 p-2.5" />
    <div class="bg-transparent py-2"></div>

    <span class="font-lato">Company URL</span>
    <input
      readonly
      formControlName="compUrl"
      placeholder="Company URL"
      class="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg block w-1/2 p-2.5" />
    <div class="bg-transparent py-2"></div>

    <span class="font-lato">Address Line 1</span>
    <input
      readonly
      formControlName="compAddr1"
      placeholder="Address Line 1"
      class="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg block w-1/2 p-2.5" />
    <div class="bg-transparent py-2"></div>

    <span class="font-lato">Address Line 2</span>
    <input
      readonly
      formControlName="compAddr2"
      placeholder="Address Line 2"
      class="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg block w-1/2 p-2.5" />
    <div class="bg-transparent py-2"></div>

    <span class="font-lato">Country</span>
    <input
      readonly
      formControlName="compCountry"
      placeholder="Country"
      class="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg block w-1/2 p-2.5" />
    <div class="bg-transparent py-2"></div>

    <span class="font-lato">State</span>
    <input
      readonly
      formControlName="compState"
      placeholder="State"
      class="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg block w-1/2 p-2.5" />
    <div class="bg-transparent py-2"></div>
    <span class="font-lato">City</span>
    <input
      readonly
      formControlName="compCity"
      placeholder="City"
      class="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg block w-1/2 p-2.5" />
    <div class="bg-transparent py-2"></div>
    <span class="font-lato">Postal Code</span>
    <input
      readonly
      formControlName="compPostalcode"
      placeholder="Postal Code"
      class="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg block w-1/2 p-2.5" />
    <div class="bg-transparent py-2"></div>

    <!-- test  -->

    <section>
      <div class="cta-button-row">
        <button
          mat-raised-button
          class="cta-button"
          *ngIf="formAction !== 'view'">
          Submit
        </button>
        <button
          (click)="HideForm()"
          type="button"
          class="mb-4 text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2">
          Go Back
        </button>
      </div>
    </section>
  </form>
</div>
