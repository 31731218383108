<!-- PAGE FOR DASHBOARD PARENT LAYOUT -->
<mat-toolbar
  *ngIf="sidenav.mode === 'over'"
  class="bg-fs-white flex flex-row justify-between !drop-shadow-lg border-b-2 !bg-white border-gray-100">
  <button
    mat-icon-button
    *ngIf="sidenav.mode === 'over'"
    (click)="sidenav.toggle()">
    <mat-icon *ngIf="!sidenav.opened"> menu </mat-icon>
    <mat-icon *ngIf="sidenav.opened"> close </mat-icon>
  </button>
  <img
    alt="fs-logo"
    class="h-10 w-1/7 ml-5"
    style="object-fit: fill"
    src="https://static.wixstatic.com/media/e5fb0b_fb648c75b543482fa941222093a44efa~mv2.png/v1/fill/w_273,h_130,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/Final%20logo.png" />
  <div
    class="mr-4 border-black rounded-full"
    style="object-fit: contain; width: 50px; height: 50px"></div>
</mat-toolbar>
<mat-sidenav-container class="h-full">
  <mat-sidenav
    mode="side"
    opened
    #sidenav="matSidenav"
    class="m-[16px] w-3/4 md:w-[290px] border-r-0 bg-white text-black !rounded-[10px] align-center md:rounded-none md:m-0">
    <div
      id="profileContainer"
      class="flex flex-row w-full h-fit items-center justify-between py-3 px-3">
      <div class="flex flex-row">
        <img
          class="h-10 rounded-lg"
          src="{{ defaultProfilePicture }}"
          alt="profile" />
        <div id="profilename" class="flex flex-col px-2">
          <span class="font-lato text-sm">{{ userName }}</span>
          <span class="font-lato text-gray-700 text-xs">Fourth Signal</span>
        </div>
      </div>
      <button
        (click)="logout()"
        class="border-gray-600 outline outline-gray-200 hover:bg-gray-100 shadow-md rounded-sm p-2">
        <img class="h-5 rounded-lg" src="assets/icons/exit.png" alt="logout" />
      </button>
    </div>
    <hr />
    <div class="h-[90%] flex flex-col justify-between">
      <div
        #normalDashboardMenus
        class="py-3 px-3"
        *ngIf="userAccessString !== null">
        <!-- PARENT MENUS  -->
        <mat-accordion>
          <ng-container *ngFor="let parentMenus of sidenavMenus; let i = index">
            <mat-expansion-panel
              [class.mat-elevation-z0]="true"
              dense
              *ngIf="userAccessString[parentMenus['code']] === 'show'">
              <!-- PARENT MENU NAME  -->
              <mat-expansion-panel-header class="px-3 !h-11 my-1">
                <div
                  #normalParentMenuContainer
                  class="flex flex-row items-center text-text-primary">
                  <mat-icon
                    class="mr-3"
                    *ngIf="userAccessString[parentMenus['code']] === 'locked'">
                    lock
                  </mat-icon>
                  <img
                    alt="lock"
                    *ngIf="userAccessString[parentMenus['code']] !== 'locked'"
                    src="assets/icons/{{ parentMenus['icon'] }}"
                    class="h-5 pr-3" />
                  <span class="text-md select-none text-gray-900 font-lato">{{
                    parentMenus['name']
                  }}</span>
                </div>
              </mat-expansion-panel-header>
              <!-- CHILD MENUS   -->
              <mat-nav-list class="!py-0">
                <ng-container
                  *ngFor="let subMenus of parentMenus['child']"
                  class="rounded-lg">
                  <a
                    class="rounded-lg mb-1 !h-10 px-3"
                    mat-list-item
                    routerLink="{{
                      userAccessString[parentMenus['code']] === 'locked'
                        ? 'locked'
                        : subMenus['routerlink']
                    }}"
                    routerLinkActive="{{
                      userAccessString[parentMenus['code']] === 'locked'
                        ? false
                        : 'sub-active'
                    }}"
                    (click)="clickToggle()">
                    <div #childMenuContainer class="flex flex-row items-center">
                      <img
                        class="h-5 ml-3 mr-3"
                        src="assets/icons/right-arrow.png"
                        alt="" />
                      <span
                        class="select-none font-lato text-sm text-text-secondary"
                        >{{ subMenus['name'] }}</span
                      >
                    </div>
                  </a>
                </ng-container>
              </mat-nav-list>
            </mat-expansion-panel>
          </ng-container>
        </mat-accordion>
      </div>
      <div
        #Footer
        class="flex flex-row items-center justify-center pb-3"
        *ngIf="userAccessString !== null">
        <img class="w-[20px]" src="assets/images/logos/FSlogo.png" alt="" />
        <span
          class="font-lato font-semibold text-md px-2 spacing tracking-wider"
          >FOURTH SIGNAL</span
        >
      </div>
    </div>
  </mat-sidenav>
  <mat-sidenav-content>
    <app-main-spinner class="spinner-overlay"></app-main-spinner>
    <div class="content">
      <router-outlet></router-outlet>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>
