<!-- <div
  #mainSpinnerDiv
  class="w-full h-full bg-transparent flex flex-col items-center justify-center"
  *ngIf="spinnerService.isSpinning()"
>
  <div class="spinner w-1/2 h-1/2 bg-green-400"></div>
</div> -->

<div
  #mainSpinnerDiv
  class="w-full h-full p-10 bg-black bg-opacity-10 flex flex-col items-center justify-center absolute"
  *ngIf="isLoading | async">
  <div class="spinner w-full h-[40px] mb-10"></div>
  <div id="empty" class="w-[40px] h-[40px]"></div>
  <span class="ml-9 font-lato font-semibold">Loading</span>
</div>
