import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { first } from 'rxjs';
import { ApiUrls } from 'src/apis';
import { AuthService } from 'src/app/core/services/auth.service';
import { DbService } from 'src/app/core/services/db.service';

@Component({
  selector: 'app-add-user-dialog',
  templateUrl: './add-user-dialog.component.html',
  styleUrls: ['./add-user-dialog.component.scss'],
})
export class AddUserDialogComponent implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { userCode: string },
    private dbService: DbService,
    private auth: AuthService
  ) {}
  UserArray: any = {
    USER_CODE: null, //
    USER_TITLE: null, //
    USER_NAME: null, //
    USER_MIDDLE_NAME: null, //
    USER_LAST_NAME: null, //
    USER_TYPE: null, //
    USER_MOBILE: null, //
    USER_EMAIL: null, //
    USER_MANAGER: null, //
    USER_FROM_DATE: null, //
    USER_TO_DATE: null, //
    USER_URL: null, //
    USER_COMPANY_GROUP_ID: null, //
    USER_R_STATUS: null, //
    USER_PASSWORD: null,
    USER_PASSWORD_LIFE: null,
    USER_PASSWORD_CHANGE_DATE: null, //Managed by Code
    USER_LANDING_MENU_CODE: null, //
    USER_APP_CODE: null, //
    USER_CREATOR_ID: null, //Managed by Code
    USER_CREATED_DATE: null, //Managed by Code
    USER_MODIFIED_ID: null, //Managed by Code
    USER_MODIFIED_DATE: null, //Managed by Code
    USER_ACCESS: null,
    USER_UAC: null,
  };

  userCode = '';
  status = '';

  ngOnInit(): void {
    this.getUser();
  }

  getUser() {
    //Function to get user
    this.dbService
      .postRequest(ApiUrls.dashboard, [this.auth.getToken()], 'getUser')
      .pipe(first())
      .subscribe({
        next: (data: any) => {
          this.userCode = data['USER_CODE'];
          this.UserArray['USER_CREATOR_ID'] = data['USER_CODE'];
          this.UserArray['USER_MODIFIED_ID'] = data['USER_CODE'];
        },
        error: err => {
          console.error(err);
        },
      });
  }

  checkUserCode(userCode: string) {
    this.dbService
      .admin([userCode], 'checkUserCode')
      .subscribe((response: any) => {
        console.log(response);
      });
  }

  addUser() {
    this.status = '';
    this.dbService
      .admin([this.UserArray], 'addUser')
      .subscribe((response: any) => {
        this.status = response['msg'];
      });
  }
}
