<body>
  <mat-card tabindex="0" class="card-style">
    <div
      id="header"
      class="flex flex-row justify-between items-center mx-3 mb-2 md:mb-0">
      <div
        id="name"
        class="flex flex-row items-center font-extrabold text-gray-500 content-heading">
        <i class="fa-solid fa-list"></i> Genearte All Forms
      </div>
    </div>
    <div id="mclr-download" class="">
      <div class="row d-flex">
        <form class="example-form">
          <div>
            <div
              id="table"
              class="max-w-full max-h-full pb-3 overflow-scroll lg:overflow-hidden mclrpading">
              <form [formGroup]="allforms" (ngSubmit)="generateAllPdf()">
                <div>
                  <form-field appearance="fill">
                    <label for="company" class="label-title">Company</label>
                    <br />
                    <select id="company" formControlName="company">
                      <option *ngFor="let comp of allcompList" [value]="comp">
                        {{ comp.comp_code }} - {{ comp.comp_name }}
                      </option>
                    </select>
                  </form-field>
                </div>
                <br />
                <div>
                  <form-field appearance="fill" class="frminp">
                    <label for="txnno" class="label-title"
                      >Transaction No.</label
                    >
                    <br />
                    <input
                      id="txnno"
                      matInput
                      formControlName="txnno"
                      required />
                  </form-field>
                </div>
                <br />
                <section>
                  <div class="cta-button-row">
                    <button mat-raised-button class="cta-button">
                      Genearte All PDF
                    </button>
                  </div>
                </section>
                <mat-spinner *ngIf="spiner === 'true'"></mat-spinner>
              </form>
            </div>
          </div>
        </form>
      </div>
    </div>
  </mat-card>
</body>
