<h1 mat-dialog-title>Download All PDF Document</h1>
<h1 mat-dialog-content>
  <!-- <div class="row">
        <div class="col-md-6">
            <ul class="list-unstyled">
                <li>
                  <span class="fa fa-check text-success"></span>
                  Click on button to download file</li>
                <li>
                  <span class="fa fa-check text-success"></span>
                  File will be download after button click</li>
              </ul>
            Click here to Download:
            <a class="cta-button" href="{{data.pathname}}" target="_blank" download="{{data.filename}}">
                <span class="text-success" tooltip-placement="bottom" tooltip="Click here to download PDF">Download</span>
            </a>
        </div>
    </div> -->
  <div class="row">
    <div class="col-xs-6">
      <ul class="list-unstyled">
        <li>
          <span class="fa fa-check text-success"></span> Click on button to
          download file
        </li>
        <li>
          <span class="fa fa-check text-success"></span> File will be download
          after button click
        </li>
        <li>
          <span class="fa fa-check text-success"></span> Save and Unzip
          downloaded file
        </li>
        <li>
          <span class="fa fa-check text-success"></span> Go to company folder
        </li>
        <li><span class="fa fa-check text-success"></span> Open pdf file</li>
        <li><span class="fa fa-check text-success"></span> Print</li>
      </ul>
      <br />
      <br />
      <br />
    </div>
    <div class="col-xs-6" style="float: right">
      <br />
      <br />
      <br />
      <div>
        <a
          class="cta-button"
          href="{{ data.pathname }}"
          target="_blank"
          download="{{ data.filename }}">
          <span
            class="text-success"
            tooltip-placement="bottom"
            tooltip="Click here to download PDF"
            >Download</span
          >
        </a>
      </div>
    </div>
  </div>
</h1>
