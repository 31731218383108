import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DbService } from 'src/app/core/services/db.service';

@Component({
  selector: 'app-view-user-dialog',
  templateUrl: './view-user-dialog.component.html',
  styleUrls: ['./view-user-dialog.component.scss'],
})
export class ViewUserDialogComponent implements OnInit {
  constructor(
    private dbService: DbService,
    @Inject(MAT_DIALOG_DATA) public data: { userCode: string }
  ) {}
  UserArray: any = {
    USER_CODE: null, //
    USER_TITLE: null, //
    USER_NAME: null, //
    USER_MIDDLE_NAME: null, //
    USER_LAST_NAME: null, //
    USER_TYPE: null, //
    USER_MOBILE: null, //
    USER_EMAIL: null, //
    USER_MANAGER: null, //
    USER_FROM_DATE: null, //
    USER_TO_DATE: null, //
    USER_URL: null, //
    USER_COMPANY_GROUP_ID: null, //
    USER_R_STATUS: null, //
    USER_PASSWORD: null,
    USER_PASSWORD_LIFE: null,
    USER_PASSWORD_CHANGE_DATE: null, //Managed by Code
    USER_LANDING_MENU_CODE: null, //
    USER_APP_CODE: null, //
    USER_CREATOR_ID: null, //Managed by Code
    USER_CREATED_DATE: null, //Managed by Code
    USER_MODIFIED_ID: null, //Managed by Code
    USER_MODIFIED_DATE: null, //Managed by Code
    USER_ACCESS: null,
    USER_UAC: null,
  };

  ngOnInit(): void {
    this.getUser();
  }

  getUser() {
    this.dbService
      .admin([this.data['userCode']], 'getUser')
      .subscribe((data: any) => {
        this.UserArray = data;
      });
  }
}
