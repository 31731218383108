import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import {
  MatDialogModule,
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialog,
} from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { first } from 'rxjs';
import { DbService } from 'src/app/core/services/db.service';

export interface DialogData {
  pathname: any;
  filename: any;
}

@Component({
  selector: 'app-generateallforms',
  templateUrl: './generateallforms.component.html',
  styleUrls: ['./generateallforms.component.scss'],
})
export class GenerateallformsComponent implements OnInit {
  allcompList: any[] = [];

  allforms = new FormGroup({
    company: new FormControl(''),
    txnno: new FormControl(''),
  });

  constructor(
    private dbservice: DbService,
    private http: HttpClient,
    public dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.allCompList();
  }

  allCompList() {
    this.dbservice
      .cpdoc([], 'allCompList')
      .pipe(first())
      .subscribe({
        next: (data: any) => {
          this.allcompList = data['res'];
          // console.log(this.mclrBankList);
        },
        error: (err: any) => {
          console.log(err);
        },
      });
  }

  data: any = [];
  spiner: any = '';
  generateAllPdf() {
    this.data = this.allforms.value;
    this.spiner = 'true';
    this.dbservice
      .cpdoc(this.data, 'generateAllPdf')
      .pipe(first())
      .subscribe({
        next: (data: any) => {
          if (data.status === true) {
            this.spiner = 'false';
            const pathTillHtdocs =
              window.location.protocol + '//' + window.location.host;
            const pathtofile =
              pathTillHtdocs +
              '/fsnxtbackend/uploads/formdocumentation/' +
              data['res'].file;
            this.dialog.open(AllPdfdialogComponent, {
              data: { pathname: pathtofile, filename: data['res'].file },
            });
          } else {
            /* dialog.modal('hide');
            $scope.errmsg = response.msg; */
          }
        },
        error: (err: any) => {
          console.log(err);
        },
      });
  }
}

@Component({
  selector: 'app-generateallpdf-dialog',
  templateUrl: 'generateallpdf-dialog.html',
  standalone: true,
  imports: [
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    MatButtonModule,
  ],
})
export class AllPdfdialogComponent {
  constructor(
    public dialogRef: MatDialogRef<AllPdfdialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {}

  onNoClick(): void {
    this.dialogRef.close();
  }
}
