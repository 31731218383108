<div id="header" class="mt-10 flex flex-col">
  <span class="font-lato text-2xl">User List</span>
  <span class="font-lato text-md py-2 text-gray-500"
    >Here you can find the list of all the users that you have added! You can
    click on the User Code for more information.</span
  >
</div>
<div
  id="userList"
  class="bg-white mt-4 py-1 rounded-lg mat-elevation-z2"
  *ngIf="showListing">
  <table mat-table [dataSource]="usersList">
    <!-- Position Column -->
    <ng-container matColumnDef="srno">
      <th mat-header-cell *matHeaderCellDef>#</th>
      <td mat-cell *matCellDef="let element; let i = index">
        {{ i + 1 }}
      </td>
    </ng-container>

    <!-- Name Column -->
    <ng-container matColumnDef="usercode">
      <th mat-header-cell *matHeaderCellDef>User Code</th>
      <td mat-cell *matCellDef="let element">
        <button
          (click)="viewRec('view', element)"
          class="text-blue-500 cursor-pointer">
          {{ element.user_code }}
        </button>
      </td>
    </ng-container>

    <!-- Weight Column -->
    <ng-container matColumnDef="username">
      <th mat-header-cell *matHeaderCellDef>User Name</th>
      <td mat-cell *matCellDef="let element">{{ element.user_name }}</td>
    </ng-container>

    <!-- Symbol Column -->
    <ng-container matColumnDef="useremail">
      <th mat-header-cell *matHeaderCellDef>User Email</th>
      <td mat-cell *matCellDef="let element">{{ element.user_email }}</td>
    </ng-container>

    <ng-container matColumnDef="usermobile">
      <th mat-header-cell *matHeaderCellDef>User Mobile</th>
      <td mat-cell *matCellDef="let element">{{ element.user_mobile }}</td>
    </ng-container>

    <ng-container matColumnDef="status">
      <th mat-header-cell *matHeaderCellDef>Status</th>
      <td mat-cell *matCellDef="let element">{{ element.rstatus }}</td>
    </ng-container>

    <!-- Symbol Column -->
    <ng-container matColumnDef="cid">
      <th mat-header-cell *matHeaderCellDef>Created ID</th>
      <td mat-cell *matCellDef="let element">{{ element.created_id }}</td>
    </ng-container>
    <!-- Symbol Column -->

    <ng-container matColumnDef="cdt">
      <th mat-header-cell *matHeaderCellDef>Created Date</th>
      <td mat-cell *matCellDef="let element">{{ element.created_dt }}</td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
</div>

<div class="row d-flex" *ngIf="showForm" #UserDetails>
  <form class="example-form" [formGroup]="usersform">
    <div class="bg-transparent py-2"></div>
    <span class="font-lato">User Code</span>
    <input
      readonly
      formControlName="usercode"
      placeholder="City"
      class="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg block w-1/2 p-2.5" />
    <div class="bg-transparent py-2"></div>
    <span class="font-lato">User Name</span>
    <input
      readonly
      formControlName="username"
      placeholder="City"
      class="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg block w-1/2 p-2.5" />
    <div class="bg-transparent py-2"></div>
    <span class="font-lato">User Mobile</span>
    <input
      readonly
      formControlName="usermobile"
      placeholder="City"
      class="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg block w-1/2 p-2.5" />
    <div class="bg-transparent py-2"></div>
    <span class="font-lato">User Email</span>
    <input
      readonly
      formControlName="useremail"
      placeholder="City"
      class="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg block w-1/2 p-2.5" />
    <div class="bg-transparent py-2"></div>
    <span class="font-lato">Landing Menu Code</span>
    <input
      readonly
      formControlName="lmenucode"
      placeholder="City"
      class="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg block w-1/2 p-2.5" />
    <div class="bg-transparent py-2"></div>

    <button
      (click)="HideForm()"
      type="button"
      class="mb-4 text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2">
      Go Back
    </button>
  </form>
</div>
