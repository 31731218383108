<div
  id="mainContainer"
  class="w-full h-full flex flex-col items-center justify-center">
  <span class="text-[5rem] font-lato font-semibold text-blue-800 mt-16"
    >404</span
  >
  <span class="text-[5rem] font-lato font-bold text-black mb-2"
    >Page Not Found</span
  >
  <span class="text-[1.5rem] font-lato py-2 text-center mb-5"
    >You either tried to access a page that does not exist, or a page that you
    dont have access to!
  </span>
  <span class="text-[1rem] font-lato text-gray-700 mb-7"
    >Contact the Admin for More information</span
  >
  <button
    (click)="logout()"
    type="button"
    class="w-1/3 text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2">
    Logout
  </button>
</div>
