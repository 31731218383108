export const environment = {
  production: true,
  firebaseConfig: {
    apiKey: 'AIzaSyDjuPp87G-DRVYEozt-pBK54yR8Ti5ny_U',
    authDomain: 'fs-nxt.firebaseapp.com',
    databaseURL: 'https://fs-nxt.firebaseio.com',
    projectId: 'fs-nxt',
    storageBucket: 'fs-nxt.appspot.com',
    messagingSenderId: '150608069622',
    appId: '1:150608069622:web:42858551caa43821c57dd7',
  },
};

export const menus = [
  {
    name: 'Control Panel',
    icon: 'setting.png',
    code: 'CONTROL',
    child: [
      {
        name: 'Company Group',
        routerlink: 'compcgrp',
      },
      {
        name: 'Company',
        routerlink: 'company',
      },
      {
        name: 'Users',
        routerlink: 'compcgrpuser',
      },
    ],
  },
  {
    name: 'CP Documentation',
    icon: 'writing.png',
    code: 'CP',
    child: [
      {
        name: 'Master Data',
        routerlink: 'docmast',
      },
      {
        name: 'Transaction Data',
        routerlink: 'trxndata',
      },
      {
        name: 'Reports',
        routerlink: 'frmgeneration',
      },
    ],
  },
  {
    name: 'CC Bank Statement',
    icon: 'writing.png',
    code: 'CC',
    child: [
      {
        name: 'Bank List',
        routerlink: 'companybanklist',
      },
      {
        name: 'Company Bank',
        routerlink: 'companybank',
      },
      {
        name: 'Statement Convertor',
        routerlink: 'statementconverter',
      },
      {
        name: 'CC Statement',
        routerlink: 'ccstmtdownload',
      },
    ],
  },
  {
    name: 'Mutual Funds',
    icon: 'mutual-funds.png',
    code: 'MF',
    child: [
      {
        name: 'MF Exposure Report',
        routerlink: 'mfexposure',
      },
    ],
  },
  {
    name: 'FRA Valuation',
    icon: 'money.png',
    code: 'FRA',
    child: [
      {
        name: 'FRA Dashboard',
        routerlink: 'fradashboard',
      },
      {
        name: 'FRA Upload',
        routerlink: 'fraupload',
      },
    ],
  },
  {
    name: 'Cost of Borrowing (COB)',
    icon: 'report.png',
    code: 'RPTS',
    child: [
      {
        name: 'Report',
        routerlink: 'cob',
      },
    ],
  },
  {
    name: 'Support Dashboard',
    icon: 'report.png',
    code: 'SUPPORTTRK',
    child: [
      {
        name: 'Support Tracker Report',
        routerlink: 'support-tracker/dashboard',
      },
      {
        name: 'COE Team Management',
        routerlink: 'support-tracker/coe-manager',
      },
    ],
  },
  {
    name: 'Admin Panel',
    icon: 'report.png',
    code: 'ADMIN',
    child: [
      {
        name: 'User Management',
        routerlink: 'user-manage',
      },
      {
        name: 'Menu Management',
        routerlink: 'menu-manage',
      },
    ],
  },
  {
    name: 'Payments',
    icon: 'writing.png',
    code: 'PAYMENTS',
    child: [
      {
        name: 'Payments',
        routerlink: 'payments',
      },
    ],
  },
  /* {
    name: 'Tracker Dashboard',
    icon: 'report.png',
    child: [
      {
        name: 'Reports',
        routerlink: 'tracker',
      },
    ],
  }, */
];

export const theme2 = [
  'rgb(43, 52, 103)',
  'rgb(186, 215, 233)',
  'rgb(252, 255, 231)',
  'rgb(235, 69, 95)',
];

export const theme1 = [
  'rgb(255, 120, 90)',
  'rgb(255, 240, 90)',
  'rgb(255, 170, 90)',
  'rgb(255, 210, 90)',
  'rgb(43, 52, 103)',
  'rgb(186, 215, 233)',
  'rgb(252, 255, 231)',
  'rgb(235, 69, 95)',
  'rgb(252, 231, 255)',
  'rgb(235, 95, 69)',
];
