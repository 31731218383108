import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { first } from 'rxjs';
import { DbService } from 'src/app/core/services/db.service';

@Component({
  selector: 'app-stmtconverter',
  templateUrl: './stmtconverter.component.html',
  styleUrls: ['./stmtconverter.component.scss'],
})
export class StmtconverterComponent implements OnInit {
  constructor(private dbservice: DbService) {}

  ngOnInit(): void {
    this.getStmtList();
  }

  stmtList: any = [];
  displayedColumns = [
    'company',
    'txnid',
    'accno',
    'bankcode',
    'bankname',
    'lastupload',
  ];
  showForm = false;
  showList = true;
  selectedFile: File | null = null;

  stmtForm = new FormGroup({
    company: new FormControl(''),
    bank: new FormControl(''),
    accno: new FormControl(''),
    txnid: new FormControl(''),
    uploadfile: new FormControl(''),
  });

  getStmtList() {
    this.dbservice
      .ccstmt([], 'getStmtList')
      .pipe(first())
      .subscribe({
        next: (data: any) => {
          this.stmtList = data['res'];
          // console.log(this.mclrBankList);
        },
        error: (err: any) => {
          console.log(err);
        },
      });
  }

  edit(data: any) {
    this.showForm = true;
    this.showList = false;
    this.stmtForm.patchValue({
      company: data.comp,
      bank: data.bank_code,
      accno: data.ac_no,
      txnid: data.ref,
    });
  }

  HideForm() {
    this.showForm = false;
    this.showList = true;
  }

  dataupload: any = [];
  onFileSelected(files: FileList | null) {
    if (files && files.length > 0) {
      this.selectedFile = files.item(0);
    } else {
      this.selectedFile = null;
    }
  }

  uploadmsg: any = '';
  processCCFile() {
    this.dataupload = this.stmtForm.value;
    if (this.selectedFile) {
      this.dbservice
        .uploadFile(this.selectedFile, 'uploadCCFile', this.dataupload)
        .subscribe(
          response => {
            this.uploadmsg = response.msg;
            this.stmtForm.reset();
            // Handle the response from the backend if needed
          },
          error => {
            console.error('Error uploading file:', error);
          }
        );
    }
  }
}
