import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatFormFieldDefaultOptions } from '@angular/material/form-field';
import { first } from 'rxjs';
import { DbService } from 'src/app/core/services/db.service';

@Component({
  selector: 'app-company',
  templateUrl: './company.component.html',
  styleUrls: ['./company.component.scss'],
})
export class CompanyComponent implements OnInit {
  compList: any[] = [];
  formAction = '';

  displayedColumns: any[] = [
    'srno',
    'cgrpid',
    'compcode',
    'compname',
    'cdt',
    'cid',
  ];

  constructor(
    private dbservice: DbService,
    private http: HttpClient
  ) {}

  companylist = new FormGroup({
    compCode: new FormControl(''),
    compName: new FormControl(''),
    compEmail: new FormControl(''),
    compUrl: new FormControl(''),
    compAddr1: new FormControl(''),
    compAddr2: new FormControl(''),
    compCountry: new FormControl(''),
    compState: new FormControl(''),
    compCity: new FormControl(''),
    compPostalcode: new FormControl(''),
  });

  appearance: MatFormFieldDefaultOptions = {
    appearance: 'outline',
  };

  cgrpList: any;
  showListing = true;
  showForm = false;

  ngOnInit(): void {
    this.getCompList();
  }

  getCompList() {
    this.dbservice
      .admin([], 'getCompList')
      .pipe(first())
      .subscribe({
        next: (data: any) => {
          this.compList = data['res'];
          this.showListing = true;
          // console.log(this.mclrBankList);
        },
        error: (err: any) => {
          console.log(err);
        },
      });
  }

  HideForm() {
    this.showForm = false;
    this.showListing = true;
    this.getCompList();
  }

  editRec(params: any) {
    this.showListing = false;
    this.showForm = true;
    this.formAction = 'view';
    this.companylist.patchValue({
      compCode: params.comp_code,
      compName: params.comp_name,
      compEmail: params.comp_email,
      compUrl: params.comp_url,
      compAddr1: params.comp_addr_line1,
      compAddr2: params.comp_addr_line2,
      compCountry: params.country_code,
      compState: params.state_code,
      compCity: params.city_code,
      compPostalcode: params.compPostalcode,
    });
  }
}
