import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatCardModule } from '@angular/material/card';
import { first } from 'rxjs';
import { DbService } from 'src/app/core/services/db.service';

@Component({
  selector: 'app-masterdata',
  templateUrl: './masterdata.component.html',
  styleUrls: ['./masterdata.component.scss'],
})
export class MasterdataComponent implements OnInit {
  constructor(
    private dbservice: DbService,
    private http: HttpClient
  ) {}
  compList: any = [];
  showListing = true;
  showlist = false;
  companylist: any;
  formAction: any;
  compMasterList: any = [];
  selectedCompany: any;

  ngOnInit(): void {
    this.getCompList();
  }

  masterDataList = new FormGroup({
    key: new FormControl(''),
    keylabel: new FormControl(''),
    keyvalue: new FormControl(''),
    action: new FormControl(''),
  });

  displayedColumns: any[] = ['srno', 'compname'];
  displayedColumnslist: any[] = ['key', 'desc', 'input', 'action'];

  getCompList() {
    this.showListing = true;
    this.showlist = false;
    this.dbservice
      .admin([], 'getCompList')
      .pipe(first())
      .subscribe({
        next: (data: any) => {
          this.compList = data['res'];
          this.showListing = true;
          // console.log(this.mclrBankList);
        },
        error: (err: any) => {
          console.log(err);
        },
      });
  }

  showCompanyDetails(data: any) {
    this.showListing = false;
    this.showlist = true;
    this.selectedCompany = data;
    this.dbservice
      .cpdoc(this.selectedCompany, 'getCompanyDetails')
      .pipe(first())
      .subscribe({
        next: (data: any) => {
          this.compMasterList = data['res'];
          // console.log(this.mclrBankList);
        },
        error: (err: any) => {
          console.log(err);
        },
      });
  }
  showForm = false;
  viewRec(action: any, data: any) {
    this.showForm = true;
    this.showListing = false;
    this.showlist = false;
    this.formAction = action;
    this.masterDataList.patchValue({
      key: data.key,
      keylabel: data.description,
      keyvalue: data.valtext,
    });
  }

  deleteCompBank(formAction: any, data: any) {
    this.showForm = true;
    this.showListing = false;
    this.showlist = false;
    this.formAction = formAction;
    this.masterDataList.patchValue({
      key: data.key,
      keylabel: data.description,
      keyvalue: data.valtext,
      action: this.formAction,
    });
  }

  HideForm() {
    this.showForm = false;
    this.showListing = false;
    this.showlist = true;
    this.getCompList();
  }

  editRec(formAction: any, data: any) {
    this.showForm = true;
    this.showListing = false;
    this.showlist = false;
    this.formAction = formAction;
    this.masterDataList.patchValue({
      key: data.key,
      keylabel: data.description,
      keyvalue: data.valtext,
      action: this.formAction,
    });
  }

  mdata: any = [];
  updateMasterData() {
    this.mdata = this.masterDataList.value;
    this.dbservice
      .cpdoc(this.mdata, 'updateMasterData')
      .pipe(first())
      .subscribe({
        next: (data: any) => {
          if (data['res'].status === 'success') {
            this.getCompList();
          }
          //this.compMasterList = data['res'];
          // console.log(this.mclrBankList);
        },
        error: (err: any) => {
          console.log(err);
        },
      });
  }
}
