import { DbService } from 'src/app/core/services/db.service';
import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { AddMenuDialogComponent } from './add-menu-dialog/add-menu-dialog.component';

@Component({
  selector: 'app-menu-management',
  templateUrl: './menu-management.component.html',
  styleUrls: ['./menu-management.component.scss'],
})
export class MenuManagementComponent implements OnInit {
  constructor(
    private dbService: DbService,
    private dialog: MatDialog
  ) {}
  displayedColumns: string[] = ['Menu Name', 'Menu Code'];
  menulist: any;

  ngOnInit(): void {
    //Get currrent menus from database
    this.getMenus();
  }

  getMenus() {
    this.dbService.admin([], 'getMenus').subscribe((data: any) => {
      this.menulist = data;
    });
  }

  openDialog() {
    const dialogConfig = new MatDialogConfig();

    // dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    this.dialog.open(AddMenuDialogComponent, dialogConfig);
  }
}
