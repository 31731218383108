import { Component, OnInit } from '@angular/core';
import {
  FormControl,
  FormGroup,
  FormBuilder,
  Validators,
} from '@angular/forms';
import { first } from 'rxjs';
import { DbService } from 'src/app/core/services/db.service';

@Component({
  selector: 'app-findata',
  templateUrl: './findata.component.html',
  styleUrls: ['./findata.component.scss'],
})
export class FindataComponent implements OnInit {
  constructor(
    private dbservice: DbService,
    private formBuilder: FormBuilder
  ) {}

  allcompList: any[] = [];
  periodList: any[] = [];
  findata: any[] = [];
  formGroup!: FormGroup;
  frmdata: any;
  colspan!: number;

  ngOnInit(): void {
    this.allCompList();
    this.periodList = [
      { key: '12', val: 'Yearly' },
      { key: '6', val: 'Half Yearly' },
      { key: '3', val: 'Quaterly' },
    ];
  }

  findatagroup = new FormGroup({
    fyyear: new FormControl('', Validators.required),
    period: new FormControl('', Validators.required),
    company: new FormControl('', Validators.required),
    eq_q1: new FormControl(''),
    eq_q2: new FormControl(''),
    eq_q3: new FormControl(''),
    eq_q4: new FormControl(''),
    eq_a1: new FormControl(''),
    pref_q1: new FormControl(''),
    pref_q2: new FormControl(''),
    pref_q3: new FormControl(''),
    pref_q4: new FormControl(''),
    pref_a1: new FormControl(''),
    networth_q1: new FormControl(''),
    networth_q2: new FormControl(''),
    networth_q3: new FormControl(''),
    networth_q4: new FormControl(''),
    networth_a1: new FormControl(''),
    inv_q1: new FormControl(''),
    inv_q2: new FormControl(''),
    inv_q3: new FormControl(''),
    inv_q4: new FormControl(''),
    inv_a1: new FormControl(''),
    dbout_q1: new FormControl(''),
    dbout_q2: new FormControl(''),
    dbout_q3: new FormControl(''),
    dbout_q4: new FormControl(''),
    dbout_a1: new FormControl(''),
    shterm_q1: new FormControl(''),
    shterm_q2: new FormControl(''),
    shterm_q3: new FormControl(''),
    shterm_q4: new FormControl(''),
    shterm_a1: new FormControl(''),
    debt_q1: new FormControl(''),
    debt_q2: new FormControl(''),
    debt_q3: new FormControl(''),
    debt_q4: new FormControl(''),
    debt_a1: new FormControl(''),
    income_q1: new FormControl(''),
    income_q2: new FormControl(''),
    income_q3: new FormControl(''),
    income_q4: new FormControl(''),
    income_a1: new FormControl(''),
    opprofit_q1: new FormControl(''),
    opprofit_q2: new FormControl(''),
    opprofit_q3: new FormControl(''),
    opprofit_q4: new FormControl(''),
    opprofit_a1: new FormControl(''),
    grprofit_q1: new FormControl(''),
    grprofit_q2: new FormControl(''),
    grprofit_q3: new FormControl(''),
    grprofit_q4: new FormControl(''),
    grprofit_a1: new FormControl(''),
    netprofit_q1: new FormControl(''),
    netprofit_q2: new FormControl(''),
    netprofit_q3: new FormControl(''),
    netprofit_q4: new FormControl(''),
    netprofit_a1: new FormControl(''),
  });

  getPastFinancialYears(): string[] {
    const currentYear = new Date().getFullYear();
    const startingYear = currentYear - 10;
    const years: string[] = [];

    for (let year = startingYear; year < currentYear; year++) {
      const formattedYear = `FY ${year}-${year + 1}`;
      years.push(formattedYear);
    }

    return years;
  }

  allCompList() {
    this.dbservice
      .cpdoc([], 'allCompList')
      .pipe(first())
      .subscribe({
        next: (data: any) => {
          this.allcompList = data['res'];
          // console.log(this.mclrBankList);
        },
        error: (err: any) => {
          console.log(err);
        },
      });
  }
  finsmttbl = false;
  cbtn = false;
  ubtn = true;
  rbtn = true;
  dbtn = true;
  col1 = false;
  col2 = false;
  col3 = false;
  col4 = false;
  col5 = false;
  formAction = '';

  finData: any = [];

  showgrid = false;
  q1: any = '';
  q2: any = '';
  q3: any = '';
  q4: any = '';
  a1: any = '';
  DisplayFinancialFrm() {
    this.finsmttbl = true;
    this.finData = this.findatagroup.value;
    this.dbservice
      .cpdoc(this.finData, 'DisplayFinancialFrm')
      .pipe(first())
      .subscribe({
        next: (data: any) => {
          //this.finDataList = data['res'];
          if (data['status'] == 'success') {
            this.cbtn = false;
            this.ubtn = true;
            this.dbtn = true;
            this.formAction = 'Update';
            if (data['frequency'] === '3') {
              this.col1 = true;
              this.col2 = true;
              this.col3 = true;
              this.col4 = true;
            }
            if (data['frequency'] === '12') {
              this.col5 = true;
            }
          } else {
            this.cbtn = true;
            this.ubtn = false;
            this.dbtn = false;
            this.formAction = 'Create';
          }

          this.q1 = JSON.parse(data['res'].q1);
          this.q2 = JSON.parse(data['res'].q2);
          this.q3 = JSON.parse(data['res'].q3);
          this.q4 = JSON.parse(data['res'].q4);
          this.a1 = JSON.parse(data['res'].a1);

          this.findatagroup.patchValue({
            eq_q1: this.q1.eq_q,
            eq_q2: this.q2.eq_q,
            eq_q3: this.q3.eq_q,
            eq_q4: this.q4.eq_q,
            eq_a1: this.a1.eq_q,

            pref_q1: this.q1.pre_shr_q,
            pref_q2: this.q2.pre_shr_q,
            pref_q3: this.q3.pre_shr_q,
            pref_q4: this.q4.pre_shr_q,
            pref_a1: this.a1.pre_shr_q,

            networth_q1: this.q1.net_wrt_q,
            networth_q2: this.q2.net_wrt_q,
            networth_q3: this.q3.net_wrt_q,
            networth_q4: this.q4.net_wrt_q,
            networth_a1: this.a1.net_wrt_q,

            inv_q1: this.q1.inv_subs_q,
            inv_q2: this.q2.inv_subs_q,
            inv_q3: this.q3.inv_subs_q,
            inv_q4: this.q4.inv_subs_q,
            inv_a1: this.a1.inv_subs_q,

            dbout_q1: this.q1.tot_dbt_ost_q,
            dbout_q2: this.q2.tot_dbt_ost_q,
            dbout_q3: this.q3.tot_dbt_ost_q,
            dbout_q4: this.q4.tot_dbt_ost_q,
            dbout_a1: this.a1.tot_dbt_ost_q,

            shterm_q1: this.q1.shrt_trm_q,
            shterm_q2: this.q2.shrt_trm_q,
            shterm_q3: this.q3.shrt_trm_q,
            shterm_q4: this.q4.shrt_trm_q,
            shterm_a1: this.a1.shrt_trm_q,

            debt_q1: this.q1.othr_dbt_q,
            debt_q2: this.q2.othr_dbt_q,
            debt_q3: this.q3.othr_dbt_q,
            debt_q4: this.q4.othr_dbt_q,
            debt_a1: this.a1.othr_dbt_q,

            income_q1: this.q1.grs_inc_q,
            income_q2: this.q2.grs_inc_q,
            income_q3: this.q3.grs_inc_q,
            income_q4: this.q4.grs_inc_q,
            income_a1: this.a1.grs_inc_q,

            opprofit_q1: this.q1.opr_profit_q,
            opprofit_q2: this.q2.opr_profit_q,
            opprofit_q3: this.q3.opr_profit_q,
            opprofit_q4: this.q4.opr_profit_q,
            opprofit_a1: this.a1.opr_profit_q,

            grprofit_q1: this.q1.grs_profit_q,
            grprofit_q2: this.q2.grs_profit_q,
            grprofit_q3: this.q3.grs_profit_q,
            grprofit_q4: this.q4.grs_profit_q,
            grprofit_a1: this.a1.grs_profit_q,

            netprofit_q1: this.q1.net_profit_q,
            netprofit_q2: this.q2.net_profit_q,
            netprofit_q3: this.q3.net_profit_q,
            netprofit_q4: this.q4.net_profit_q,
            netprofit_a1: this.a1.net_profit_q,
          });
        },
        error: (err: any) => {
          console.log(err);
        },
      });

    this.showgrid = true;
    this.cbtn = true;
  }

  finDataList: any[] = [];
  finstmtCRUD(formAction: any) {
    this.finData = this.findatagroup.value;
    this.formAction = formAction;
    this.finData.formAction = formAction;
    this.dbservice
      .cpdoc(this.finData, 'finstmtCRUD')
      .pipe(first())
      .subscribe({
        next: (data: any) => {
          this.finDataList = data['res'];
        },
        error: (err: any) => {
          console.log(err);
        },
      });
  }
}
