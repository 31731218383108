<div id="header" class="mt-10 flex flex-col">
  <span class="font-lato text-2xl">Generate Reports</span>
  <span class="font-lato text-md py-2 text-gray-500"
    >Generate Reports for Transactions and Download Documents.</span
  >
</div>

<!-- Form Generation Input -->
<form
  [formGroup]="getfrmData"
  (ngSubmit)="getTxnInfo()"
  class="flex flex-row items-end mt-3 w-full">
  <div #companyInput class="mr-2 flex-1">
    <label for="company" class="font-lato">Company</label>
    <br />
    <select
      id="company"
      formControlName="company"
      class="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5">
      <option *ngFor="let comp of allcompList" [value]="comp">
        {{ comp.comp_code }} - {{ comp.comp_name }}
      </option>
    </select>
  </div>
  <br />
  <div #formTypeInput class="mr-2 flex-1">
    <label for="formtypeList" class="font-lato">Form Type.</label>
    <br />
    <select
      id="formtypeList"
      formControlName="cpfrmtype"
      class="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg block p-2.5 w-full">
      <option *ngFor="let frname of formtypeList" [value]="frname">
        {{ frname.frmname }}
      </option>
    </select>
  </div>
  <br />
  <div #transactionNumber class="mr-2 flex-1">
    <label for="txn_no" class="font-lato">Transaction No.</label>
    <br />
    <input
      id="txn_no"
      class="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg block p-2.5 w-full"
      formControlName="txnno"
      required
      #fromDate />
  </div>
  <br />
  <button
    (click)="({})"
    type="button"
    class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-20 py-2.5">
    Submit
  </button>
</form>
<div
  *ngIf="!isFormGenerated"
  class="w-full h-10 flex flex-col items-center my-12">
  <span class="font-lato text-2xl">Enter the details to generate a form.</span>
</div>

<!-- Generated Form Table  -->
<div
  id="generatedFormsTable"
  class="bg-white mt-4 pb-1 rounded-lg mat-elevation-z2"
  *ngIf="isFormGenerated">
  <div class="py-3 px-3">
    <span id="name" class="font-lato">Forms Generated</span>
  </div>
  <hr />

  <table mat-table [dataSource]="TxnInfoList" class="w-full">
    <ng-container matColumnDef="txnno">
      <th mat-header-cell *matHeaderCellDef>Txn No.</th>
      <td mat-cell *matCellDef="let element">{{ element.txn_no }}</td>
    </ng-container>
    <ng-container matColumnDef="classid">
      <th mat-header-cell *matHeaderCellDef>Class ID</th>
      <td mat-cell *matCellDef="let element">{{ element.classid }}</td>
    </ng-container>
    <ng-container matColumnDef="isin">
      <th mat-header-cell *matHeaderCellDef>ISIN</th>
      <td mat-cell *matCellDef="let element">{{ element.isin }}</td>
    </ng-container>
    <ng-container matColumnDef="issueamt">
      <th mat-header-cell *matHeaderCellDef>Issue Amount</th>
      <td mat-cell *matCellDef="let element">{{ element.issue_amt }}</td>
    </ng-container>
    <ng-container matColumnDef="issuedate">
      <th mat-header-cell *matHeaderCellDef>Issue Date</th>
      <td mat-cell *matCellDef="let element">{{ element.issue_date }}</td>
    </ng-container>
    <ng-container matColumnDef="facevalue">
      <th mat-header-cell *matHeaderCellDef>Face Value</th>
      <td mat-cell *matCellDef="let element">{{ element.issue_date }}</td>
    </ng-container>
    <ng-container matColumnDef="matdate">
      <th mat-header-cell *matHeaderCellDef>Maturity Date</th>
      <td mat-cell *matCellDef="let element">{{ element.issue_date }}</td>
    </ng-container>
    <ng-container matColumnDef="ipa">
      <th mat-header-cell *matHeaderCellDef>IPA</th>
      <td mat-cell *matCellDef="let element">{{ element.issue_date }}</td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
</div>

<!-- Document Table  -->
<div id="documents" class="bg-white mt-4 rounded-lg mat-elevation-z2 pb-1">
  <div class="py-3 px-3">
    <span id="name" class="font-lato">Documents Available</span>
  </div>
  <hr />

  <table mat-table [dataSource]="FormsTypeList" class="w-full">
    <ng-container matColumnDef="srno" class="px-8">
      <th mat-header-cell *matHeaderCellDef>#</th>
      <td mat-cell *matCellDef="let element; let i = index">
        {{ i + 1 }}
      </td>
    </ng-container>
    <ng-container matColumnDef="type">
      <th mat-header-cell *matHeaderCellDef>Type</th>
      <td mat-cell *matCellDef="let element">{{ element.fcode }}</td>
    </ng-container>

    <ng-container matColumnDef="document">
      <th mat-header-cell *matHeaderCellDef>Document</th>
      <td mat-cell *matCellDef="let element">{{ element.doc_name }}</td>
    </ng-container>

    <ng-container matColumnDef="pdf">
      <th mat-header-cell *matHeaderCellDef>PDF</th>
      <td mat-cell *matCellDef="let element">
        <a
          href="javascript::"
          class=""
          (click)="preGeneratePDF(element, 'pdf')">
          <i class="fa-solid fa-file-pdf"></i
        ></a>
      </td>
    </ng-container>
    <ng-container matColumnDef="doc">
      <th mat-header-cell *matHeaderCellDef>Doc</th>
      <td mat-cell *matCellDef="let element">
        <a
          href="javascript::"
          class=""
          (click)="preGeneratePDF(element, 'doc')">
          <i class="fa-solid fa-file-word"></i>
        </a>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumnsf"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumnsf"></tr>
  </table>
</div>
<div class="bg-transparent py-2"></div>

<!-- <div class="container" style="border: 1px solid;">
            <div class="left-pane" style="width:65%">
                <div id="header" class="flex flex-row justify-between items-center mx-3 mb-2 md:mb-0">
                    <div id="name" class="flex flex-row items-center font-extrabold text-gray-500 content-heading">
                        <i class="fa-solid fa-list"></i> Generate Form
                    </div>
                </div>
                <div id="mclr-comparisons" class="bg-white w-1/2 !max-h-full p-5 rounded-2xl mx-3 mb-2 md:mb-0">

                    <div class="row d-flex">
                        <form [formGroup]="getfrmData" (ngSubmit)="getTxnInfo()">
                            <div class="row">
                                <mat-form-field appearance="fill" class="frminp">
                                    <mat-label>Company</mat-label>
                                    <mat-select formControlName="company">
                                        <mat-option *ngFor="let comp of allcompList" [value]="comp">
                                        {{ comp.comp_code }} - {{ comp.comp_name }}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                            <div class="row">
                            <mat-form-field appearance="fill" class="frminp">
                                <mat-label>Form Type.</mat-label>
                                <mat-select formControlName="cpfrmtype">
                                <mat-option *ngFor="let frname of formtypeList" [value]="frname">
                                    {{ frname.frmname }}
                                </mat-option>
                                </mat-select>
                            </mat-form-field>
                            </div>
                            <div class="row">
                            <mat-form-field appearance="fill" class="frminp">
                                <mat-label>Transaction No.</mat-label>
                                <input matInput formControlName="txnno" required #fromDate>
                            </mat-form-field>
                            </div>
                            <section>
                                <div class="cta-button-row">
                                <button mat-raised-button class="cta-button">
                                    Submit
                                </button>
                                </div>
                            </section>
                        </form>
                        <div class="row">
                            <table mat-table [dataSource]="TxnInfoList" class="mat-elevation-z8">
                                <ng-container matColumnDef="txnno">
                                    <th mat-header-cell *matHeaderCellDef> Txn No. </th>
                                    <td mat-cell *matCellDef="let element"> {{element.txn_no}} </td>
                                </ng-container>
                                <ng-container matColumnDef="classid">
                                    <th mat-header-cell *matHeaderCellDef> Class ID </th>
                                    <td mat-cell *matCellDef="let element"> {{element.class_id}} </td>
                                </ng-container>
                                <ng-container matColumnDef="isin">
                                    <th mat-header-cell *matHeaderCellDef> ISIN </th>
                                    <td mat-cell *matCellDef="let element"> {{element.isin}} </td>
                                </ng-container>
                                <ng-container matColumnDef="issueamt">
                                    <th mat-header-cell *matHeaderCellDef> Class ID </th>
                                    <td mat-cell *matCellDef="let element"> {{element.issue_amt}} </td>
                                </ng-container>
                                <ng-container matColumnDef="issuedate">
                                    <th mat-header-cell *matHeaderCellDef> Issue Date </th>
                                    <td mat-cell *matCellDef="let element"> {{element.issue_date}} </td>
                                </ng-container>
                                <ng-container matColumnDef="facevalue">
                                    <th mat-header-cell *matHeaderCellDef> Face Value </th>
                                    <td mat-cell *matCellDef="let element"> {{element.issue_date}} </td>
                                </ng-container>
                                <ng-container matColumnDef="matdate">
                                    <th mat-header-cell *matHeaderCellDef> Maturity Date </th>
                                    <td mat-cell *matCellDef="let element"> {{element.issue_date}} </td>
                                </ng-container>
                                <ng-container matColumnDef="ipa">
                                    <th mat-header-cell *matHeaderCellDef> IPA </th>
                                    <td mat-cell *matCellDef="let element"> {{element.issue_date}} </td>
                                </ng-container>

                                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                            </table>
                        </div> 
                    </div>
                </div>
            </div>
            <div class="right-pane">
                <div id="header" class="flex flex-row justify-between items-center mx-3 mb-2 md:mb-0">
                    <div id="name" class="flex flex-row items-center font-extrabold text-gray-500 content-heading">
                        <i class="fa-solid fa-list"></i> Documents
                    </div>
                </div>
                <div id="mclr-comparisons" class="bg-white w-1/2 !max-h-full p-5 rounded-2xl mx-3 mb-2 md:mb-0">

                    <div class="row d-flex">
                        <table mat-table [dataSource]="FormsTypeList" class="mat-elevation-z8" style="width:500px;" >
                            <ng-container matColumnDef="srno" class="px-8">
                                <th mat-header-cell *matHeaderCellDef>#</th>
                                <td mat-cell *matCellDef="let element; let i = index">
                                  {{ i + 1 }}
                                </td>
                              </ng-container>
                            <ng-container matColumnDef="type">
                            <th mat-header-cell *matHeaderCellDef> Type </th>
                            <td mat-cell *matCellDef="let element"> {{element.fcode}} </td>
                            </ng-container>

                            <ng-container matColumnDef="document">
                                <th mat-header-cell *matHeaderCellDef> Document </th>
                                <td mat-cell *matCellDef="let element"> {{element.doc_name}} </td>
                            </ng-container>

                            <ng-container matColumnDef="pdf">
                                <th mat-header-cell *matHeaderCellDef> PDF </th>
                                <td mat-cell *matCellDef="let element">
                                     <a href="javascript::" class="" (click)="preGeneratePDF(element, 'pdf')">
                                        <i class="fa-solid fa-file-pdf"></i></a>
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="doc">
                                <th mat-header-cell *matHeaderCellDef> Doc </th>
                                <td mat-cell *matCellDef="let element"> 
                                    <a href="javascript::" class="" (click)="preGeneratePDF(element, 'doc')">
                                        <i class="fa-solid fa-file-word"></i>
                                  </a></td>
                            </ng-container>

                            <tr mat-header-row *matHeaderRowDef="displayedColumnsf"></tr>
                            <tr mat-row *matRowDef="let row; columns: displayedColumnsf;"></tr>
                        </table>
                    </div>
                </div>
            </div>
        </div> -->
