<div id="dialogContainer" class="px-5 py-10 w-[1000px]">
  <div class="flex flex-row justify-between mb-10">
    <div class="flex flex-row items-center">
      <span class="font-lato text-xl"
        >Updating User : {{ data['userCode'] }}</span
      >
    </div>
    <div class="flex flex-row items-center">
      <button
        (click)="updateUser()"
        type="button"
        class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-5">
        Update User Details
      </button>
      <img
        class="w-[25px] h-[25px] hover:opacity-25 duration-300"
        src="assets/icons/close.png"
        alt=""
        mat-dialog-close="" />
    </div>
  </div>
  <div id="inputContainer" class="flex flex-row w-full">
    <div id="leftPanel" class="flex-1">
      <span>Required Fields</span>
      <div class="flex flex-row w-full justify-between">
        <div #UserCode class="mr-2 flex-1">
          <label for="user_code" class="font-lato">User Code</label>
          <br />
          <input
            id="user_code"
            class="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg block p-2.5 w-full"
            [(ngModel)]="UserArray['USER_CODE']"
            readonly />
        </div>
        <div class="mr-2 flex-1">
          <label for="user_type" class="font-lato">User Type</label>
          <br />
          <select
            id="user_type"
            [(ngModel)]="UserArray['USER_TYPE']"
            class="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg block p-2.5 w-full">
            <option value="U">User</option>
            <option value="A">Admin</option>
          </select>
        </div>
        <div class="mr-2 flex-1">
          <label for="user_status" class="font-lato">User Status</label>
          <br />
          <select
            id="user_status"
            [(ngModel)]="UserArray['USER_R_STATUS']"
            class="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg block p-2.5 w-full">
            <option value="A">Active</option>
            <option value="X">Inactive</option>
          </select>
        </div>
      </div>
      <div id="nameContainer" class="flex flex-row">
        <div class="mr-2 w-[5rem]">
          <label for="prefix" class="font-lato">Prefix</label>
          <br />
          <select
            id="prefix"
            [(ngModel)]="UserArray['USER_TITLE']"
            name="---"
            class="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg block p-2.5">
            <option value="Mr.">Mr.</option>
            <option value="Miss">Miss</option>
            <option value="Mrs.">Mrs.</option>
          </select>
        </div>
        <div class="mr-2 flex-1">
          <label for="firstName" class="font-lato">First Name</label>
          <br />
          <input
            id="firstName"
            class="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg block p-2.5 w-full"
            [(ngModel)]="UserArray['USER_NAME']"
            required />
        </div>

        <div class="mr-2 flex-1">
          <label for="lastName" class="font-lato">Last Name</label>
          <br />
          <input
            id="lastName"
            class="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg block p-2.5 w-full"
            [(ngModel)]="UserArray['USER_LAST_NAME']"
            required />
        </div>
      </div>
      <div #UserEmail class="mr-2 flex-1">
        <label for="email" class="font-lato">Email</label>
        <br />
        <input
          id="email"
          class="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg block p-2.5 w-full"
          [(ngModel)]="UserArray['USER_EMAIL']"
          required />
      </div>

      <div #UserCompanyGrpID class="mr-2 flex-1">
        <label for="cgrp_id" class="font-lato">Company Group ID</label>
        <br />
        <input
          id="cgrp_id"
          class="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg block p-2.5 w-full"
          [(ngModel)]="UserArray['USER_COMPANY_GROUP_ID']"
          required />
      </div>
      <div id="validityContainer" class="flex flex-row w-full">
        <div appearance="fill" class="flex-1 mr-2">
          <label for="valid-from" class="label-title">Valid From</label>
          <br />
          <input
            id="valid-from"
            type="date"
            [(ngModel)]="UserArray['USER_FROM_DATE']"
            class="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg w-full p-2.5" />
        </div>
        <div appearance="fill" class="flex-1 mr-2">
          <label for="valid-to" class="label-title">Valid To</label>
          <br />
          <input
            id="valid-to"
            type="date"
            [(ngModel)]="UserArray['USER_TO_DATE']"
            class="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg w-full p-2.5" />
        </div>
      </div>
      <div #UserPhoneNo class="mr-2 flex-1">
        <label for="phoneNo" class="font-lato">Phone Number</label>
        <br />
        <input
          id="phoneNo"
          class="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg block p-2.5 w-full"
          [(ngModel)]="UserArray['USER_MOBILE']"
          required />
      </div>
    </div>
    <div id="right" class="flex-1">
      <span>Additional Fields</span>
      <div #UserManager class="mr-2 flex-1">
        <label for="usermanager" class="font-lato">User Manager</label>
        <br />
        <input
          id="usermanager"
          class="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg block p-2.5 w-full"
          [(ngModel)]="UserArray['USER_MANAGER']"
          required />
      </div>
      <div #UserUrl class="mr-2 flex-1">
        <label for="userurl" class="font-lato">User URL</label>
        <br />
        <input
          id="userurl"
          class="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg block p-2.5 w-full"
          [(ngModel)]="UserArray['USER_URL']"
          required />
      </div>
      <div #UserLanding class="mr-2 flex-1">
        <label for="userlandingmenucode" class="font-lato"
          >User Landing Menu Code</label
        >
        <br />
        <input
          id="userlandingmenucode"
          class="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg block p-2.5 w-full"
          [(ngModel)]="UserArray['USER_LANDING_MENU_CODE']"
          required />
      </div>

      <div #UserPassword class="mr-2 flex-1">
        <label for="password" class="font-lato">Password</label>
        <br />
        <input
          id="password"
          class="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg block p-2.5 w-full"
          [(ngModel)]="newPassword"
          required />
      </div>
      <button
        (click)="updatePassword()"
        type="button"
        class="text-white w-full mt-3 bg-blue-500 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2">
        Update Password
      </button>
    </div>
  </div>
</div>
