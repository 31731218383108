<div id="header" class="mt-10 flex flex-col">
  <span class="font-lato text-2xl">Transaction Data</span>
  <span class="font-lato text-md py-2 text-gray-500"
    >What do i even put here</span
  >
</div>

<div id="mclr-download" class="">
  <div class="row d-flex">
    <form class="example-form">
      <div #tabs class="bg-white rounded-md mat-elevation-z2">
        <mat-tab-group animationDuration="10ms">
          <mat-tab label="Data Migration">
            <div
              id="table"
              class="max-w-full max-h-full pb-3 overflow-scroll lg:overflow-hidden mclrpading">
              <form
                [formGroup]="txndataupload"
                (ngSubmit)="upload()"
                class="flex flex-row items-end w-full">
                <form-field appearance="fill" class="w-1/5 px-2">
                  <label for="statement" class="label-title">Form Type.</label>
                  <br />
                  <select
                    id="statement"
                    formControlName="statement"
                    class="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg w-full p-2.5">
                    <option *ngFor="let stmt of statementList" [value]="stmt">
                      {{ stmt.value }}
                    </option>
                  </select>
                </form-field>

                <form-field appearance="fill" class="w-1/5 px-2">
                  <label for="company" class="label-title">Company</label>
                  <br />
                  <select
                    id="company"
                    formControlName="company"
                    class="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg w-full p-2.5">
                    <option *ngFor="let comp of allcompList" [value]="comp">
                      {{ comp.comp_code }} - {{ comp.comp_name }}
                    </option>
                  </select>
                </form-field>

                <form-field appearance="fill" class="w-1/5 px-2">
                  <label for="date" class="label-title">Date</label>
                  <br />
                  <input
                    id="date"
                    type="date"
                    formControlName="udate"
                    required
                    #udate
                    class="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg w-full p-2.5" />
                </form-field>

                <form-field appearance="fill" style="display: none">
                  <input
                    placeholder="Choose file"
                    readonly
                    [value]="selectedFile?.name"
                    class="visually-hidden py-2" />
                </form-field>
                <div class="file-input-container">
                  <input
                    type="file"
                    #fileInput
                    (change)="onFileSelected(fileInput.files)"
                    class="file-input py-2" />
                </div>

                <button
                  (click)="({})"
                  type="button"
                  class="text-white h-1/2 bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2">
                  Search
                </button>
              </form>
            </div>
          </mat-tab>
          <mat-tab label="Data Display">
            <div
              #inputcontainer
              class="flex flex-row justify-between px-2 items-end mb-3">
              <form
                [formGroup]="txndatadisplay"
                (ngSubmit)="displayCpData()"
                class="flex flex-row items-center">
                <div>
                  <label for="statement-2" class="font-lato">Form Type</label>
                  <br />
                  <select
                    id="statement-2"
                    formControlName="statement"
                    class="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg block w-[12rem] p-2.5">
                    <option *ngFor="let stmt of statementList" [value]="stmt">
                      {{ stmt.value }}
                    </option>
                  </select>
                </div>
                <br />
                <div class="px-2">
                  <label for="company-2" class="font-lato">Company</label>
                  <br />
                  <select
                    id="company-2"
                    formControlName="company"
                    class="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg block w-[12rem] p-2.5">
                    <option *ngFor="let comp of allcompList" [value]="comp">
                      {{ comp.comp_code }} - {{ comp.comp_name }}
                    </option>
                  </select>
                </div>
                <br />
                <div class="px-2">
                  <label for="date-2" class="label-title">From Date</label>
                  <br />
                  <input
                    id="date-2"
                    type="date"
                    formControlName="frmdate"
                    required
                    #fromDate
                    class="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg w-full p-2.5" />
                </div>
                <br />
                <div>
                  <label for="date-3" class="label-title">To Date</label>
                  <br />
                  <input
                    id="date-3"
                    type="date"
                    formControlName="todate"
                    required
                    #fromDate
                    class="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg w-full p-2.5" />
                </div>
              </form>
              <button
                (click)="({})"
                type="button"
                class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-12 py-2.5 mr-2">
                Submit
              </button>
            </div>

            <div *ngIf="false">
              <table mat-table [dataSource]="cpdataList">
                <!-- Position Column -->
                <ng-container matColumnDef="srno" class="px-8">
                  <th mat-header-cell *matHeaderCellDef>#</th>
                  <td mat-cell *matCellDef="let element; let i = index">
                    {{ i + 1 }}
                  </td>
                </ng-container>
                <ng-container matColumnDef="date">
                  <th mat-header-cell *matHeaderCellDef>Date</th>
                  <td mat-cell *matCellDef="let element">
                    {{ element.dt }}
                  </td>
                </ng-container>
                <ng-container matColumnDef="classid">
                  <th mat-header-cell *matHeaderCellDef>Class Id</th>
                  <td mat-cell *matCellDef="let element">
                    {{ element.classid }}
                  </td>
                </ng-container>
                <ng-container matColumnDef="txnno">
                  <th mat-header-cell *matHeaderCellDef>Txn No.</th>
                  <td mat-cell *matCellDef="let element">
                    {{ element.txn_no }}
                  </td>
                </ng-container>
                <ng-container matColumnDef="isin">
                  <th mat-header-cell *matHeaderCellDef>ISIN</th>
                  <td mat-cell *matCellDef="let element">
                    {{ element.isin }}
                  </td>
                </ng-container>
                <ng-container matColumnDef="units">
                  <th mat-header-cell *matHeaderCellDef>No.Units</th>
                  <td mat-cell *matCellDef="let element">
                    {{ element.no_units }}
                  </td>
                </ng-container>
                <ng-container matColumnDef="issueamt">
                  <th mat-header-cell *matHeaderCellDef>Issue Amount</th>
                  <td mat-cell *matCellDef="let element">
                    {{ element.issue_amt }}
                  </td>
                </ng-container>
                <ng-container matColumnDef="cid">
                  <th mat-header-cell *matHeaderCellDef>Created ID</th>
                  <td mat-cell *matCellDef="let element">
                    {{ element.cid }}
                  </td>
                </ng-container>
                <ng-container matColumnDef="cdt">
                  <th mat-header-cell *matHeaderCellDef>Created Date</th>
                  <td mat-cell *matCellDef="let element">
                    {{ element.c_dt }}
                  </td>
                </ng-container>
                <ng-container matColumnDef="action">
                  <th mat-header-cell *matHeaderCellDef>Action</th>
                  <td mat-cell *matCellDef="let element">
                    {{ element.c_dt }}
                  </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displaytxndata"></tr>
                <tr mat-row *matRowDef="let row; columns: displaytxndata"></tr>
              </table>
            </div>
          </mat-tab>
          <mat-tab label="Data Deletion">
            <div
              #inputcontainer
              class="flex flex-row justify-between px-2 items-end pb-3">
              <form
                [formGroup]="txndatadisplay"
                (ngSubmit)="deleteCpData()"
                class="flex flex-row items-center">
                <div>
                  <label for="statement-3" class="font-lato">Form Type</label>
                  <br />
                  <select
                    id="statement-3"
                    formControlName="statement"
                    class="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg block w-[12rem] p-2.5">
                    <option *ngFor="let stmt of statementList" [value]="stmt">
                      {{ stmt.value }}
                    </option>
                  </select>
                </div>
                <br />
                <div class="px-2">
                  <label for="company-3" class="font-lato">Company</label>
                  <br />
                  <select
                    id="company-3"
                    formControlName="company"
                    class="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg block w-[12rem] p-2.5">
                    <option *ngFor="let comp of allcompList" [value]="comp">
                      {{ comp.comp_code }} - {{ comp.comp_name }}
                    </option>
                  </select>
                </div>
                <br />
                <div class="px-2">
                  <label for="date-4" class="label-title">From Date</label>
                  <br />
                  <input
                    id="date-4"
                    type="date"
                    formControlName="frmdate"
                    required
                    #fromDate
                    class="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg w-full p-2.5" />
                </div>
                <br />
                <div>
                  <label for="date-5" class="label-title">To Date</label>
                  <br />
                  <input
                    id="date-5"
                    type="date"
                    formControlName="todate"
                    required
                    #fromDate
                    class="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg w-full p-2.5" />
                </div>
              </form>
              <button
                (click)="({})"
                type="button"
                class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-12 py-2.5 mr-2">
                Submit
              </button>
            </div>
          </mat-tab>
          <mat-tab label="Migration Logs">
            <div class="bg-white pb-1 rounded-lg mat-elevation-z2">
              <table mat-table [dataSource]="migrationLog">
                <!-- Position Column -->
                <ng-container matColumnDef="srno" class="px-8">
                  <th mat-header-cell *matHeaderCellDef>#</th>
                  <td mat-cell *matCellDef="let element; let i = index">
                    {{ i + 1 }}
                  </td>
                </ng-container>
                <ng-container matColumnDef="date">
                  <th mat-header-cell *matHeaderCellDef>Date</th>
                  <td mat-cell *matCellDef="let element">
                    {{ element.logdt }}
                  </td>
                </ng-container>
                <ng-container matColumnDef="company">
                  <th mat-header-cell *matHeaderCellDef>Company</th>
                  <td mat-cell *matCellDef="let element">
                    {{ element.comp_code }}
                  </td>
                </ng-container>
                <ng-container matColumnDef="statement">
                  <th mat-header-cell *matHeaderCellDef>Statement</th>
                  <td mat-cell *matCellDef="let element">
                    {{ element.stmt }}
                  </td>
                </ng-container>
                <ng-container matColumnDef="user">
                  <th mat-header-cell *matHeaderCellDef>User Name</th>
                  <td mat-cell *matCellDef="let element">
                    {{ element.uid }}
                  </td>
                </ng-container>
                <ng-container matColumnDef="filename">
                  <th mat-header-cell *matHeaderCellDef>File Name</th>
                  <td mat-cell *matCellDef="let element">
                    {{ element.filename }}
                  </td>
                </ng-container>
                <ng-container matColumnDef="errorlog">
                  <th mat-header-cell *matHeaderCellDef>Error Log</th>
                  <td mat-cell *matCellDef="let element">
                    {{ element.err_msg }}
                  </td>
                </ng-container>
                <!-- <ng-container matColumnDef="action">
                                    <th mat-header-cell *matHeaderCellDef> Action </th>
                                    <td mat-cell *matCellDef="let element"> {{element.err_msg}} </td>
                                </ng-container> -->

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr
                  mat-row
                  *matRowDef="let row; columns: displayedColumns"></tr>
              </table>
            </div>
          </mat-tab>
        </mat-tab-group>
      </div>
    </form>
  </div>
</div>
