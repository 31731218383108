<div id="header" class="mt-10 flex flex-col">
  <span class="font-lato text-2xl">Cash Credit Statement</span>

  <span class="font-lato text-md py-2 text-gray-500"
    >Cash/Credit applications</span
  >
</div>
<div #SliderTabs class="bg-white rounded-md mat-elevation-z2">
  <form class="example-form">
    <mat-tab-group animationDuration="100ms" #TabSliders>
      <mat-tab label="Download CC Statement">
        <div
          id="table"
          class="max-w-full max-h-full pb-3 overflow-scroll lg:overflow-hidden mclrpading">
          <form
            [formGroup]="ccstmtdownload"
            (ngSubmit)="downloadCCstmt()"
            class="flex flex-row items-end w-full px-2">
            <div #CompanyInput class="flex-1 mr-2">
              <label for="choose-company" class="label-title">Company</label>
              <br />
              <select
                id="choose-company"
                class="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5">
                <option>Choose Company</option>
                <option *ngFor="let comp of allcompList" [value]="comp">
                  {{ comp.comp_code }} - {{ comp.comp_name }}
                </option>
              </select>
            </div>

            <div #BankInput class="flex-1 mr-2">
              <label for="choose-bank" class="label-title">Bank</label>
              <br />
              <select
                id="choose-bank"
                class="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5">
                <option>Choose Bank</option>
                <option *ngFor="let bank of compbankList" [value]="bank">
                  {{ bank.bankacn }}
                </option>
              </select>
            </div>

            <div #MonthStartDate class="flex-1 mr-2">
              <form-field appearance="fill">
                <label for="month-start-date" class="label-title"
                  >Month Start Date</label
                >
                <br />
                <input
                  id="month-start-date"
                  type="date"
                  formControlName="menddate"
                  required
                  class="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg w-full p-2.5"
                  #menddate />
              </form-field>
            </div>

            <div #MonthEndDate1 class="flex-1 mr-2">
              <form-field appearance="fill">
                <label for="month-end-date" class="label-title"
                  >Month End Date</label
                >
                <br />
                <input
                  id="month-end-date"
                  type="date"
                  formControlName="menddate"
                  class="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg w-full p-2.5"
                  required
                  #menddate />
              </form-field>
            </div>

            <section class="flex-1 mr-2">
              <div class="cta-button-row">
                <button
                  (click)="({})"
                  type="button"
                  class="text-white bg-blue-700 hover:bg-blue-800 mb-0.5 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 w-full">
                  Submit
                </button>

                <button
                  mat-raised-button
                  class="cta-button marginbtn"
                  *ngIf="exportmclr"
                  (click)="exportToCsv(ccstmtList, 'Statement')">
                  <i class="fa-solid fa-file-export"></i>Export
                </button>
              </div>
            </section>
          </form>
        </div>
      </mat-tab>
      <mat-tab label="View CC Statement">
        <div
          id="table"
          class="max-w-full max-h-full pb-3 overflow-scroll lg:overflow-hidden mclrpading">
          <form
            [formGroup]="ccviewstmt"
            (ngSubmit)="getbnkstatement()"
            class="flex flex-row items-end w-full px-2">
            <div class="flex-1 mr-2">
              <form-field appearance="fill">
                <label for="company" class="label-title">Company</label>
                <br />
                <select
                  id="company"
                  formControlName="company"
                  class="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5">
                  <option *ngFor="let comp of allcompList" [value]="comp">
                    {{ comp.comp_code }} - {{ comp.comp_name }}
                  </option>
                </select>
              </form-field>
            </div>
            <br />
            <div class="flex-1 mr-2">
              <form-field appearance="fill">
                <label for="bank" class="label-title">Bank</label>
                <br />
                <select
                  id="bank"
                  formControlName="bank"
                  class="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5">
                  <option>Choose Bank</option>
                  <option *ngFor="let bank of compbankList" [value]="bank">
                    {{ bank.bankacn }}
                  </option>
                </select>
              </form-field>
            </div>
            <br />
            <div class="flex-1 mr-2">
              <form-field appearance="fill">
                <label for="date-from" class="label-title">From Date</label>
                <br />
                <input
                  id="date-from"
                  type="date"
                  formControlName="fromdate"
                  required
                  class="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg w-full p-2.5"
                  #fromdate />
              </form-field>
            </div>
            <br />
            <div class="flex-1 mr-2">
              <form-field appearance="fill">
                <label for="date-to" class="label-title">To Date</label>
                <br />
                <input
                  id="date-to"
                  type="date"
                  formControlName="todate"
                  class="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg w-full p-2.5"
                  required
                  #todate />
              </form-field>
            </div>

            <section class="flex-1 mr-2">
              <div class="cta-button-row">
                <button
                  (click)="({})"
                  type="button"
                  class="text-white bg-blue-700 hover:bg-blue-800 mb-0.5 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 w-full">
                  Submit
                </button>
              </div>
            </section>
          </form>
          <hr />
          <div>
            <table
              mat-table
              [dataSource]="bankstmtList"
              class="mat-elevation-z8">
              <ng-container matColumnDef="srno" class="px-8">
                <th mat-header-cell *matHeaderCellDef>#</th>
                <td mat-cell *matCellDef="let element; let i = index">
                  {{ i + 1 }}
                </td>
              </ng-container>
              <ng-container matColumnDef="txnid">
                <th mat-header-cell *matHeaderCellDef>Transaction ID</th>
                <td mat-cell *matCellDef="let element">
                  {{ element.ref }}
                </td>
              </ng-container>
              <ng-container matColumnDef="bank">
                <th mat-header-cell *matHeaderCellDef>Bank</th>
                <td mat-cell *matCellDef="let element">
                  {{ element.bank_code }}
                </td>
              </ng-container>
              <ng-container matColumnDef="accno">
                <th mat-header-cell *matHeaderCellDef>Account No.</th>
                <td mat-cell *matCellDef="let element">
                  {{ element.bank_acno }}
                </td>
              </ng-container>
              <ng-container matColumnDef="valdate">
                <th mat-header-cell *matHeaderCellDef>Value Date</th>
                <td mat-cell *matCellDef="let element">
                  {{ element.bs_date }}
                </td>
              </ng-container>
              <ng-container matColumnDef="check">
                <th mat-header-cell *matHeaderCellDef>Check</th>
                <td mat-cell *matCellDef="let element">
                  {{ element.bs_chequeno }}
                </td>
              </ng-container>
              <ng-container matColumnDef="particular">
                <th mat-header-cell *matHeaderCellDef>particular</th>
                <td mat-cell *matCellDef="let element">
                  {{ element.bs_particular }}
                </td>
              </ng-container>
              <ng-container matColumnDef="withdrawls">
                <th mat-header-cell *matHeaderCellDef>Withdrawlas</th>
                <td mat-cell *matCellDef="let element">
                  {{ element.bs_debitamt }}
                </td>
              </ng-container>
              <ng-container matColumnDef="deposit">
                <th mat-header-cell *matHeaderCellDef>Deposits</th>
                <td mat-cell *matCellDef="let element">
                  {{ element.bs_creditamt }}
                </td>
              </ng-container>
              <ng-container matColumnDef="balance">
                <th mat-header-cell *matHeaderCellDef>Balance</th>
                <td mat-cell *matCellDef="let element">
                  {{ element.bs_runningbal }}
                </td>
              </ng-container>
              <tr mat-header-row *matHeaderRowDef="viewccdata"></tr>
              <tr mat-row *matRowDef="let row; columns: viewccdata"></tr>
            </table>
          </div>
        </div>
      </mat-tab>
      <mat-tab label="Delete CC Statement">
        <div
          id="table"
          class="max-w-full max-h-full pb-3 overflow-scroll lg:overflow-hidden mclrpading">
          <form
            [formGroup]="deletebankstmt"
            (ngSubmit)="deleteBankStmt()"
            class="flex flex-row items-end w-full px-2">
            <div class="flex-1 mr-2">
              <form-field appearance="fill">
                <label for="choose-company" class="label-title">Company</label>
                <br />
                <select
                  id="choose-company"
                  formControlName="company"
                  class="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5">
                  <option>Choose Company</option>
                  <option *ngFor="let comp of allcompList" [value]="comp">
                    {{ comp.comp_code }} - {{ comp.comp_name }}
                  </option>
                </select>
              </form-field>
            </div>
            <br />
            <div class="flex-1 mr-2">
              <form-field appearance="fill">
                <label for="choose-bank" class="label-title">Bank</label>
                <br />
                <select
                  id="choose-bank"
                  formControlName="bank"
                  class="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5">
                  <option>Choose Bank</option>
                  <option *ngFor="let bank of compbankList" [value]="bank">
                    {{ bank.bankacn }}
                  </option>
                </select>
              </form-field>
            </div>
            <br />
            <div class="flex-1 mr-2">
              <form-field appearance="fill">
                <label for="choose-from-date" class="label-title"
                  >From Date</label
                >
                <br />
                <input
                  id="choose-from-date"
                  type="date"
                  formControlName="sdate"
                  class="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg w-full p-2.5"
                  required
                  #sdate />
              </form-field>
            </div>
            <br />
            <div class="flex-1 mr-2">
              <form-field appearance="fill">
                <label for="choose-to-date" class="label-title">To Date</label>
                <br />
                <input
                  id="choose-to-date"
                  type="date"
                  formControlName="edate"
                  class="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg w-full p-2.5"
                  required
                  #edate />
              </form-field>
            </div>
            <section class="flex-1 mr-2">
              <div class="cta-button-row">
                <button
                  (click)="({})"
                  type="button"
                  class="text-white bg-blue-700 hover:bg-blue-800 mb-0.5 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 w-full">
                  Submit
                </button>
              </div>
            </section>
            <section>
              {{ msg }}
            </section>
          </form>
        </div>
      </mat-tab>
    </mat-tab-group>
  </form>
</div>

<!-- Download CC Statement Table  -->
<div class="mt-4">
  <table mat-table [dataSource]="ccstmtList" class="mat-elevation-z8">
    <ng-container matColumnDef="srno" class="px-8">
      <th mat-header-cell *matHeaderCellDef>#</th>
      <td mat-cell *matCellDef="let element; let i = index">
        {{ i + 1 }}
      </td>
    </ng-container>
    <ng-container matColumnDef="txnid">
      <th mat-header-cell *matHeaderCellDef>Transaction ID</th>
      <td mat-cell *matCellDef="let element">
        {{ element.bank_erpref }}
      </td>
    </ng-container>
    <ng-container matColumnDef="date">
      <th mat-header-cell *matHeaderCellDef>Date</th>
      <td mat-cell *matCellDef="let element">
        {{ element.bsdate }}
      </td>
    </ng-container>
    <ng-container matColumnDef="withdrawls">
      <th mat-header-cell *matHeaderCellDef>Withdrawlas</th>
      <td mat-cell *matCellDef="let element">
        {{ element.withdrwals }}
      </td>
    </ng-container>
    <ng-container matColumnDef="deposit">
      <th mat-header-cell *matHeaderCellDef>Deposits</th>
      <td mat-cell *matCellDef="let element">
        {{ element.deposits }}
      </td>
    </ng-container>
    <ng-container matColumnDef="balance">
      <th mat-header-cell *matHeaderCellDef>Balance</th>
      <td mat-cell *matCellDef="let element">
        {{ element.runningbal }}
      </td>
    </ng-container>
    <ng-container matColumnDef="otherflows">
      <th mat-header-cell *matHeaderCellDef>Other Flow</th>
      <td mat-cell *matCellDef="let element">
        {{ element.otherflows }}
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displaytxndata"></tr>
    <tr mat-row *matRowDef="let row; columns: displaytxndata"></tr>
  </table>
</div>
