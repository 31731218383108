import { Component, ElementRef, ViewChild } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-upload-file-modal',
  templateUrl: './upload-file-modal.component.html',
  styleUrls: ['./upload-file-modal.component.scss'],
})
export class UploadFileModalComponent {
  @ViewChild('fileDropRef') fileInput!: ElementRef;

  protected file?: File;
  protected isFileSelected = false;
  protected selectedFileName = '';

  constructor(public dialogRef: MatDialogRef<UploadFileModalComponent>) {}

  onFileDropped(event: DragEvent) {
    event.preventDefault();
    const files = event.dataTransfer?.files;
    if (files !== undefined) {
      this.handleFile(files);
    }
  }

  onDragOver(event: DragEvent) {
    event.preventDefault();
  }

  onFileSelected(event: Event) {
    const selectedFiles = (event.target as HTMLInputElement)?.files;
    if (selectedFiles !== null) {
      this.handleFile(selectedFiles);
    }
  }

  handleFile(files: FileList) {
    if (files.length > 0) {
      this.file = files[0];
      this.isFileSelected = true;
      this.selectedFileName = this.file.name;
    }
  }

  onChooseFileClicked() {
    this.fileInput.nativeElement.click();
  }

  uploadData() {
    if (this.file === null || this.file === undefined) {
      return;
    }
    // TODO: Add upload logic
    // this.spreadsheetService
    //   .uploadSpreadsheet({
    //     file: this.file,
    //   })
    //   .subscribe({
    //     next: (result: { code: number }) => {
    //       if (result['code'] === 200) {
    //         console.log('Upload successful');
    //         this.dialogRef.close();
    //       }
    //     },
    //   });

    console.info(`Emulation: File - ${this.file.name} uploaded successfully.`);
  }
}
