<link
  rel="stylesheet"
  href="https://cdnjs.cloudflare.com/ajax/libs/animate.css/4.1.1/animate.min.css" />
<link
  href="https://fonts.googleapis.com/icon?family=Material+Icons"
  rel="stylesheet" />
<title>FS NXT</title>
<script src="js/wow.js"></script>
<script>
  new WOW().init();
</script>
<router-outlet></router-outlet>
