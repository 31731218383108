import {
  OnInit,
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ViewChild,
} from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/core/services/auth.service';
import { DbService } from 'src/app/core/services/db.service';
import { BreakpointObserver } from '@angular/cdk/layout';
import { first } from 'rxjs';
import { menus } from '../../../environment';
import { ApiUrls } from 'src/apis';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit, AfterViewInit {
  @ViewChild(MatSidenav) sideNav!: MatSidenav;

  constructor(
    private observer: BreakpointObserver,
    private auth: AuthService,
    private dbService: DbService,
    private cdRef: ChangeDetectorRef,
    private router: Router
  ) {}

  //Variables
  userName: any = '';
  userAccessString: any = {};
  sidenavMenus: any[] = menus;
  menuList: any[] = [];
  toggleMenu = false;
  userDetails: any = null;
  mfpages: string[] = ['overall'];
  menuItems: any[] = [];
  defaultProfilePicture = '';
  //Flags
  isMFType = false;

  ngOnInit(): void {
    this.auth.validateSession();
    this.getUser();
    this.getUAC();
  }

  ngAfterViewInit() {
    //Observer listener to switch sidenav
    this.observer.observe(['(max-width:1024px)']).subscribe(res => {
      if (res.matches) {
        this.sideNav.mode = 'over';
        this.sideNav.close();
      } else {
        this.sideNav.mode = 'side';
        this.sideNav.open();
      }
    });
    this.cdRef.detectChanges();
  }

  logout() {
    //Logs out the session from auth service
    this.auth.logout();
  }

  getUAC() {
    //3 OPTIONS SHOW HIDE OR LOCKED
    //SHOW- SHOW COMPONENT WITHOUT ANY CONTRAINS
    //HIDE - HIDE COMPONENT WITH NGIF
    //LOCKED - SHOW COMPONENT WITH LOCKED ROUTER LINKS
    this.dbService
      .postRequest(ApiUrls.auth, [], 'getUAC')
      .pipe(first())
      .subscribe({
        next: (data: any) => {
          this.userAccessString = JSON.parse(data);
          if (this.userAccessString == null) {
            this.router.navigate(['/dashboard/locked']);
          }
        },
        error: (err: any) => {
          console.log(err);
        },
      });
  }

  menuToggle() {
    this.toggleMenu = !this.toggleMenu;
  }

  clickToggle() {
    if (this.sideNav.mode == 'over') {
      this.sideNav.close();
    }
  }

  getSidebarMenus() {
    //Function to get menus
    this.dbService
      .postRequest(ApiUrls.dashboard, [this.auth.getToken()], 'getMenus')
      .pipe(first())
      .subscribe({
        next: (data: any) => {
          this.menuList = data['res'];
          /* console.log(this.menus); */
        },
        error: (err: any) => {
          console.log(err);
        },
      });
  }

  getUser() {
    //Function to get user
    this.dbService
      .postRequest(ApiUrls.dashboard, [this.auth.getToken()], 'getUser')
      .pipe(first())
      .subscribe({
        next: (data: any) => {
          if (data['USER_URL'] == '' || data['USER_URL'] == null) {
            this.defaultProfilePicture =
              'https://st3.depositphotos.com/6672868/13701/v/600/depositphotos_137014128-stock-illustration-user-profile-icon.jpg';
          } else {
            this.defaultProfilePicture = data['USER_URL'];
          }
          this.userName = data['SESS_USERNAME'];
          this.setDefaultLandingPage(data['USER_LANDING']);
          this.setDashboardType(data['USER_LANDING']);
          localStorage.setItem('CGRP', data['USER_COMPCODE']);
        },
        error: (err: any) => {
          console.log(err);
        },
      });
  }

  setDefaultLandingPage(route: string) {
    const defaultRoute = this.router.config?.[2].children?.[0];
    if (localStorage.getItem('isset') == null) {
      defaultRoute!.redirectTo = route;
      this.router.navigate(['/dashboard/' + route]);
      localStorage.setItem('isset', 'true');
    }
  }

  setDashboardType(landingPage: string) {
    this.getSidebarMenus();
  }
}
