import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { first } from 'rxjs';
import { DbService } from 'src/app/core/services/db.service';

@Component({
  selector: 'app-companybank',
  templateUrl: './companybank.component.html',
  styleUrls: ['./companybank.component.scss'],
})
export class CompanybankComponent implements OnInit {
  constructor(private dbservice: DbService) {}

  ngOnInit(): void {
    this.CompBankList();
    this.allCompList();
    this.allBankList();
  }

  allcompList: any[] = [];
  compbankList: any[] = [];
  allbankList: any[] = [];
  showListing = true;
  showForm = false;

  compbankform = new FormGroup({
    compcode: new FormControl(''),
    bank: new FormControl(''),
    txnid: new FormControl(''),
    accno: new FormControl(''),
    ifsc: new FormControl(''),
    branch: new FormControl(''),
    email: new FormControl(''),
    addr1: new FormControl(''),
    addr2: new FormControl(''),
    city: new FormControl(''),
    pincode: new FormControl(''),
    country: new FormControl(''),
    state: new FormControl(''),
  });
  displayedColumns = ['srno', 'compcode', 'txnid', 'accno', 'bankname'];

  allBankList() {
    this.dbservice
      .ccstmt([], 'allBankList')
      .pipe(first())
      .subscribe({
        next: (data: any) => {
          this.allbankList = data['res'];
          // console.log(this.mclrBankList);
        },
        error: (err: any) => {
          console.log(err);
        },
      });
  }

  allCompList() {
    this.dbservice
      .cpdoc([], 'allCompList')
      .pipe(first())
      .subscribe({
        next: (data: any) => {
          this.allcompList = data['res'];
          // console.log(this.mclrBankList);
        },
        error: (err: any) => {
          console.log(err);
        },
      });
  }

  CompBankList() {
    this.dbservice
      .ccstmt([], 'CompBankList')
      .pipe(first())
      .subscribe({
        next: (data: any) => {
          this.compbankList = data['res'];
          // console.log(this.mclrBankList);
        },
        error: (err: any) => {
          console.log(err);
        },
      });
  }

  addCompBank() {
    console.log('check bank');
    this.showForm = true;
    this.showListing = false;
  }

  compbankdata: any = [];
  saveCompBank() {
    this.compbankdata = this.compbankform.value;
    this.dbservice
      .ccstmt(this.compbankdata, 'saveCompBank')
      .pipe(first())
      .subscribe({
        next: (data: any) => {
          if (data['res']['status'] == true) {
            this.CompBankList();
            this.showForm = false;
            this.showListing = true;
          }
          //this.compbankList = data['res'];
          // console.log(this.mclrBankList);
        },
        error: (err: any) => {
          console.log(err);
        },
      });
  }

  HideForm() {
    this.showForm = false;
    this.showListing = true;
  }

  editrec(data: any) {
    this.showForm = true;
    this.showListing = false;
    this.allcompList.forEach((value: any) => {
      if (value.comp_code == data.code) {
        this.compbankform.patchValue({
          compcode: value,
        });
      }
    });
    this.allbankList.forEach((value: any) => {
      if (value.code == data.bank_code) {
        this.compbankform.patchValue({
          bank: value,
        });
      }
    });
    this.compbankform.patchValue({
      txnid: data.refid,
      accno: data.accno,
      ifsc: data.ifsc_code,
      branch: data.branch_name,
      email: data.bank_email,
      addr1: data.bank_addr1,
      addr2: data.bank_addr2,
      city: data.city_code,
      pincode: data.post_code,
      country: data.country_code,
      state: data.state_code,
    });
  }
}
