<h1 mat-dialog-title>Download PDF Document</h1>
<h1 mat-dialog-content>
  <div class="row">
    <div class="col-md-6">
      <ul class="list-unstyled">
        <li>
          <span class="fa fa-check text-success"></span>
          Click on button to download file
        </li>
        <li>
          <span class="fa fa-check text-success"></span>
          File will be download after button click
        </li>
      </ul>
      Click here to Download:
      <a
        class="cta-button"
        href="{{ data.pathname }}"
        target="_blank"
        download="{{ data.filename }}">
        <span
          class="text-success"
          tooltip-placement="bottom"
          tooltip="Click here to download PDF"
          >Download</span
        >
      </a>
    </div>
    <div class="col-md-6">
      Click here to open:
      <a href="{{ data.pathname }}" target="_blank">
        <span
          class="text-success"
          tooltip-placement="bottom"
          tooltip="Click here to preview PDF"
          >view</span
        >
      </a>
      <ul class="list-unstyled">
        <li>
          <span class="fa fa-check text-success"></span>
          File will be open in new tab
        </li>
        <li>
          <span class="fa fa-check text-success"></span>
          Save
        </li>
        <li>
          <span class="fa fa-check text-success"></span>
          Print
        </li>
      </ul>
    </div>
  </div>
</h1>
