import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './features/login/login.component';
import { CompanyComponent } from './features/admin/company/company.component';
import { DashboardComponent } from './features/dashboard/dashboard.component';
import { DocumenttypeComponent } from './features/admin/documenttype/documenttype.component';
import { CompanygroupComponent } from './features/admin/companygroup/companygroup.component';
import { UsersComponent } from './features/admin/users/users.component';
import { FormgenerationComponent } from './features/CP/Txn/formgeneration/formgeneration.component';
import { TxndataComponent } from './features/CP/Txn/txndata/txndata.component';
import { FindataComponent } from './features/CP/Txn/findata/findata.component';
import { MasterdataComponent } from './features/CP/master/masterdata/masterdata.component';
import { MfExposureComponent } from './features/mutualfund/mf-exposure/mf-exposure.component';
import { GenerateallformsComponent } from './features/CP/Txn/generateallforms/generateallforms.component';
import { BanklistComponent } from './features/CC/banklist/banklist.component';
import { CompanybankComponent } from './features/CC/companybank/companybank.component';
import { StmtconverterComponent } from './features/CC/stmtconverter/stmtconverter.component';
import { CcstatementComponent } from './features/CC/ccstatement/ccstatement.component';
import { CommonModule } from '@angular/common';
import { LandingpageComponent } from './features/landingpage/landingpage.component';
import { LockedComponent } from './core/components/locked/locked.component';
import { FraUploadComponent } from './features/fra/fra-upload/fra-upload.component';
import { FraDashboardComponent } from './features/fra/fra-dashboard/fra-dashboard.component';
import { UserManagementComponent } from './features/adminpanel/user-management/user-management.component';
import { MenuManagementComponent } from './features/adminpanel/menu-management/menu-management.component';
import { RedirectUnauthorizedToLogin } from './core/services/auth.guard';

const routes: Routes = [
  {
    path: '',
    component: LandingpageComponent,
    pathMatch: 'full',
    canActivate: [],
  },
  {
    path: 'login',
    component: LoginComponent,
    pathMatch: 'full',
    canActivate: [],
  },
  {
    path: 'dashboard',
    component: DashboardComponent,
    canActivate: [RedirectUnauthorizedToLogin],
    children: [
      {
        path: '',
        redirectTo: 'default',
        pathMatch: 'full',
        //component: DocumenttypeComponent
      },
      {
        path: 'cob',
        loadChildren: () =>
          import('./features/cob/cob.module').then(m => m.CobModule),
      },
      {
        path: 'support-tracker',
        loadChildren: () =>
          import('./features/support-tracker/support-tracker.module').then(
            m => m.SupportTrackerModule
          ),
      },
      {
        path: 'payments',
        loadChildren: () =>
          import('./payments/payments.module').then(m => m.PaymentsModule),
      },
      {
        path: 'compcgrp',
        component: CompanygroupComponent,
      },
      {
        path: 'company',
        component: CompanyComponent,
      },
      {
        path: 'compcgrpuser',
        component: UsersComponent,
      },
      {
        path: 'frmgeneration',
        component: FormgenerationComponent,
      },
      {
        path: 'formtype',
        component: DocumenttypeComponent,
      },
      {
        path: 'trxndata',
        component: TxndataComponent,
      },
      {
        path: 'finstmt',
        component: FindataComponent,
      },
      {
        path: 'docmast',
        component: MasterdataComponent,
      },
      {
        path: 'generateallforms',
        component: GenerateallformsComponent,
      },
      {
        path: 'companybanklist',
        component: BanklistComponent,
      },
      {
        path: 'companybank',
        component: CompanybankComponent,
      },
      {
        path: 'statementconverter',
        component: StmtconverterComponent,
      },
      {
        path: 'ccstmtdownload',
        component: CcstatementComponent,
      },
      //FOR MF MODULE
      {
        path: 'mfexposure',
        component: MfExposureComponent,
      },
      //FOR FRA MODULE
      {
        path: 'fraupload',
        component: FraUploadComponent,
      },
      {
        path: 'fradashboard',
        component: FraDashboardComponent,
      },
      {
        path: 'locked',
        component: LockedComponent,
      },
      //FOR USER MANAGEMENT
      {
        path: 'user-manage',
        component: UserManagementComponent,
      },
      {
        path: 'menu-manage',
        component: MenuManagementComponent,
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true }), CommonModule],
  exports: [RouterModule],
})
export class AppRoutingModule {}
