<div id="header" class="mt-10 flex flex-col mb-7">
  <span class="font-lato text-2xl">Mutual Fund Exposure</span>
  <span class="font-lato text-md py-2 text-gray-500"
    >Compare and Have a quick glance at the Mutual Funds and its
    Categories</span
  >
</div>
<div
  id="inputContainer"
  class="w-full flex flex-col md:flex-row items-start md:items-end">
  <div
    id="fieldsContainer"
    class="flex flex-col md:flex-row pr-0 md:pr-4 w-full">
    <div id="ExposureMonth" class="flex flex-1 flex-col mb-3 md:mr-3">
      <span class="font-lato text-md"> Select Exposure Month</span>

      <select
        [(ngModel)]="selectedExposureMonth"
        (change)="getPortfolioMonths()"
        name="em"
        class="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg block w-full md:w-[12rem] p-2.5">
        <option *ngFor="let month of exposureMonths" [value]="month.id">
          {{ month.name }}
        </option>
      </select>
    </div>
    <div id="PortfolioMonth" class="flex flex-1 flex-col mb-3 md:mr-3">
      <span>Portfolio Month</span>

      <select
        [(ngModel)]="selectedPortfolioMonth"
        name="pm"
        class="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg block w-full md:w-[12rem] p-2.5">
        <option *ngFor="let month of portfolioMonths" [value]="month.id">
          {{ month.name }}
        </option>
      </select>
    </div>
    <div id="MfCategory" class="flex flex-1 flex-col mb-3 md:mr-5">
      <span>MF Category</span>

      <select
        [(ngModel)]="selectedMfCategory"
        name="mfc"
        class="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg block w-full md:w-[12rem] p-2.5">
        <option *ngFor="let category of mfCategories" [value]="category.code">
          {{ category.name }}
        </option>
      </select>
    </div>
  </div>
  <button
    (click)="getMfExposure()"
    type="button"
    class="text-white w-full bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 mb-3 py-2.5 mr-2">
    Search
  </button>
</div>
<div id="mainContainer" class="flex flex-col">
  <!-- For Search Prompt -->
  <div
    *ngIf="!isMftableLoaded && !noDataFound"
    class="w-full flex flex-col items-center pt-24">
    <img class="w-52" src="assets/images/graphics/search.png" alt="" />
    <span class="font-lato font-bold text-4xl">Make a search</span>
    <span class="font-lato">Results will show up in a minute</span>
    <img src="" alt="" />
  </div>
  <!-- For Errors -->
  <div *ngIf="noDataFound" class="w-full flex flex-col items-center pt-24">
    <img class="w-40" src="assets/images/graphics/nodata.png" alt="" />
    <span class="font-lato font-bold text-4xl">Sorry!</span>
    <span class="font-lato"
      >No data found for this period, try searching again!</span
    >
  </div>

  <!-- Mf Exposure Table  -->
  <div
    id="MfExposure"
    class="bg-white mt-4 pb-1 rounded-lg mat-elevation-z2"
    *ngIf="isMftableLoaded">
    <div class="py-3 px-3">
      <span id="name" class="font-lato">Mutual Fund Exposure</span>
    </div>
    <hr />
    <div class="overflow-scroll">
      <table mat-table [dataSource]="dataSource" class="!overflow-scroll">
        <ng-container
          *ngFor="let col of displayedColumns; let colIndex = index">
          <!-- Special Div for First Column -->
          <ng-container *ngIf="colIndex === 0" matColumnDef="{{ col }}">
            <th mat-header-cell *matHeaderCellDef>{{ col }}</th>
            <td mat-cell *matCellDef="let element">
              <button
                class="cursor-pointer"
                *ngIf="element[colIndex] !== displayedRows[0]"
                (click)="getSchemeRating(element[colIndex])">
                {{ element[colIndex] }}
              </button>
              <div
                class="cursor-default"
                *ngIf="element[colIndex] === displayedRows[0]">
                {{ element[colIndex] }}
              </div>
            </td>
          </ng-container>

          <ng-container *ngIf="colIndex !== 0" matColumnDef="{{ col }}">
            <th mat-header-cell *matHeaderCellDef>{{ col }}</th>
            <td mat-cell *matCellDef="let element">{{ element[colIndex] }}</td>
          </ng-container>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
    </div>
  </div>
  <!-- Selected Rating Table  -->
  <div
    id="SelectedRatingExposure"
    class="bg-white mt-4 pb-1 rounded-lg mat-elevation-z2"
    *ngIf="isRatingTableLoaded">
    <div class="py-3 px-3">
      <span id="name" class="font-lato">Selected Rating Exposure</span>
    </div>
    <hr />
    <div class="overflow-scroll">
      <table mat-table [dataSource]="ratingDataSource" class="overflow-scroll">
        <ng-container
          *ngFor="let col of displayedRatingColumns; let colIndex = index">
          <ng-container matColumnDef="{{ col }}">
            <th mat-header-cell *matHeaderCellDef>{{ col }}</th>
            <td mat-cell *matCellDef="let element">{{ element[colIndex] }}</td>
          </ng-container>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedRatingColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedRatingColumns"></tr>
      </table>
    </div>
  </div>
  <!-- O/s Comparison Table  -->
  <div
    id="O/s Comparison Table"
    class="bg-white mt-4 pb-1 rounded-lg mat-elevation-z2"
    *ngIf="isComparisonTableLoaded">
    <div class="py-3 px-3">
      <span id="name" class="font-lato">O/s Comparison Table</span>
    </div>
    <hr />
    <div class="overflow-scroll">
      <table mat-table [dataSource]="comparisonDataSource">
        <ng-container
          *ngFor="let col of displayedComparisonColumns; let colIndex = index">
          <ng-container matColumnDef="{{ col }}">
            <th mat-header-cell *matHeaderCellDef>{{ col }}</th>
            <td mat-cell *matCellDef="let element">{{ element[colIndex] }}</td>
          </ng-container>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedComparisonColumns"></tr>
        <tr
          mat-row
          *matRowDef="let row; columns: displayedComparisonColumns"></tr>
      </table>
    </div>
  </div>
  <!-- Category Wise, Scheme Wise and Rating Wise Graph Containers  -->
  <div
    id="graphsContainer"
    class="{{
      isGraphsLoaded ? 'flex' : 'hidden'
    }}  flex-col md:flex-row w-full min-w-full max-w-full h-fit md:h-[30rem] mt-4 mb-4 ">
    <div
      id="CategoryWiseGraph"
      class="{{
        isSummaryGraphLoaded ? 'block' : 'hidden'
      }}  bg-white mat-elevation-z2 rounded-lg w-full md:w-1/3 pb-3 mb-4 md:mr-4 h-[30rem] md:h-full">
      <div class="py-3 px-3">
        <span class="font-lato">Category Wise Graph</span>
      </div>
      <hr />
      <div class="chart-container" style="position: relative; height: 90%">
        <canvas #CategoryWiseChartCanvas class="pr-6 pl-3 py-3">{{
          categoryWiseChart
        }}</canvas>
      </div>
    </div>

    <div
      id="RatingExposure"
      class="bg-white mat-elevation-z2 rounded-lg {{
        isSummaryGraphLoaded ? 'w-full md:w-1/3' : 'w-full md:w-1/2'
      }} pb-3 mb-4 md:mr-4 h-[30rem] md:h-full">
      <div class="py-3 px-3">
        <span class="font-lato">Rating Exposure Graph</span>
      </div>
      <hr />
      <div class="chart-container" style="position: relative; height: 90%">
        <canvas #RatingExposureChartCanvas class="p-4">{{
          ratingExposureChart
        }}</canvas>
      </div>
    </div>
    <div
      id="SchemeWiseExposure"
      class="bg-white mat-elevation-z2 rounded-lg {{
        isSummaryGraphLoaded ? 'w-full md:w-1/3' : 'w-full md:w-1/2'
      }} pb-3 h-[30rem] md:h-full">
      <div class="py-3 px-3">
        <span class="font-lato">Scheme Wise Exposure Graph</span>
      </div>
      <hr />
      <div class="chart-container" style="position: relative; height: 90%">
        <canvas #SchemeExposureChartCanvas class="p-4">{{
          schemeExposureChart
        }}</canvas>
      </div>
    </div>
  </div>
</div>
